import { GenericOAuth2 } from '@capacitor-community/generic-oauth2';
import { criiptoLogin, helseIDLogin, refreshToken } from 'src/store/backend';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { jwtDecode } from 'jwt-decode';
import { displayAlert } from 'src/utilities/display-alert';
import { runningUnderNativePlatform } from 'src/store/running-under-native-platform';

export async function saveTokens(accessToken: string, refreshToken: string) {
  await SecureStoragePlugin.set({ key: 'access_token', value: accessToken });
  await SecureStoragePlugin.set({ key: 'refresh_token', value: refreshToken });
}

export async function hasAccessToken(): Promise<boolean> {
  const keys = await SecureStoragePlugin.keys();
  return keys.value.includes('access_token');
}

// Get tokens
export async function getAccessToken() {
  const { value } = await SecureStoragePlugin.get({ key: 'access_token' });
  return value;
}

export async function getRefreshToken() {
  const { value } = await SecureStoragePlugin.get({ key: 'refresh_token' });
  return value;
}

// Remove tokens (on logout)
export async function clearTokens() {
  await SecureStoragePlugin.clear();
}

const criiptoOptions = {
  authorizationBaseUrl: 'https://trinnvis.criipto.id/oauth2/authorize',
  accessTokenEndpoint: 'https://trinnvis.criipto.id/oauth2/token',
  scope: 'profile openid offline_access',
  logsEnabled: true,
  additionalParameters: { acr_values: 'urn:grn:authn:no:bankid' },
  appId: 'urn:dabih-app:identifier:695713',
  web: {
    responseType: 'code', // implicit flow
    redirectUrl: window.origin,
    windowTarget: '_self',
    pkceEnabled: true,
    sendCacheControlHeader: false,
  },
  android: {
    responseType: 'code',
    redirectUrl: 'no.trinnvis.dabih.issues://callback',
    pkceEnabled: true,
  },
  ios: {
    responseType: 'code',
    redirectUrl: 'no.trinnvis.dabih://callback',
    pkceEnabled: true,
  },
};

export async function openCriiptoAuth() {
  const r = await GenericOAuth2.authenticate(criiptoOptions);
  console.log('SecureStoragePlugin authenticated', r);
  const currentAccessToken = await getValidAccessToken();
  const l = await criiptoLogin({
    criiptoTokenLoginMessage: {
      idToken: r.access_token_response.id_token,
      currentAccessToken: currentAccessToken != '' ? currentAccessToken : undefined,
    },
  });
  console.log('SecureStoragePlugin authenticated l', l);

  localStorage.setItem('dabih-username', l.username);

  await saveTokens(l.accessToken, l.refreshToken);

  console.log('SecureStoragePlugin saveTokens');

  window.location.href = '/';
}

const helseIdOptions = {
  authorizationBaseUrl: 'https://api.trinnvis.io/auth/helseid/authorize',
  accessTokenEndpoint: 'https://api.trinnvis.io/auth/helseid/token',
  scope: 'email profile openid offline_access',
  logsEnabled: true,
  appId: '6ebb3644-762e-41b8-b0eb-a45df5698a6a',
  web: {
    responseType: 'code', // implicit flow
    redirectUrl: window.origin,
    windowTarget: '_self',
    pkceEnabled: true,
    sendCacheControlHeader: false,
  },
  android: {
    responseType: 'code',
    redirectUrl: 'no.trinnvis.dabih.issues://callback',
    pkceEnabled: true,
  },
  ios: {
    responseType: 'code',
    redirectUrl: 'no.trinnvis.dabih://callback',
    pkceEnabled: true,
    logsEnabled: true,
  },
};

async function executeHelseIdLogin(r, currentAccessToken: string | string) {
  const l = await helseIDLogin({
    helseIDTokenLoginMessage: {
      idToken: r.access_token_response.id_token,
      currentAccessToken: currentAccessToken != '' ? currentAccessToken : undefined,
    },
  });
  console.log('SecureStoragePlugin authenticated l', l);

  localStorage.setItem('dabih-username', l.username);

  await saveTokens(l.accessToken, l.refreshToken);

  console.log('SecureStoragePlugin saveTokens');

  window.location.href = '/';
}

export async function openHelseIdAuth() {
  const r = await GenericOAuth2.authenticate(helseIdOptions);
  console.log('SecureStoragePlugin authenticated', r);
  const currentAccessToken = await getValidAccessToken();
  await executeHelseIdLogin(r, currentAccessToken);
}

// Decode and check token expiration
function isTokenExpired(token: string): boolean {
  try {
    const decodedToken: any = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return decodedToken.exp < currentTime;
  } catch (error) {
    return true; // Consider token expired if decoding fails
  }
}

// Refresh access token
async function refreshAccessToken() {
  const token = await getRefreshToken();
  if (!token) {
    throw new Error('No refresh token available');
  }

  try {
    const response = await refreshToken({
      refreshTokenMessage: {
        refreshToken: token,
      },
    });

    const newAccessToken = response.accessToken;
    const newRefreshToken = response.refreshToken;

    // Save new tokens
    await saveTokens(newAccessToken, newRefreshToken);
    return newAccessToken;
  } catch (error) {
    console.error('Failed to refresh access token', error);
    throw error;
  }
}

// Get access token (with automatic refresh)
export async function getValidAccessToken() {
  if (!(await hasAccessToken())) {
    return '';
  }
  let accessToken = await getAccessToken();

  if (!accessToken || isTokenExpired(accessToken)) {
    // Refresh the access token if expired
    accessToken = await refreshAccessToken();
  }

  return accessToken;
}

export async function checkRedirectCodeFlow() {
  if (runningUnderNativePlatform()) {
    return;
  }

  const query = location.search.slice(1);
  const params = new URLSearchParams(query);
  const currentAccessToken = await getValidAccessToken();

  if (params.has('code')) {
    const iss = params.get('iss');
    console.log(iss, window.location.href);
    if (iss === 'https://helseid-sts.nhn.no') {
      try {
        const r = await GenericOAuth2.redirectFlowCodeListener({
          ...helseIdOptions,
          response_url: window.location.href,
        });
        console.log('SecureStoragePlugin redirectFlowCodeListener', r);
        await executeHelseIdLogin(r, currentAccessToken);
      } catch (error) {
        console.error(error);
        displayAlert(
          'TrinnVisbruker ikke funnet for denne HelseID. Logg inn med e-post og passord først for å koble til HelseID.',
        );
      }
    } else {
      // Criipto does not provide iss
      try {
        const r = await GenericOAuth2.redirectFlowCodeListener({
          ...criiptoOptions,
          response_url: window.location.href,
        });
        console.log('SecureStoragePlugin criipto redirectFlowCodeListener', r);
        const l = await criiptoLogin({
          criiptoTokenLoginMessage: {
            idToken: r.access_token_response.id_token,
            currentAccessToken: currentAccessToken != '' ? currentAccessToken : undefined,
          },
        });
        console.log('SecureStoragePlugin criipto authenticated l', l);

        localStorage.setItem('dabih-username', l.username);

        await saveTokens(l.accessToken, l.refreshToken);

        console.log('SecureStoragePlugin criipto saveTokens');
      } catch (error) {
        console.error(error);
        displayAlert(
          'TrinnVisbruker ikke funnet for denne BankID. Logg inn med e-post og passord først for å koble til BankID.',
        );
      }

      window.location.href = '/';
    }
  }
}
