import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { PropertyValues } from 'lit/development';
import { DPromoDialog, PromoDialogResult } from 'src/library/promo/d-promo-dialog';
import { features, getPromoCodeForPage, promo } from 'src/store/selectors/features';
import type { FeatureStates } from 'src/store/selectors/features';
import { OrganizationViewModelSpecialTermsEnum } from 'src/store/api';

export interface PageMenuItem {
  pageId: number;
  name: string;
  href: string;
  active: boolean;
  promo: string;
}

export interface PageMenuGroup {
  code: string;
  name: string;
  pages: PageMenuItem[];
}

/**
 *
 */
@customElement('d-page-menu')
export class DPageMenu extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      list-style-type: none;
      margin: 0 20px;
      padding-bottom: 100px;
      font-family: var(--mainSans);
      direction: ltr;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    .group {
      padding: 6px 0;
      margin-bottom: 12px;
      border-radius: 12px;
      background: hsla(0, 0%, 0%, 0.2);
      overflow: hidden;
    }
    .group-name {
      margin-top: -6px;
      padding: 10px 12px 2px 12px;
      color: white;
      font-size: 11px;
      line-height: 150%;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1px;
      opacity: 0.7;
    }
    .group-name.expandable {
      cursor: pointer;
    }
    .group-name.expandable:before {
      content: '';
      display: inline-block;
      margin-right: 3px;
      width: 0;
      height: 0;
      border-top: 4px solid transparent;
      border-left: 8px solid white;
      border-bottom: 4px solid transparent;
      transition: transform 0.3s;
    }
    .group-name.expandable.expanded:before {
      transform: rotate(90deg);
    }
    .group li {
      display: block;
      padding: 2px 12px;
      color: var(--themeColorLighterTwo);
      text-decoration: none;
      font-weight: normal;
      cursor: pointer;
      line-height: 150%;
    }
    .group li.inactive {
      font-weight: 200;
      opacity: 0.6;
    }
    .group li[selected] {
      color: white;
      cursor: default;
    }
    @media (hover: hover) {
      .group-name.expandable:hover {
        opacity: 1;
      }
      .group li:not([selected]):hover {
        color: hsl(196, 83%, 90%);
      }
    }
  `;
  @property({ type: Number })
  setScrolltop = 0;
  @property({ type: Number })
  organizationId = 0;
  @property({ type: Array })
  pageMenu: PageMenuGroup[] = [];
  @property({ type: Number })
  pageId = 0;
  @property({ type: Number })
  currentLevel = 0;
  @property({ type: Object })
  featureStates!: FeatureStates;
  @property({ type: Number })
  employeesCount = 1;
  @property({ type: String })
  specialTerms: OrganizationViewModelSpecialTermsEnum | undefined = undefined;

  @property({ type: Boolean })
  writeAccess = false;

  private get filteredPageMenu(): PageMenuGroup[] {
    const result: PageMenuGroup[] = [];
    const promoPages: PageMenuItem[] = [];
    this.pageMenu.forEach((group) => {
      const pages: PageMenuItem[] = [];
      group.pages.forEach((page) => {
        const pageItem: PageMenuItem = {
          ...page,
          promo: getPromoCodeForPage(page.pageId),
        };
        if (page.active) {
          pages.push(pageItem);
        } else if (pageItem.promo !== '') {
          pageItem.active = false;
          promoPages.push(pageItem);
        }
      });
      if (pages.length) {
        let groupName = '';
        if (pages.length > 2 && group.code !== 'registers') {
          groupName = group.name;
        }
        result.push({
          code: group.code,
          name: groupName,
          pages,
        });
      }
    });
    if (this.featureStates && !this.featureStates.core) {
      promoPages.push({
        pageId: -1,
        name: 'HMS',
        href: '',
        active: false,
        promo: 'hse',
      });
      promoPages.push({
        pageId: -1,
        name: 'TrinnVis Komplett',
        href: '',
        active: false,
        promo: 'complete',
      });
    }
    if (promoPages.length) {
      result.push({
        code: '',
        name: '',
        pages: promoPages,
      });
    }
    return result;
  }

  handleScroll(e) {
    this.dispatchEvent(
      new CustomEvent('scrolltop-changed', {
        bubbles: true,
        composed: true,
        detail: e.target.scrollTop,
      }),
    );
  }

  async onMenuClick(page: PageMenuItem) {
    if (page.active) {
      this.dispatchEvent(new CustomEvent('navigate', { bubbles: true, composed: true, detail: { href: page.href } }));
    } else {
      const promoData = promo.find((p) => {
        return p.id === page.promo;
      });
      if (promoData) {
        const result: PromoDialogResult = await DPromoDialog.open({
          promoData,
          features: features,
          employeesCount: this.employeesCount,
          specialTerms: this.specialTerms,
          healthcareSignatoryOptions: [],
          healthcareSignatory: undefined,
          canUpgrade: this.writeAccess,
        });
        if (result.action === 'upgrade' && promoData.pageAfterUpgrade)
          this.dispatchEvent(
            new CustomEvent('navigate', {
              bubbles: true,
              composed: true,
              detail: { href: '/account/' + this.organizationId + '/' + promoData.pageAfterUpgrade },
            }),
          );
      }
    }
  }

  render() {
    return html`
      <ul>
        ${this.filteredPageMenu.map(
          (group) => html`
            <li>
              <ul class="group">
                ${group.name ? html` <div class="group-name">${group.name}</div> ` : nothing}
                ${group.pages.map(
                  (page) =>
                    html`<li
                      class="item ${page.active ? '' : 'inactive'}"
                      ?selected="${page.pageId === this.pageId && this.currentLevel > 0}"
                      @click=${() => this.onMenuClick(page)}
                    >
                      ${page.name}
                    </li> `,
                )}
              </ul>
            </li>
          `,
        )}
      </ul>
    `;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.addEventListener('scroll', this.handleScroll);
  }

  protected updated(_changedProperties: PropertyValues) {
    if (_changedProperties.has('setScrolltop')) {
      this.scrollTo({
        top: this.setScrolltop,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  private selected(currentPageId, pageId) {
    return currentPageId === pageId;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-page-menu': DPageMenu;
  }
}
