import type { ContactView, ContactViewEditItem } from 'src/content/contacts/d-contact-view.js';
import { PartnerView, PartnerViewEditItem } from 'src/content/partners/d-partner-view.js';
import { fetchDraft, pagesAsSelectTagOptions, toAttachmentItems } from 'src/models/factory-utilities.js';
import { BASE_PATH, PartnerViewModel } from 'src/store/api';
import {
  computersNotDeleted,
  contactsNotDeleted,
  contractsNotDeleted,
  currentEmployeeUuid,
  getOrganization,
  key,
  partnerNamesById,
  partnersNotDeleted,
  toContactPersonViewModel,
  toPartnerViewModel,
  writeAccess,
} from 'src/store/selectors';
import type { State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { assertIsDefined } from 'src/lib';
import { getFeatureStates } from 'src/store/selectors/features';
import { CommonDataEntityView } from 'src/content/entity-content';
import { ApiClient } from 'src/store';
import { sortBy } from 'lodash';
import { ListSectionItemInput } from 'src/library/lists/utilities';

function hasAssociatedComputers(uuid: string, state: State) {
  const computers = computersNotDeleted(state);
  return computers.find((c) => c.serviceProvider === uuid) !== undefined;
}

export function buildPartnerView(
  api: ApiClient,
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): PartnerView {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const item = organization.partnersById[uuid];
  assertIsDefined(item);

  const entity = toPartnerViewModel(item, organization);
  const href = currentParent + '/partners/' + uuid;

  const current = currentEmployeeUuid(state);

  const contacts: ListSectionItemInput[] = contactsNotDeleted(state)
    .filter((c) => c.partnerUuid === uuid)
    .map((c) => ({
      href: href + '/contacts/' + c.uuid,
      label: c.firstName + ' ' + c.lastName,
      accessible: true,
      hasDraft: c.hasDraft,
    }));

  const contracts: ListSectionItemInput[] = contractsNotDeleted(state)
    .filter((c) => c.partners?.includes(uuid))
    .map((c) => ({
      href: href + '/contracts/' + c.uuid,
      label: c.name ?? '',
      mainIcon: c.classification !== 'NONE' ? 'lock' : undefined,
      accessible: c.classification === 'NONE' || (current !== undefined && (c.accessControl ?? []).includes(current)),
      hasDraft: c.hasDraft,
    }));

  return {
    ...commonData,
    docsForLinking: [],
    currentUserHasAccess: true,
    currentUserHasWriteAccess: writeAccess(state) && hasPartnerAccess(state, entity),

    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    helpContent: entity.helpContent ?? '',
    href: href,
    pdfHref:
      BASE_PATH + '/organizations/' + organization.organizationId + '/partners/' + uuid + '.pdf?key=' + key(state),

    parentHref: currentParent,
    deleted: entity.deleted === true,
    type: 'partners',
    remoteAccess: entity.remoteAccess === true,
    notes: entity.notes ?? '',
    url: entity.url ?? '',
    address: entity.address ?? '',
    physicalAccess: entity.physicalAccess === true,
    phone: entity.phone ?? '',
    email: entity.email ?? '',
    industry: entity.industry ?? '',
    name: entity.name ?? '',
    attachments: toAttachmentItems(api, 'partners', entity.uuid, entity.attachments),
    contacts: sortBy(contacts, [(item) => item.label]),
    contracts: sortBy(contracts, [(item) => item.label]),
    featureStates: getFeatureStates(state),
    availablePages: pagesAsSelectTagOptions(state),
    pages: (entity.pages ?? []).map((x) => '' + x),
    today: LocalDate.fromString(state.today),
    deletable: !hasAssociatedComputers(uuid, state),
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(api, 'partners', uuid, organization.organizationId);
      return command.draft as PartnerViewEditItem;
    },
  };
}

function hasPartnerAccess(state: State, partner: PartnerViewModel): boolean {
  assertIsDefined(state.username);
  return partner.name !== 'PlussBHT' || state.username.endsWith('@plussbht.no');
}

export function buildContactView(
  api: ApiClient,
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): ContactView {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const item = organization.contactsById[uuid];
  assertIsDefined(item);

  const entity = toContactPersonViewModel(item);

  const partnerEntity = partnersNotDeleted(state).find((p) => p.uuid === entity.partnerUuid);
  assertIsDefined(partnerEntity);
  return {
    ...commonData,
    docsForLinking: [],
    currentUserHasAccess: true,
    currentUserHasWriteAccess: writeAccess(state) && hasPartnerAccess(state, partnerEntity),

    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    helpContent: '',
    href: currentParent + '/contacts/' + uuid,
    pdfHref:
      BASE_PATH + '/organizations/' + organization.organizationId + '/contacts/' + uuid + '.pdf?key=' + key(state),

    parentHref: currentParent,
    deleted: entity.deleted,
    type: 'contacts',
    name: entity.firstName + ' ' + entity.lastName,
    attachments: toAttachmentItems(api, 'contacts', entity.uuid, entity.attachments, entity.partnerUuid),
    notes: entity.notes ?? '',
    accessLevel: entity.accessLevel ?? 'NONE',
    email: entity.email ?? '',
    telephone: entity.telephone ?? '',
    mobilePhone: entity.mobilePhone ?? '',
    partnerName: partnerNamesById(state)[entity.partnerUuid] ?? '',
    parentEntityId: entity.partnerUuid ?? '',
    lastName: entity.lastName,
    firstName: entity.firstName,
    personalIdentifier: entity.personalIdentifier,
    today: LocalDate.fromString(state.today),
    accessExpires: entity.accessExpires ?? '',
    deletable: true,
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(api, 'contacts', uuid, organization.organizationId);
      return command.draft as ContactViewEditItem;
    },
    featureStates: getFeatureStates(state),
    partnerUuid: entity.partnerUuid,
    partners: partnersNotDeleted(state).map((p) => {
      return { value: p.uuid, text: p.name };
    }),
  };
}
