import type { State, User } from '../types.js';
import { currentEmployeeUuid, getEmployeesAsUsers } from './organization-employees.js';
import { currentContactUuid, getContactsAsUsers } from './organization-partners.js';
import { getOrganizationId } from './organization.js';
import { getCurrentUserEmail } from './session.js';
import type { ContactPersonViewModel, EmployeeViewModel } from '../api';
import { createSelector } from '@reduxjs/toolkit';
import { currentOrganizationId } from 'src/store';

function getUser(state: State): User {
  if (state.user === undefined || state.user === null) return { username: '', organizations: [], key: '' };
  return state.user;
}

export const displayedUserName = createSelector(
  getEmployeesAsUsers,
  getContactsAsUsers,
  getCurrentUserEmail,
  function (employees, contacts, currentUserEmail) {
    if (!currentUserEmail) {
      return '';
    }

    const employeeAsUser = employees.find(
      (e) => e.email !== undefined && currentUserEmail.toLowerCase() === e.email.toLowerCase(),
    );
    const contactAsUser = contacts.find(
      (e) => e.email !== undefined && currentUserEmail.toLowerCase() === e.email.toLowerCase(),
    );

    let displayedUserName = '';

    if (employeeAsUser !== undefined) {
      displayedUserName = employeeAsUser.name;
    } else if (contactAsUser !== undefined) {
      displayedUserName = contactAsUser.name;
    }

    if (displayedUserName.length < 3) {
      displayedUserName = currentUserEmail;
    }

    return displayedUserName;
  },
);
const userOrganizations = createSelector(getUser, (user) => user.organizations ?? []);

export const writeAccess = createSelector(
  getOrganizationId,
  userOrganizations,
  function (organizationId, organizations) {
    if (organizationId !== null && organizationId !== 0) {
      const len = organizations.length;

      for (let i = 0; i < len; i++) {
        if (organizations[i].id === +organizationId) {
          return !organizations[i].readonly;
        }
      }

      console.error('Write access, no organization found for id ', organizationId, organizations);
    }
    return false;
  },
);

export const requiresSecureLogin = createSelector(
  currentOrganizationId,
  userOrganizations,
  function (organizationId, organizations) {
    if (organizationId !== null) {
      const len = organizations.length;

      for (let i = 0; i < len; i++) {
        if (organizations[i].id === organizationId) {
          return organizations[i].requiresSecureLogin;
        }
      }

      console.error('requiresSecureLogin, no organization found for id ', organizationId, organizations);
    }
    return false;
  },
);

export function userHasUniqueEmail(
  uuid: string,
  email: string,
  employees: EmployeeViewModel[],
  contacts: ContactPersonViewModel[],
): boolean {
  if (email.trim() !== '') {
    const otherEmployeesWithSameEmail = employees.filter(function (employee) {
      return employee.email === email && employee.uuid !== uuid;
    });
    if (otherEmployeesWithSameEmail.length > 0) {
      return false;
    }
    const otherContactsWithSameEmail = contacts.filter(function (contact) {
      return contact.email === email && contact.uuid !== uuid;
    });
    if (otherContactsWithSameEmail.length > 0) {
      return false;
    }
  }
  return true;
}

export const currentUserUuid = createSelector(
  currentEmployeeUuid,
  currentContactUuid,
  function (employeeUuid, contactUuid): string {
    if (employeeUuid) {
      return employeeUuid;
    }
    if (contactUuid) {
      return contactUuid;
    }
    throw new Error('Illegal state');
  },
);
