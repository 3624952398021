import { css, html, LitElement, nothing } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import { DPromoDialog, PromoDialogResult } from 'src/library/promo/d-promo-dialog';

import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import { PageMenuGroup } from 'src/layout/parts/d-page-menu';
import { features, promo } from 'src/store/selectors/features';
import type { FeatureStates } from 'src/store/selectors/features';
import { OrganizationViewModelSpecialTermsEnum } from 'src/store/api';
import './d-new-documents-list';

/**
 * Viser en generell + knapp for å legge til nye elementer
 *
 *
 */
@customElement('d-new-document')
export class DNewDocument extends LitElement {
  static readonly styles = css`
    :host {
      z-index: 20;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    :host([storybook]) {
      width: 800px;
      height: 800px;
    }

    #cover {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
    }

    #bottom {
      display: flex;
      flex-direction: column-reverse;
      position: absolute;
      bottom: 0;
      right: 0;
      height: 0;
    }

    #slider {
      position: relative;
      right: -320px;
      display: flex;
      flex-direction: column;
      background: white;
      padding: 20px;
      border-top-left-radius: 12px;
      width: 300px;
      max-height: calc(100vh - 20px);
      max-height: calc((var(--vh, 1vh) * 100) - 20px);
      box-sizing: border-box;
      box-shadow: 0px 0px 10px hsla(0, 0%, 0%, 0.3);
      visibility: hidden;
      transition:
        visibility 0s 0.3s,
        right 0.3s;
    }

    #slider.open {
      visibility: visible;
      right: 0;
      transition:
        visibility 0s,
        right 0.3s;
    }

    :host([storybook]) #slider,
    :host([storybook]) #slider.open {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
    }

    .header {
      flex: none;
      font-size: 17px;
      font-weight: 500;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--borderColor);
    }

    .body {
      flex: 1;
      overflow: auto;
    }

    .footer {
      flex: none;
      margin-bottom: 46px;
    }

    .doc-types-list {
      margin-top: -1px;
      border-top: 1px solid var(--borderColor);
      border-bottom: 1px solid var(--borderColor);
      padding: 4px 0;
    }

    .doc-types-list a {
      display: block;
      margin-top: 1px;
      padding: 6px 0px 6px 30px;
      background-size: 28px 28px;
      background-position: -2px 2px;
      background-repeat: no-repeat;
      opacity: 0.8;
    }

    a {
      color: var(--linkColorGray);
      text-decoration: none;
      cursor: pointer;
    }

    .doc-types-list a:hover {
      color: var(--themeColorDarkerOne);
      opacity: 1;
    }

    @media (hover: hover) {
      .doc-types-list a:hover {
        opacity: 1;
      }
      .issueMessage:hover {
        color: white;
        background: var(--alertColorDarkerOne);
      }

      .feedback:hover {
        color: white;
        background: var(--themeColorDarkerOne);
      }
    }

    .issueMessage,
    .feedback {
      color: white;
      margin-top: 8px;
      padding: 8px 10px;
      border-radius: 6px;
      cursor: pointer;
    }

    .issueMessage {
      background: var(--alertColor);
    }

    .inactive {
      opacity: 0.5;
    }

    .feedback {
      background: var(--themeColor);
      margin-bottom: 8px;
    }

    @media (hover: hover) {
      .issueMessage:hover {
        color: white;
        background: var(--alertColorDarkerOne);
      }

      .feedback:hover {
        color: white;
        background: var(--themeColorDarkerOne);
      }
    }

    #toggleSlide {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;
      box-shadow: 0px 0px 10px hsla(0, 0%, 0%, 0.3);
      cursor: pointer;
      transition: box-shadow 0.3s;
    }

    #toggleSlide > div {
      width: 18px;
      height: 18px;
      position: relative;
      transition: transform 0.3s;
    }

    #toggleSlide.open {
      box-shadow: 0px 0px 10px hsla(0, 0%, 0%, 0);
    }

    #toggleSlide.open > div {
      transform: rotate(-45deg);
    }

    #toggleSlide > div > div {
      position: absolute;
      top: 6.5px;
      width: 18px;
      height: 3px;
      background: var(--themeColor);
    }

    @media (hover: hover) {
      #toggleSlide:hover > div > div {
        background: var(--themeColorDarkerTwo);
      }
    }

    #toggleSlide > div > div:last-child {
      transform: rotate(90deg);
    }
  `;
  @property({ type: Boolean })
  /**
   * The menu is open
   */
  open = false;
  @property({ type: Boolean })
  uncoverPageMenu = false;
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Array })
  currentPathArray: string[] = [];
  @property({ type: Boolean })
  singleUser = false;
  @property({ type: Object })
  featureStates!: FeatureStates;
  @property({ type: Array })
  userEmails: string[] = [];
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];
  @property({ type: Array })
  pageMenu: PageMenuGroup[] = [];
  @property({ type: String })
  defaultFunctionUuid = '';
  @property({ type: Number })
  employeesCount = 0;
  @property({ type: String })
  specialTerms: OrganizationViewModelSpecialTermsEnum | undefined = undefined;

  private get themePages(): number[] {
    const group: PageMenuGroup | undefined = this.pageMenu.find((group) => {
      return group.code === 'themePages';
    });
    if (group) {
      return group.pages.map((page) => {
        return page.pageId;
      });
    }
    return [];
  }

  _sliderClass(open: boolean) {
    if (open) {
      return 'open';
    }
    return '';
  }

  onClickSlider(e) {
    if (e.target.tagName !== 'A') {
      this._toggleSlide();
    }
  }

  _toggleSlide() {
    this.open = !this.open;
  }

  _openFeedback() {
    this.dispatchEvent(new CustomEvent('display-feedback', { composed: true, bubbles: true, detail: {} }));
  }

  _openIssue() {
    this.dispatchEvent(new CustomEvent('display-new-issue', { composed: true, bubbles: true, detail: {} }));
  }

  renderDoclist() {
    if (this.writeAccess) {
      return html`<div class="doc-types-list">
        <d-new-documents-list
          style="background: white"
          .writeAccess=${this.writeAccess}
          .currentPathArray=${this.currentPathArray}
          .singleUser=${this.singleUser}
          .featureStates=${this.featureStates}
          .userEmails=${this.userEmails}
          .partners=${this.partners}
          .pageMenu=${this.pageMenu}
          .defaultFunctionUuid=${this.defaultFunctionUuid}
          .employeesCount=${this.employeesCount}
          .specialTerms=${this.specialTerms}
        ></d-new-documents-list>
      </div>`;
    }
    return nothing;
  }
  render() {
    return html`
      ${!this.open ? nothing : html` <div id="cover" @click=${() => this._toggleSlide()}></div>`}
      <div id="bottom">
        <div id="slider" class="${this._sliderClass(this.open)}" @click=${(e) => this.onClickSlider(e)}>
          ${!this.writeAccess ? nothing : html` <div class="header">Legg til nytt element</div>`}
          <div class="body">${this.renderDoclist()}</div>
          <div class="footer">
            <div class="doc-types-list">
              ${this.featureStates.core || this.featureStates.issues
                ? html` <div class="issueMessage" @click=${() => this._openIssue()}>Avviksmelding</div>`
                : html` <div class="issueMessage inactive" @click=${(e) => this.onClickPromo(e, 'issues')}>
                    Avviksmelding
                  </div>`}
              <div class="feedback" @click=${() => this._openFeedback()}>Tilbakemelding til TrinnVis</div>
            </div>
          </div>
        </div>
        ${this.uncoverPageMenu
          ? nothing
          : html` <div id="toggleSlide" class="${this._sliderClass(this.open)}" @click=${() => this._toggleSlide()}>
              <div>
                <div></div>
                <div></div>
              </div>
            </div>`}
      </div>
    `;
  }

  private async onClickPromo(e, type) {
    e.preventDefault();
    const promoData = promo.find((p) => {
      return p.id === type;
    });
    if (promoData) {
      const result: PromoDialogResult = await DPromoDialog.open({
        promoData,
        features: features,
        employeesCount: this.employeesCount,
        specialTerms: this.specialTerms,
        healthcareSignatoryOptions: [],
        healthcareSignatory: '',
        canUpgrade: this.writeAccess,
      });
      if (result.action === 'upgrade') {
        console.log('upgrade');
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-new-document': DNewDocument;
  }
}
