import { css, html, LitElement, nothing } from 'lit';
import { firstPageSize, PdfPageSize } from './pdf-utility.js';
import './pdf-viewer-page.js';
import type { ParentPdfDocument } from './pdf-viewer-page.js';
import { customElement, property, query, state } from 'lit/decorators.js';
import * as pdfjs from 'pdfjs-dist';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { LitVirtualizer, VisibilityChangedEvent } from '@lit-labs/virtualizer';
import { Direction } from 'src/layout/parts/file-viewer-dialog.js';
import workerUrl from 'pdfjs-dist/build/pdf.worker.mjs?url';

pdfjs.GlobalWorkerOptions.workerSrc = workerUrl;
const styles = css`
  :host {
    position: relative;
    display: flex;
    background: var(--pdf-background, #888);
    flex-direction: column;
    overflow: hidden;
    min-height: 200px;
  }
  #container {
    flex: 1;
    overflow: hidden;
    position: relative;
  }
  .viewer {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    box-sizing: border-box;
  }

  lit-virtualizer {
    flex: 1;
  }
`;

/** Event detail for 'pdf-document-loading' event */
export interface PdfLoadingEventArgs {
  /** URI of the document about to be loaded. */
  src: string;
}

/** Event detail for 'pdf-document-loaded' event */
export interface PdfLoadedEventArgs {
  /** URI of the document successfully loaded. */
  src: string;

  /** Number of pages in the document. */
  pages: number;
}

/** Event detail for 'pdf-document-error' event */
export interface PdfLoadErrorEventArgs {
  /** URI of the document that couldn't be loaded. */
  src: string;

  /** Error message. */
  message: string;

  /** Name of error. */
  name: string;
}

/** Don't allow zooming out past this minimum. */
const minZoom = 0.5;

/** Promote elements searches (just strings) to the arrarys of regular expressions the page find supports.
 * @param input A string, a regular expression, or an array of either.
 * @generator yeilds regular expressions. */
export function* normaliseSearchTerms(input: string | RegExp | (string | RegExp)[]) {
  if (typeof input === 'string') yield new RegExp(input, 'gi');
  else if (input instanceof RegExp) yield input;
  else
    for (const i of input)
      if (typeof i === 'string') yield new RegExp(i, 'gi');
      else if (i instanceof RegExp) yield i;
}

/** Render a PDF document a canvas elements in the page.
 *  No UI provided, just display of the document.
 *  Lifecycle events:
 *      pdf-document-loading: when the source has changed and loading is starting.
 *      pdf-document-loaded: once the document has been successfully loaded (pages may still be rendering).
 *      pdf-document-error: if an error is encountered while loading the document.
 *
 *  Each page is rendered by <pdf-viewer-page>, which uses an IntersectionObserver to only render visible pages.
 *  Styles:
 *       --pdf-background, default: #888, content behind pages.
 *       --pdf-paper, default: #fff, colour of each page (overriden by canvas content).
 *       --pdf-colour-1, default: #f00, colour for 1st term highlight.
 *       --pdf-colour-2, default: #0f0, colour for 2nd term highlight.
 *       --pdf-colour-3, default: #00f, colour for 3rd term highlight.
 *       --pdf-colour-4, default: #fd0, colour for 4th term highlight.
 *       --pdf-colour-5, default: #0fd, colour for 5th term highlight.
 *       --pdf-colour-6, default: #d0f, colour for 6th term highlight.
 *       --pdf-colour-7, default: #df0, colour for 7th term highlight.
 *       --pdf-colour-8, default: #0df, colour for 8th term highlight. (subsequent terms repeat)
 *       --pdf-highlight-opacity, default .4, highlights appear over text with this opacity.
 * */
@customElement('pdf-viewer-document')
export class PdfViewerDocument extends LitElement {
  _currenctPage = 0;
  @query('lit-virtualizer')
  _litVirtualizerEl!: LitVirtualizer;
  /** The initial zoom when opening a document and when the mode change.
   * - 'height': whole page
   * - 'width': full width */
  @property()
  fit: 'height' | 'width' = 'width';
  /** The zoom ratio used. */
  @property({ attribute: 'zoom-ratio', type: Number })
  zoomRatio = 1.25;
  @property()
  highlight: string | RegExp | (string | RegExp)[] | undefined;
  /** Total number of pages in the document, set by parsing the src. */
  private pages?: number;
  @query('#container')
  private container?: HTMLDivElement;
  /** Internal PDF object. */
  @state()
  private pdfProxy?: PDFDocumentProxy;
  /** Optional estimate of page size based on first page */
  private pageSize?: PdfPageSize;

  static get styles() {
    return [styles];
  }

  private _src = '';

  /** URL of the PDF file to display. */
  @property()
  get src(): string {
    return this._src;
  }

  set src(s: string) {
    if (this._src === s) return;

    this._src = s;
    this.srcChanged(this._src);
  }

  private _zoom = 1;

  @property({ type: Number })
  get zoom(): number {
    return this._zoom;
  }

  set zoom(z: number) {
    if (this._zoom === z) return;

    if (this.pdfProxy)
      firstPageSize(this.pdfProxy, z).then((p) => {
        this.pageSize = p;
        this._zoom = z;
        this.requestUpdate('zoom');
      });
    else {
      this._zoom = z;
      this.requestUpdate('zoom');
    }
  }

  render() {
    console.log('render pdf');
    // Convert the number of pages into an array of [1,..., pages]
    return html` <div id="container">${this.renderPdf()}</div>`;
  }

  firstUpdated() {
    console.log('firstUpdated', this.container);
    if (this._src) this.srcChanged(this._src);
  }

  jumpToPage(direction: Direction) {
    if (direction === Direction.NEXT && typeof this.pages === 'number' && this._currenctPage !== this.pages - 1) {
      this._currenctPage++;
    }

    if (direction === Direction.PREVIOUS && this._currenctPage !== 0) {
      this._currenctPage--;
    }

    if (direction === Direction.FIRST) {
      this._currenctPage = 0;
    }

    if (direction === Direction.LAST && this.pages) {
      this._currenctPage = this.pages - 1;
    }

    this._litVirtualizerEl?.scrollToIndex(this._currenctPage);
  }

  async updateFit(fitMode: 'height' | 'width') {
    if (fitMode === 'width') await this.fitWidth();
    else await this.fitHeight();
  }

  /** Zoom in */
  zoomin() {
    this.zoom = this._zoom * this.zoomRatio;
  }

  //connectedCallback() {
  //    super.connectedCallback()
  //    this.addEventListener('iron-resize', this._recenter)
  //}

  /** Zoom out */
  zoomout() {
    this.zoom = Math.max(minZoom, this._zoom / this.zoomRatio);
  }

  _visibilityChanged(e: VisibilityChangedEvent) {
    this._currenctPage = e.first;
  }

  private renderPdf() {
    // Convert the number of pages into an array of [1,..., pages]
    const pages =
      this.pages !== undefined && this.pages > 0
        ? Array.from({ length: this.pages }, (_, n) => ({ page: n + 1 }))
        : undefined;

    const pdf: ParentPdfDocument | undefined = this.pdfProxy
      ? {
          document: this.pdfProxy,
          source: this._src,
        }
      : undefined;

    const pageSize = this.pageSize;

    if (pdf != undefined) {
      return html` <div id="viewer" class="viewer">
        ${pages && pdf && pageSize !== undefined
          ? pages.map(
              (p) =>
                html` <pdf-viewer-page
                  page=${p.page}
                  zoom=${this._zoom}
                  .pageSize=${pageSize}
                  .pdf=${pdf}
                ></pdf-viewer-page>`,
            )
          : nothing}
      </div>`;
    } else return nothing;
  }

  private itemHtml(p: number, pdf: ParentPdfDocument) {
    const hl = this.highlight ? [...normaliseSearchTerms(this.highlight)] : [];

    return this.pageSize !== undefined
      ? html` <pdf-viewer-page
          .page=${p}
          .zoom=${this._zoom}
          .pageSize=${this.pageSize}
          .highlight=${hl}
          .pdf=${pdf}
        ></pdf-viewer-page>`
      : html``;
  }

  /** When the source property is set render
   * @event pdf-viewer-loaded Fired when the download of the pdf succeed.
   * @param src the source string of the pdf. */
  private async srcChanged(src: string) {
    console.log('srcChanged', this.container);
    console.log('pdf-viewer-document srcChanged ' + src + ' ' + this._src);
    if (!this.container) {
      console.log('not loaded yet, defer until it has');
      return;
    } // not loaded yet, defer until it has

    // Clear the pages and document proxy
    this.pages = undefined;
    this.pageSize = undefined;

    if (this.pdfProxy) {
      await this.pdfProxy.destroy();
      this.pdfProxy = undefined;
    }

    if (!src || !navigator.onLine) return;

    console.time(`📃 Loaded PDF ${src}`);
    this.dispatchEvent(
      new CustomEvent<PdfLoadingEventArgs>('pdf-document-loading', {
        detail: { src: src },
        bubbles: true,
        composed: true,
      }),
    );

    // Loaded via <script> tag, create shortcut to access PDF.js exports.
    const pdfjsLib = pdfjs; // await pdfApi();

    try {
      const loadingTask = pdfjsLib.getDocument(src);

      const pdf = await loadingTask.promise;

      if (src !== this.src) return; // src changed while we were loading

      this.pdfProxy = pdf;

      if (this.pdfProxy !== undefined) {
        // Get the size of the first page and estimate rest from that
        this.pageSize = await firstPageSize(this.pdfProxy, this._zoom);
        this.pages = this.pdfProxy.numPages;

        this.dispatchEvent(
          new CustomEvent<PdfLoadedEventArgs>('pdf-document-loaded', {
            detail: {
              src: src,
              pages: this.pages || 0,
            },
            bubbles: true,
            composed: true,
          }),
        );
      }
    } catch (x: any) {
      this.dispatchEvent(
        new CustomEvent<PdfLoadErrorEventArgs>('pdf-document-error', {
          detail: {
            src: src,
            message: x.message,
            name: x.name,
          },
          bubbles: true,
          composed: true,
        }),
      );

      throw x;
    } finally {
      console.timeEnd(`📃 Loaded PDF ${src}`);
      this.requestUpdate();
    }
  }

  /** Display the document full width */
  private async fitWidth() {
    this.fit = 'width';
    if (this.pdfProxy !== undefined && this.container) {
      const viewport = await firstPageSize(this.pdfProxy, 1); // Get page with no scaling
      const rect = this.container.getBoundingClientRect();
      // Avoid errors if element is allowed to stretch past screen boundary
      const width = Math.min(screen.width, window.innerWidth, rect.width);

      const zoom = width / viewport.width;
      if (zoom === this._zoom) return;

      this.pageSize = await firstPageSize(this.pdfProxy, zoom);
      this._zoom = zoom;
      this.requestUpdate('zoom');
    }
  }

  /** Display the whole page */
  private async fitHeight() {
    this.fit = 'height';
    if (this.pdfProxy !== undefined && this.container) {
      const viewport = await firstPageSize(this.pdfProxy, 1); // Get page with no scaling
      const rect = this.container.getBoundingClientRect();
      // Avoid errors if element is allowed to stretch past screen boundary
      const height = Math.min(screen.height, window.innerHeight, rect.height);

      const zoom = (height - 24) / viewport.height;
      if (zoom === this._zoom) return;

      this.pageSize = await firstPageSize(this.pdfProxy, zoom);
      this._zoom = zoom;
      this.requestUpdate('zoom');
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'pdf-viewer-document': PdfViewerDocument;
  }
}
