import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { PageContent } from 'src/pages/page-content.js';
import 'src/utilities/display-alert';
import '../../content/d-entity-header.js';
import '../../library/components/d-help-section.js';
import '../../library/elements/d-action.js';
import { PageViewModel } from 'src/store/api';
import type { AbstractPageView } from 'src/pages/abstract-page-view';
import type { ListSectionItemInput } from 'src/library/lists/utilities';

export interface EmployeeGroup {
  employeeGroupName: string;
  terminated: boolean;
  employees: { name: string; functions: string[]; href: string; profession: string; hasDraft: boolean }[];
}

export interface EmployeesPageViewModel extends AbstractPageView {
  type: 'employees-page';
  icon: 'employees';
  employeeGroups: ListSectionItemInput[];
  pdfHref: string;
}

/**
 *
 */
@customElement('d-employees-page-content')
export class DEmployeesPageContent extends PageContent<EmployeesPageViewModel> {
  @property({ type: Array })
  pages: PageViewModel[] = [];
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  editMode = false;
  @property({ type: Object })
  token = {};
  @property({ type: Boolean })
  uncoverPageMenu = false;
  @property({ type: String })
  key = '';

  onShareContent() {
    this.dispatchEvent(new CustomEvent('open-send-message', { bubbles: true, composed: true, detail: {} }));
  }

  renderContent() {
    return html`<d-list-section
      no-header
      icon="employees"
      field="organization_employees"
      .items=${this.pageView.employeeGroups}
      theme-page
      .contentStickyTop=${this.contentStickyTop}
    >
    </d-list-section> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-employees-page-content': DEmployeesPageContent;
  }
}
