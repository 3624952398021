/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface HelseIDTokenLoginMessage
 */
export interface HelseIDTokenLoginMessage {
  /**
   *
   * @type {string}
   * @memberof HelseIDTokenLoginMessage
   */
  idToken: string;
  /**
   * Used to upgrade a login from less secure
   * @type {string}
   * @memberof HelseIDTokenLoginMessage
   */
  currentAccessToken?: string;
}

/**
 * Check if a given object implements the HelseIDTokenLoginMessage interface.
 */
export function instanceOfHelseIDTokenLoginMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'idToken' in value;

  return isInstance;
}

export function HelseIDTokenLoginMessageFromJSON(json: any): HelseIDTokenLoginMessage {
  return HelseIDTokenLoginMessageFromJSONTyped(json, false);
}

export function HelseIDTokenLoginMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HelseIDTokenLoginMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    idToken: json['idToken'],
    currentAccessToken: !exists(json, 'currentAccessToken') ? undefined : json['currentAccessToken'],
  };
}

export function HelseIDTokenLoginMessageToJSON(value?: HelseIDTokenLoginMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    idToken: value.idToken,
    currentAccessToken: value.currentAccessToken,
  };
}
