import { createSelector } from '@reduxjs/toolkit';
import type { State } from 'src/store/types';

export const userPropertiesForCurrentOrganization = createSelector(
  (state: State) => state.user,
  (state: State) => state.id,
  (
    user,
    id,
  ): {
    [key: string]: any;
  } => {
    try {
      const result: { [key: string]: any } = {};
      if (user !== undefined && id) {
        const prefix = id + '_';

        const keys = Object.keys(user);

        keys
          .filter((k) => k.startsWith(prefix))
          .forEach((k) => {
            if (user !== undefined) {
              const key = k.slice(prefix.length);
              result[key] = user[k];
            }
          });
      }
      return result;
    } catch (e) {
      return {};
    }
  },
);
