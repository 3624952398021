import { css, html, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import { LocalDate } from 'src/utilities/local-date.js';
import '../../content/issues/d-external-report-item.js';
import '../../library/editors/index.js';
import { AbstractEntityView, EntityContent, ListDefinition } from '../entity-content.js';
import './d-issue-behavior.js';
import { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';
import { SectionField } from 'src/library/components/d-field-section';
import { descriptionForExternal } from 'src/content/issues/d-issue-behavior';

export interface IssueView extends AbstractEntityView<IssueViewEditItem> {
  /**
   * Report
   */
  reportedDate: string;
  reportedBy: string;
  reportedByName: string;
  reportedTo: string;
  reportedToName: string;
  description: string;
  eventDate: string;
  immediateMeasures: string;
  furtherMeasures: string;
  /**
   * Processing
   */
  name: string;
  processedDate: string;
  processedBy: string;
  processedByOptions: SelectDropdownOption[];
  processedByName: string;
  notes: string;
  /**
   * Checklist
   */
  personDamagePossibility: boolean;
  relateToPatient: boolean;
  relateToEmployees: boolean;
  workRelatedHealthIssue: boolean;
  relateToEquipment: boolean;
  relateToRadiation: boolean;
  informationLeakagePossibility: boolean;
  relateToMaritimeHealthCertificate: boolean;
  /**
   * Work related health issue
   */
  workRelatedHealthIssueEmployee: string;
  workRelatedHealthIssueEmployeeOptions: SelectDropdownOption[];
  workRelatedHealthIssueEmployeeName: string;
  workRelatedHealthIssueDescription: string;
  workRelatedInjury: boolean;
  workRelatedSickness: boolean;
  workRelatedHealthIssueType: string;
  workRelatedInjuryTask: string;
  workRelatedInjuryIncidentCause: string;
  workRelatedSicknessCausingTask: string;
  workRelatedSicknessCausingTaskLocation: string;
  workRelatedSicknessCausingEnvironment: string;
  /**
   * Measures
   */
  requiresMeasures?: boolean;
  identifiedMeasures: string;
  implementedMeasure?: boolean;
  measuresSatisfactorily?: boolean;
  /**
   * Evaluation
   */
  evaluated?: boolean; // I.e. 'needs evaluation'
  evaluatedDate: string;
  evaluatedBy: string;
  evaluatedByOptions: SelectDropdownOption[];
  evaluatedByName: string;
  evaluatedOk?: boolean;
  processed: boolean;
  /**
   * Meta data and ui resources
   */
  type: 'issues';
  uuid: string;
  pages: string[];
  availablePages: SelectTagOption[];
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  classificationText: string;
  currentUserIsEmployee: boolean;
  maritimeDoctor: boolean;
}

export interface IssueViewEditItem {
  /**
   * Report
   */
  reportedDate: string;
  reportedBy: string;
  reportedTo: string;
  description: string;
  eventDate: string;
  immediateMeasures: string;
  furtherMeasures: string;
  /**
   * Processing
   */
  name: string;
  processedDate: string;
  processedBy: string;
  notes: string;
  /**
   * Checklist
   */
  personDamagePossibility: boolean;
  relateToPatient: boolean;
  relateToEmployees: boolean;
  workRelatedHealthIssue: boolean;
  relateToEquipment: boolean;
  relateToRadiation: boolean;
  informationLeakagePossibility: boolean;
  relateToMaritimeHealthCertificate: boolean;
  /**
   * Work related health issue
   */
  workRelatedHealthIssueEmployee: string;
  workRelatedHealthIssueDescription: string;
  workRelatedInjury: boolean;
  workRelatedSickness: boolean;
  workRelatedInjuryTask: string;
  workRelatedInjuryIncidentCause: string;
  workRelatedSicknessCausingTask: string;
  workRelatedSicknessCausingTaskLocation: string;
  workRelatedSicknessCausingEnvironment: string;
  /**
   * Measures
   */
  requiresMeasures?: boolean;
  identifiedMeasures: string;
  implementedMeasure?: boolean;
  measuresSatisfactorily?: boolean;
  /**
   * Evaluation
   */
  evaluated?: boolean; // I.e. 'needs evaluation'
  evaluatedDate: string;
  evaluatedBy: string;
  evaluatedOk?: boolean;
  /**
   * Meta data and ui resources
   */
  type: 'issues';
  uuid: string;
  pages: string[];
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
}

/**
 *
 */
@customElement('d-issue-view')
export class DIssueView extends EntityContent<IssueView, IssueViewEditItem> {
  static readonly styles = [
    ...EntityContent.styles,
    css`
      .status {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        border-left: 1px solid var(--borderColor);
        border-right: 1px solid var(--borderColor);
        border-bottom: 1px solid var(--borderColor);
        padding: 15px 12px 14px 48px;
        background: hsl(0, 0%, 96%) url(/images/alert.svg) 12px 9px no-repeat;
        background-size: 34px;
      }
      .processed {
        background: hsl(0, 0%, 96%) url(/images/check-mini-blue.svg) 12px 9px no-repeat;
        background-size: 34px;
      }
      .status d-label {
        margin-right: 8px;
      }
      .status d-action {
        margin: -6px -8px;
      }
      .report {
        margin-top: 12px;
        margin-bottom: -1px;
        border: 1px solid var(--borderColor);
        background: hsl(0, 0%, 96%);
        padding: 0 20px;
        overflow: hidden;
      }
      .report > * {
        margin-top: -2px;
      }
      .process {
        overflow: hidden;
      }
      .process > * {
        margin-top: -1px;
      }
    `,
  ];
  @property({ type: String })
  entityType = 'issues';
  @property({ type: String })
  itemReportedTo = '';
  @property({ type: String })
  itemProcessedBy = '';
  @property({ type: Array })
  employees = [];
  @property({ type: String })
  itemEvaluatedBy = '';
  @property({ type: Boolean })
  dentist = false;
  @property({ type: Object })
  fieldsByCode = {};
  @property({ type: String })
  informationLeakagePossibilityTooltipSimplified = 'undefined';
  @property({ type: String })
  externalRecipient = '';
  @property({
    type: Boolean,
  })
  pllParticipant = false;
  @property({
    type: Array,
  })
  externalRecipients = [];

  mainHeaderActions() {
    return super.mainHeaderActions().map((action) => {
      if (action.action === 'edit') {
        action.name = 'Behandle';
      }
      return action;
    });
  }
  _externalReports(issue) {
    const l = issue.externalIssueMessages ?? [];
    return l.map((x) => {
      return {
        created: x.timestamp + ' 12:00:00',
        senderName: '',
        recipientName: x.recipientName,
        subject: 'Ikke tilgjengelig',
        description: '<h1>Krever innlogging med HelseID</h1>',
      };
    });
  }

  _sendExternalReport(e) {
    if (e.target.hasAttribute('disabled')) {
      return;
    }
    const fullDescription = descriptionForExternal(
      this.entityView.description,
      this.entityView.eventDate,
      this.entityView.immediateMeasures,
      this.entityView.furtherMeasures,
    );
    const returnUrl = window.location.href;
    window.location.href =
      'https://avvik.trinnvis.io/?issue-id=' +
      this.entityView.uuid +
      '&public-recipient=' +
      this.externalRecipient +
      '&public-subject=' +
      encodeURI(this.entityView.name) +
      '&public-description=' +
      encodeURI(fullDescription) +
      '&return-url=' +
      returnUrl;
  }

  _dateFormat(value) {
    return value ? LocalDate.fromString(value).toStringForDisplay() : '';
  }

  ready() {
    this._fetchRecipients();

    if (
      location.href.startsWith('https://app.trinnvis.no/account/2668') ||
      location.href.startsWith('https://app.trinnvis.no/account/971')
    ) {
      this.pllParticipant = true;
    }
  }

  _fetchRecipients() {
    fetch('https://api.trinnvis.io/external-issues/recipients/')
      .then((response) => response.text())
      .then((recipients) => {
        const list = JSON.parse(recipients);
        const options = list.map((item) => ({
          uuid: item.id,
          value: item.description,
        }));
        this.externalRecipients = options;
      });
  }

  formatAsSentTime(date) {
    if (date === undefined) {
      return '';
    }
    const localDate = LocalDate.fromString(date.substring(0, 10));
    const dateAsString = localDate.toStringForDisplayWithDayOfWeekAndYear();
    const timePartHours = date.substring(11, 13);
    const timePartMinutes = date.substring(14, 16);
    const timeAsString = 'kl. ' + timePartHours + '.' + timePartMinutes;
    return dateAsString + ' ' + timeAsString;
  }

  capitalize(text) {
    return text.toLowerCase().replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  }

  async initializeEditItem() {
    this.editItem = {
      reportedDate: this.entityView.reportedDate,
      reportedBy: this.entityView.reportedBy,
      reportedTo: this.entityView.reportedTo,
      description: this.entityView.description,
      eventDate: this.entityView.eventDate,
      immediateMeasures: this.entityView.immediateMeasures,
      furtherMeasures: this.entityView.furtherMeasures,
      /**
       * Processing
       */
      name: this.entityView.name,
      processedDate: this.entityView.processedDate,
      processedBy: this.entityView.processedBy,
      notes: this.entityView.notes,
      /**
       * Checklist
       */
      personDamagePossibility: this.entityView.personDamagePossibility,
      relateToPatient: this.entityView.relateToPatient,
      relateToEmployees: this.entityView.relateToEmployees,
      workRelatedHealthIssue: this.entityView.workRelatedHealthIssue,
      relateToEquipment: this.entityView.relateToEquipment,
      relateToRadiation: this.entityView.relateToRadiation,
      informationLeakagePossibility: this.entityView.informationLeakagePossibility,
      relateToMaritimeHealthCertificate: this.entityView.relateToMaritimeHealthCertificate,
      /**
       * Work related health issue
       */
      workRelatedHealthIssueEmployee: this.entityView.workRelatedHealthIssueEmployee,
      workRelatedHealthIssueDescription: this.entityView.workRelatedHealthIssueDescription,
      workRelatedInjury: this.entityView.workRelatedInjury,
      workRelatedSickness: this.entityView.workRelatedSickness,
      workRelatedInjuryTask: this.entityView.workRelatedInjuryTask,
      workRelatedInjuryIncidentCause: this.entityView.workRelatedInjuryIncidentCause,
      workRelatedSicknessCausingTask: this.entityView.workRelatedSicknessCausingTask,
      workRelatedSicknessCausingTaskLocation: this.entityView.workRelatedSicknessCausingTaskLocation,
      workRelatedSicknessCausingEnvironment: this.entityView.workRelatedSicknessCausingEnvironment,
      /**
       * Measures
       */
      requiresMeasures: this.entityView.requiresMeasures,
      identifiedMeasures: this.entityView.identifiedMeasures,
      implementedMeasure: this.entityView.implementedMeasure,
      measuresSatisfactorily: this.entityView.measuresSatisfactorily,
      /**
       * Evaluation
       */
      evaluated: this.entityView.evaluated,
      evaluatedDate: this.entityView.evaluatedDate,
      evaluatedBy: this.entityView.evaluatedBy,
      evaluatedOk: this.entityView.evaluatedOk,
      /**
       * Meta data and ui resources
       */
      type: 'issues',
      uuid: this.entityView.uuid,
      pages: this.entityView.pages,
      classification: this.entityView.classification,
      accessControl: this.entityView.accessControl,
    };
  }

  viewFieldClassification(): SectionField[] {
    return [
      {
        field: 'classificationText',
        fieldForLabel: 'generalFields_access',
        type: 'view-text',
        class: 'fullWidth',
        condition: this.entityView.classificationText !== '',
      },
    ];
  }

  viewFieldsReport(editMode: boolean = false): SectionField[] {
    return [
      {
        field: 'report',
        sublabel: editMode ? 'Ikke redigbar' : '',
        type: 'header',
        size: 'verybig',
      },
      {
        field: 'reportedDate',
        type: 'view-date',
        format: 'date-short',
        class: 'minWidth200',
        condition: true,
      },
      {
        field: 'reportedByName',
        fieldForLabel: 'reportedBy',
        type: 'view-text',
        class: 'minWidth200',
      },
      {
        field: 'reportedToName',
        fieldForLabel: 'reportedTo',
        type: 'view-text',
        class: 'minWidth200',
      },
      {
        field: 'description',
        type: 'view-text',
        class: 'fullWidth',
      },
      {
        field: 'eventDate',
        type: 'view-date',
        format: 'date-short',
        class: 'minWidth200',
        hideIfEmpty: true,
      },
      {
        field: 'immediateMeasures',
        type: 'view-text',
        class: 'fullWidth',
        hideIfEmpty: true,
      },
      {
        field: 'furtherMeasures',
        type: 'view-text',
        class: 'fullWidth',
        hideIfEmpty: true,
      },
    ];
  }

  viewFieldsProcessAnalysis(): SectionField[] {
    return [
      {
        field: 'process',
        type: 'header',
        size: 'verybig',
      },
      {
        field: 'notes',
        type: 'view-text',
        class: 'fullWidth',
      },
      {
        field: 'processedDate',
        type: 'view-date',
        format: 'date-short',
        class: 'minWidth200',
      },
      {
        field: 'processedByName',
        type: 'view-text',
        class: 'minWidth200',
      },
      {
        field: 'personalDamage',
        type: 'view-conditional-bullet-list',
        condition: this.entityView.personDamagePossibility,
        listItems: [
          {
            field: 'personDamagePossibility',
          },
          {
            field: 'relateToPatient',
          },
          {
            field: 'relateToEmployees',
          },
          {
            field: 'workRelatedHealthIssue',
          },
        ],
      },
      {
        field: 'workRelatedHealthIssueTitle',
        type: 'header',
        condition: this.entityView.workRelatedHealthIssue,
      },
      {
        field: 'workRelatedHealthIssueType',
        type: 'view-text',
        class: 'fullWidth',
        condition: this.entityView.workRelatedHealthIssue,
      },
      {
        field: 'workRelatedHealthIssueDescription',
        type: 'view-text',
        class: 'fullWidth',
        condition: this.entityView.workRelatedHealthIssue,
      },
      {
        field: 'workRelatedInjuryTask',
        type: 'view-text',
        class: 'fullWidth',
        condition: this.entityView.workRelatedInjury,
      },
      {
        field: 'workRelatedInjuryIncidentCause',
        type: 'view-text',
        class: 'fullWidth',
        condition: this.entityView.workRelatedInjury,
      },
      {
        field: 'equipmentTitle',
        type: 'view-conditional-bullet-list',
        condition: this.entityView.relateToEquipment || this.entityView.relateToRadiation,
        listItems: [
          {
            field: 'relateToEquipment',
          },
          {
            field: 'relateToRadiation',
          },
        ],
      },
      {
        field: 'personalInformationTitle',
        type: 'view-conditional-bullet-list',
        condition: this.entityView.informationLeakagePossibility,
        listItems: [
          {
            field: 'informationLeakagePossibility',
          },
        ],
      },
      {
        field: 'maritimeDoctorTitle',
        type: 'view-conditional-bullet-list',
        condition: this.entityView.relateToMaritimeHealthCertificate,
        listItems: [
          {
            field: 'relateToMaritimeHealthCertificate',
          },
        ],
      },
    ];
  }

  viewFieldsProcessMeasures(): SectionField[] {
    return [
      {
        field: 'measuresTitle',
        type: 'header',
      },
      {
        field: '',
        type: 'view-conditional-bullet-list',
        condition: true,
        listItems: [
          {
            field: 'requiresMeasures',
            elseField: 'requiresMeasuresNOT',
            hideIfNull: true,
          },
        ],
      },
      {
        field: 'identifiedMeasures',
        type: 'view-text',
        noValueValue: 'Ingen tiltak er bestemt',
        class: 'fullWidth',
        condition: this.entityView.requiresMeasures,
      },
      {
        field: '',
        type: 'view-conditional-bullet-list',
        condition: !isEmptyOrInvalidString(this.entityView.identifiedMeasures),
        listItems: [
          {
            field: 'implementedMeasure',
            elseField: 'implementedMeasureNOT',
            hideIfNull: true,
          },
          {
            field: 'evaluated',
            elseField: 'evaluatedNOT',
            hideIfNull: true,
          },
        ],
      },
      {
        field: 'evaluatedDate',
        type: 'view-date',
        format: 'date-short',
        class: 'minWidth200',
        condition: this.entityView.evaluated,
      },
      {
        field: 'evaluatedByName',
        type: 'view-text',
        class: 'minWidth200',
        condition: this.entityView.evaluated,
      },
      {
        field: '',
        type: 'view-conditional-bullet-list',
        condition: this.entityView.evaluated,
        listItems: [
          {
            field: 'evaluatedOk',
            elseField: 'evaluatedOkNOT',
            hideIfNull: true,
          },
        ],
      },
    ];
  }

  renderViewMode() {
    return html`
      <div class="status ${this.entityView.processed ? 'processed' : ''}">
        <d-label .field=${this.entityView.processed ? 'issues_processed' : 'issues_processedNOT'}></d-label>
        ${!this.entityView.processed && !this.hideEdit()
          ? html` <d-action plain @click=${() => this.onEditModeOn()}>Behandle avviket</d-action> `
          : nothing}
      </div>
      ${this.renderFieldSection('issues', this.viewFieldClassification(), this.entityView)}
      <div class="report">${this.renderFieldSection('issues', this.viewFieldsReport(), this.entityView)}</div>
      ${this.renderPllReportView()} ${this.renderPllReportView()}
      <div class="process">${this.renderFieldSection('issues', this.viewFieldsProcess(), this.entityView)}</div>
    `;
  }

  editFieldsWorkRelatedHealthIssue(item: IssueViewEditItem): SectionField[] {
    return [
      {
        type: 'expansion',
        opened: item.workRelatedHealthIssue,
        resetHiddenFields: true,
        fields: [
          {
            label: 'Arbeidsrelatert skade eller sykdom',
            type: 'header',
          },
          {
            field: 'eventDate',
            type: 'edit-date',
          },
          {
            field: '',
            label: 'Type',
            type: 'edit-checkboxes',
            options: [
              { value: 'workRelatedInjury', text: 'Skade' },
              { value: 'workRelatedSickness', text: 'Sykdom' },
            ],
            storedAsOptions: true,
            class: 'minWidth300',
          },
          {
            field: 'workRelatedHealthIssueEmployee',
            type: 'edit-dropdown',
            options: this.entityView.workRelatedHealthIssueEmployeeOptions,
            placeholder: 'Velg person',
            class: 'minWidth300',
          },
          {
            field: 'workRelatedHealthIssueDescription',
            type: 'edit-textarea',
            class: 'fullWidth',
          },
        ],
      },
      {
        type: 'expansion',
        opened: item.workRelatedInjury,
        resetHiddenFields: true,
        fields: [
          {
            field: 'workRelatedInjuryTask',
            type: 'edit-textarea',
            class: 'fullWidth',
          },
          {
            field: 'workRelatedInjuryIncidentCause',
            type: 'edit-textarea',
            class: 'fullWidth',
          },
        ],
      },
      {
        type: 'expansion',
        opened: item.workRelatedSickness,
        resetHiddenFields: true,
        fields: [
          {
            field: 'workRelatedSicknessCausingTask',
            type: 'edit-textarea',
            class: 'fullWidth',
          },
          {
            field: 'workRelatedSicknessCausingTaskLocation',
            type: 'edit-textarea',
            class: 'fullWidth',
          },
          {
            field: 'workRelatedSicknessCausingEnvironment',
            type: 'edit-textarea',
            class: 'fullWidth',
          },
        ],
      },
    ];
  }

  editFieldsMeasures(item: IssueViewEditItem): SectionField[] {
    return [
      {
        field: '',
        label: 'Tiltak',
        type: 'header',
      },
      {
        field: 'requiresMeasures',
        type: 'edit-radio',
        options: [
          { value: 'true', text: 'Ja' },
          { value: 'false', text: 'Nei' },
        ],
        valueType: 'boolean',
        class: 'fullWidth',
        inlineLabel: true,
      },
      {
        type: 'expansion',
        opened: item.requiresMeasures === true,
        resetHiddenFields: true,
        fields: [
          {
            field: 'identifiedMeasures',
            type: 'edit-textarea',
            class: 'fullWidth',
          },
          {
            type: 'expansion',
            opened: !isEmptyOrInvalidString(item.identifiedMeasures),
            fields: [
              {
                field: 'implementedMeasure',
                type: 'edit-radio',
                options: [
                  { value: 'true', text: 'Ja' },
                  { value: 'false', text: 'Nei' },
                ],
                valueType: 'boolean',
                class: 'fullWidth',
                inlineLabel: true,
              },
              {
                type: 'expansion',
                opened: item.implementedMeasure === true,
                resetHiddenFields: true,
                fields: [
                  {
                    field: 'evaluated',
                    type: 'edit-radio',
                    options: [
                      { value: 'true', text: 'Ja' },
                      { value: 'false', text: 'Nei' },
                    ],
                    valueType: 'boolean',
                    class: 'fullWidth',
                    inlineLabel: true,
                  },
                  {
                    type: 'expansion',
                    opened: item.evaluated === true,
                    resetHiddenFields: true,
                    fields: [
                      {
                        field: 'evaluatedDate',
                        type: 'edit-date',
                      },
                      {
                        field: 'evaluatedBy',
                        type: 'edit-dropdown',
                        placeholder: 'Velg person',
                        class: 'minWidth300',
                        options: this.entityView.evaluatedByOptions,
                      },
                      {
                        field: 'evaluatedOk',
                        type: 'edit-radio',
                        options: [
                          { value: 'true', text: 'Ja' },
                          { value: 'false', text: 'Nei' },
                        ],
                        valueType: 'boolean',
                        class: 'fullWidth',
                        inlineLabel: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
  }

  editFields(item: IssueViewEditItem): SectionField[] {
    return this.editFieldsBasic(item).concat(
      this.editFieldsChecklist(item),
      this.editFieldsWorkRelatedHealthIssue(item),
      this.editFieldsMeasures(item),
    );
  }

  renderEditItem(item: IssueViewEditItem): TemplateResult<1> {
    return html`
      ${this.renderEditClassification(item)}
      <div class="report">${this.renderFieldSection('issues', this.viewFieldsReport(true), this.entityView)}</div>
      <div class="process">${this.renderFieldSection('issues', this.editFields(item), item)}</div>
    `;
  }

  protected viewFieldsProcess(): SectionField[] {
    return this.viewFieldsProcessAnalysis().concat(this.viewFieldsProcessMeasures());
  }

  protected viewFields(): SectionField[] {
    return this.viewFieldClassification().concat(this.viewFieldsReport(), this.viewFieldsProcess());
  }

  protected lists(): ListDefinition[] {
    return [
      {
        field: 'generalFields_attachments',
        type: 'custom',
        icon: 'attachments',
        items: 'attachments',
        render: () => this.renderAttachments(),
        condition: this.entityView.featureStates.core,
      },
    ];
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  private renderPllReportView() {
    return this.pllParticipant
      ? html` <div class="pllReportField">
          <d-label label="Ekstern melding (under utprøving)"></d-label>
          <div class="externalReportList">
            ${this._externalReports(this.entityView).map(
              (report) =>
                html` <d-external-report-item
                  created-display="${this._dateFormat(report.created)}"
                  created-display-full="${this.formatAsSentTime(report.created)}"
                  recipient-name="${report.recipientName}"
                  sender-name="${this.capitalize(report.senderName)}"
                  subject="${report.subject}"
                  description="${report.description}"
                ></d-external-report-item>`,
            )}
          </div>
          <div class="pllForm">
            <d-select-dropdown
              placeholder="Velg mottaker"
              label=""
              .options="${this.externalRecipients}"
              value="${this.externalRecipient}"
            ></d-select-dropdown>
            <button @click=${(e) => this._sendExternalReport(e)} ?disabled=${!this.externalRecipient}>Send</button>
          </div>
        </div>`
      : nothing;
  }

  private editFieldsBasic(item: IssueViewEditItem): SectionField[] {
    return [
      {
        field: 'process',
        type: 'header',
        size: 'verybig',
      },
      {
        field: 'name',
        type: 'edit-text',
        placeholder: item.classification !== 'NONE' ? 'NB! Navnet du velger her vil være synlig for alle brukere.' : '',
        class: 'fullWidth',
      },
      {
        field: 'processedDate',
        type: 'edit-date',
        class: 'minWidth200',
      },
      {
        field: 'processedBy',
        type: 'edit-dropdown',
        class: 'minWidth300',
        options: this.entityView.processedByOptions,
        placeholder: 'Velg person',
      },
      {
        field: 'notes',
        type: 'edit-textarea',
        class: 'fullWidth',
      },
    ];
  }

  private editFieldsChecklist(item: IssueViewEditItem): SectionField[] {
    return [
      {
        field: 'checklist',
        type: 'header',
      },
      {
        field: 'personDamagePossibility',
        optionField: 'personDamagePossibility',
        type: 'edit-checkbox',
        class: 'fullWidth',
      },
      {
        type: 'expansion',
        indent: 20,
        opened: item.personDamagePossibility,
        resetHiddenFields: true,
        fields: [
          {
            field: 'relateToPatient',
            optionField: 'relateToPatient',
            type: 'edit-checkbox',
            class: 'fullWidth',
            hideOptionTooltip: true,
            condition: this.entityView.sector === 'HEALTH',
          },
          {
            type: 'expansion',
            indent: 20,
            condition: true,
            opened: item.relateToPatient,
            resetHiddenFields: true,
            fields: [
              {
                field: 'relateToPatient',
                type: 'view-info',
                class: 'fullWidth',
              },
            ],
          },
          {
            field: 'relateToEmployees',
            optionField: 'relateToEmployees',
            type: 'edit-checkbox',
            class: 'fullWidth',
            hideOptionTooltip: true,
          },
          {
            type: 'expansion',
            indent: 20,
            condition: true,
            opened: item.relateToEmployees,
            resetHiddenFields: true,
            fields: [
              {
                field: 'relateToEmployees',
                type: 'view-info',
                class: 'fullWidth',
              },
              {
                field: 'workRelatedHealthIssue',
                optionField: 'workRelatedHealthIssue',
                type: 'edit-checkbox',
                class: 'fullWidth',
                hideOptionTooltip: true,
              },
              {
                type: 'expansion',
                indent: 20,
                condition: true,
                opened: item.workRelatedHealthIssue,
                fields: [
                  {
                    field: 'workRelatedHealthIssue',
                    type: 'view-info',
                    class: 'fullWidth',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        field: 'relateToEquipment',
        optionField: 'relateToEquipment',
        type: 'edit-checkbox',
        class: 'fullWidth',
        hideOptionTooltip: true,
        condition: this.entityView.sector === 'HEALTH',
      },
      {
        type: 'expansion',
        indent: 20,
        opened: item.relateToEquipment,
        resetHiddenFields: true,
        fields: [
          {
            field: 'relateToEquipment',
            type: 'view-info',
            class: 'fullWidth',
          },
        ],
      },
      {
        field: 'relateToRadiation',
        optionField: 'relateToRadiation',
        type: 'edit-checkbox',
        class: 'fullWidth',
        hideOptionTooltip: true,
        condition: this.entityView.sector === 'HEALTH',
      },
      {
        type: 'expansion',
        indent: 20,
        opened: item.relateToRadiation,
        resetHiddenFields: true,
        fields: [
          {
            field: 'relateToRadiation',
            type: 'view-info',
            class: 'fullWidth',
          },
        ],
      },
      {
        field: 'informationLeakagePossibility',
        optionField: 'informationLeakagePossibility',
        type: 'edit-checkbox',
        class: 'fullWidth',
        hideOptionTooltip: true,
      },
      {
        type: 'expansion',
        indent: 20,
        condition: true,
        opened: item.informationLeakagePossibility,
        resetHiddenFields: true,
        fields: [
          {
            field: 'informationLeakagePossibility',
            type: 'view-info',
            class: 'fullWidth',
          },
          {
            field: 'informationLeakagePossibilityHEALTH',
            type: 'view-info',
            class: 'fullWidth',
            condition: this.entityView.sector === 'HEALTH',
          },
        ],
      },
      {
        field: 'relateToMaritimeHealthCertificate',
        optionField: 'relateToMaritimeHealthCertificate',
        type: 'edit-checkbox',
        class: 'fullWidth',
        hideOptionTooltip: true,
        condition: this.entityView.maritimeDoctor,
      },
      {
        type: 'expansion',
        indent: 20,
        opened: item.relateToMaritimeHealthCertificate,
        resetHiddenFields: true,
        fields: [
          {
            field: 'relateToMaritimeHealthCertificate',
            type: 'view-info',
            class: 'fullWidth',
          },
        ],
      },
    ];
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-issue-view': DIssueView;
  }
}
