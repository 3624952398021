import Bugsnag from '@bugsnag/js';
import type { ActionCreator, ThunkAction } from '@reduxjs/toolkit';
import { ApiClient, cache, getBasePath, identify, pageIds, RootState } from 'src/store';
import type { LocalDate } from 'src/utilities/local-date.js';
import {
  CompleteOrganizationViewModel,
  CreateOrUpdateAssetRequest,
  CreateOrUpdateContractRequest,
  CreateOrUpdateEmployeeRequest,
  CreateOrUpdatePartnerRequest,
  CreateOrUpdateRiskAssessmentRequest,
  EmployeeUpdateMessageGenderEnum,
  EventOccurrenceViewModel,
  FunctionTaskUpdateMessage,
  MeetingOccurrenceViewModel,
  OrganizationReference,
  PageGroupViewModel,
  ReferenceViewModel,
  SendMessageCommand,
  SendShareVacationCommand,
  StopShareVacationCommand,
  TopicViewModel,
} from '../api';
import { BASE_PATH } from '../config.js';
import {
  DAction,
  DeleteItemAction,
  EntityViewModel,
  LoadedCachedOrganizationAction,
  LoadedErrorAction,
  LoadedEventOccurrencesAction,
  LoadedMeetingOccurrencesAction,
  LoadedOrganizationAction,
  LoadedPageGroupsAction,
  LoadedReferencesAction,
  LoadedTopicsAction,
  LoadingOrganizationAction,
  OrganizationState,
  SetTodayAction,
  State,
  UpdateCalendarSingleUserViewAction,
  UpdateCalendarWeekAction,
  UpdateCopilotOpenAction,
  UpdateCurrentEditLevelAction,
  UpdateCurrentHelpPageAction,
  UpdateCurrentSideContentTemplateIdAction,
  UpdateCurrentTutorialIdAction,
  UpdateHelpViewerOpenAction,
  UpdateItemAction,
  UpdatePathAction,
  UpdatePopupOpenAction,
  UpdateRecognizedTextAction,
  UpdateSelectedStartTaskAction,
  UpdateSideContentOpenAction,
  UpdateTutorialViewerOpenAction,
  UpdateUserAction,
  UpdateUserPropertiesAction,
  User,
} from '../types.js';
import { getOrganization } from 'src/store/selectors/organization.js';
import { Buffer } from 'buffer/index.js';

export type NotifyErrorCallback = (error: Error) => void;
let notifyError: NotifyErrorCallback = () => {
  // empty placeholder
};

export type AlertCallback = (message: string) => void;
let alert: AlertCallback = () => {
  // empty placeholder
};

export function initActions(notifyErrorCallback: NotifyErrorCallback, alertCallback: AlertCallback): void {
  notifyError = notifyErrorCallback;
  alert = alertCallback;
}

class ResponseError extends Error {
  response: any;
}

export type ThunkResult<R> = ThunkAction<R, RootState, unknown, DAction>;

export const updatePath: ActionCreator<UpdatePathAction> = (path: string, queryParams: Record<string, string>) => {
  return {
    type: 'UPDATE_PATH',
    path: path,
    queryParams: queryParams,
  };
};

export const deleteItemAction: ActionCreator<DeleteItemAction> = (message: { path: string }) => {
  return {
    type: 'DELETE_ITEM',
    message: message,
  };
};

export const updateItemAction: ActionCreator<UpdateItemAction> = (message: { path: string; item: EntityViewModel }) => {
  return {
    type: 'UPDATE_ITEM',
    message: message,
  };
};

export const updateUserAction: ActionCreator<UpdateUserAction> = (user?: User) => {
  return {
    type: 'UPDATE_USER',
    user: user,
  };
};

export const updateWeekStart: ActionCreator<UpdateCalendarWeekAction> = (weekStart: string) => {
  return {
    type: 'UPDATE_CALENDAR_WEEK',
    weekStart: weekStart,
  };
};

export const updatePopupOpen: ActionCreator<UpdatePopupOpenAction> = (value: boolean) => {
  return {
    type: 'UPDATE_POPUP_OPEN',
    popupOpen: value,
  };
};

export const updateCurrentEditLevel: ActionCreator<UpdateCurrentEditLevelAction> = (value: number) => {
  return {
    type: 'UPDATE_CURRENT_EDIT_LEVEL',
    currentEditLevel: value,
  };
};

export const updateHelpViewerOpen: ActionCreator<UpdateHelpViewerOpenAction> = (value: boolean) => {
  return {
    type: 'UPDATE_HELP_VIEWER_OPEN',
    helpViewerOpen: value,
  };
};

export const updateSideContentOpen: ActionCreator<UpdateSideContentOpenAction> = (value: boolean) => {
  return {
    type: 'UPDATE_SIDE_CONTENT_OPEN',
    sideContentOpen: value,
  };
};

export const updateCopilotOpen: ActionCreator<UpdateCopilotOpenAction> = (value: boolean) => {
  return {
    type: 'UPDATE_COPILOT_OPEN',
    copilotOpen: value,
  };
};

export const updateTutorialViewerOpen: ActionCreator<UpdateTutorialViewerOpenAction> = (value: boolean) => {
  return {
    type: 'UPDATE_TUTORIAL_VIEWER_OPEN',
    tutorialViewerOpen: value,
  };
};

export const updateCurrentTutorialId: ActionCreator<UpdateCurrentTutorialIdAction> = (value: string | undefined) => {
  return {
    type: 'UPDATE_CURRENT_TUTORIAL_ID',
    currentTutorialId: value,
  };
};

export const updateCurrentHelpPage: ActionCreator<UpdateCurrentHelpPageAction> = (currentHelpPage: string) => {
  return {
    type: 'UPDATE_CURRENT_HELP_PAGE',
    currentHelpPage: currentHelpPage,
  };
};

export const updateRecognizedText: ActionCreator<UpdateRecognizedTextAction> = (text?: string) => {
  return {
    type: 'UPDATE_RECOGNIZED_TEXT',
    text: text,
  };
};

export const updateCurrentSideContentTemplateId: ActionCreator<UpdateCurrentSideContentTemplateIdAction> = (
  currentSideContentTemplateId: number,
) => {
  return {
    type: 'UPDATE_CURRENT_SIDE_CONTENT_TEMPLATE_ID',
    currentSideContentTemplateId,
  };
};

export const updateCalendarSingleUserView: ActionCreator<UpdateCalendarSingleUserViewAction> = (value: boolean) => {
  return {
    type: 'UPDATE_CALENDAR_SINGLE_USER_VIEW',
    singleUserView: value,
  };
};

export const updateSelectedStartTask: ActionCreator<UpdateSelectedStartTaskAction> = (selectedStartTask: string) => {
  return {
    type: 'UPDATE_SELECTED_START_TASK',
    selectedStartTask: selectedStartTask,
  };
};

export const updateUserPropertiesAction: ActionCreator<UpdateUserPropertiesAction> = (
  properties: Record<string, unknown>,
) => {
  return {
    type: 'UPDATE_USER_PROPERTIES',
    properties: properties,
  };
};

export function checkStatus(response: { status: number; statusText?: string }): any {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new ResponseError(response.statusText);
    error.response = response;
    throw error;
  }
}

export function onFetchRejected(message: string): (error: Error) => void {
  return (error: Error): void => {
    console.error(message, error);
    notifyError(error);
    alert(message);
  };
}

async function fetchOrganization(api: ApiClient, id: number): Promise<CompleteOrganizationViewModel> {
  return await api.organization.fetchOrganizationWithEntities({
    organizationId: id.toString(),
  });
}

async function fetchOrganizationEventOccurrences(api: ApiClient, id: number): Promise<Array<EventOccurrenceViewModel>> {
  return await api.eventOccurrences.listEventOccurrences({
    organizationId: id.toString(),
  });
}

async function fetchOrganizationMeetingOccurrences(
  api: ApiClient,
  id: number,
): Promise<Array<MeetingOccurrenceViewModel>> {
  return await api.meetingOccurrences.listMeetingOccurrences({
    organizationId: id.toString(),
  });
}
async function fetchOrganizationTopics(api: ApiClient, id: number): Promise<Array<TopicViewModel>> {
  return await api.topics.listTopics({
    organizationId: id.toString(),
  });
}

async function fetchEntity(api: ApiClient, path: string): Promise<any> {
  const p = path.split('/');
  console.log(p);

  const response = await fetch(getBasePath() + path, {
    headers: await withAuthorization(api, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': __APP_VERSION__,
    }),
  });
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  } else if (response.status === 404) {
    return undefined;
  } else {
    const error = new ResponseError(response.statusText);
    error.response = response;
    throw error;
  }
}

export async function fetchPageGroups(api: ApiClient, id: number): Promise<PageGroupViewModel[]> {
  return await api.organization.listPageGroups({
    organizationId: '' + id,
  });
}

async function fetchReferences(api: ApiClient, id: number): Promise<Array<ReferenceViewModel>> {
  return await api.organization.listReferences({
    organizationId: '' + id,
  });
}

export const loadedOrganization: ActionCreator<LoadedOrganizationAction> = (
  id: number,
  organization: CompleteOrganizationViewModel,
  user: User,
) => {
  const username = user.username;
  const key = user.key;

  return {
    type: 'LOADED_ORGANIZATION',
    id: id,
    organization: organization,
    username: username,
    key: key,
    user: user,
  };
};

export const loadedCachedOrganization: ActionCreator<LoadedCachedOrganizationAction> = (
  id: number,
  organization: OrganizationState,
  user: {
    key: string;
    username: string;
    token: string;
  },
) => {
  const token = user.token;
  const username = user.username;
  const key = user.key;

  return {
    type: 'LOADED_CACHED_ORGANIZATION',
    id: id,
    organization: organization,
    token: token,
    username: username,
    key: key,
    user: user,
  };
};

export const loadedError: ActionCreator<LoadedErrorAction> = (error: Record<string, unknown>) => {
  console.error(error);
  return {
    type: 'LOADED_ERROR',
    error: error.toString(),
  };
};

export const loadingOrganization: ActionCreator<LoadingOrganizationAction> = () => {
  return {
    type: 'LOADING_ORGANIZATION',
  };
};

export const loadedPageGroups: ActionCreator<LoadedPageGroupsAction> = (pages: PageGroupViewModel[]) => {
  return {
    type: 'LOADED_PAGE_GROUPS',
    pageGroups: pages,
  };
};
export const setToday: ActionCreator<SetTodayAction> = (today: LocalDate) => {
  return {
    type: 'SET_TODAY',
    today: today.toString(),
  };
};

export const loadedReferences: ActionCreator<LoadedReferencesAction> = (references: ReferenceViewModel[]) => {
  return {
    type: 'LOADED_REFERENCES',
    references: references,
  };
};

export const loadedEventOccurrences: ActionCreator<LoadedEventOccurrencesAction> = (
  eventOccurrences: EventOccurrenceViewModel[],
) => {
  return {
    type: 'LOADED_EVENT_OCCURRENCES',
    eventOccurrences: eventOccurrences,
  };
};

export const loadedMeetingOccurrences: ActionCreator<LoadedMeetingOccurrencesAction> = (
  meetingOccurrences: MeetingOccurrenceViewModel[],
) => {
  return {
    type: 'LOADED_MEETING_OCCURRENCES',
    meetingOccurrences: meetingOccurrences,
  };
};

export const loadedTopics: ActionCreator<LoadedTopicsAction> = (topics: TopicViewModel[]) => {
  return {
    type: 'LOADED_TOPICS',
    topics: topics,
  };
};

export function loadOrganization(
  id: number,
  user: {
    username: string;
    organizations: OrganizationReference[];
  },
  api: ApiClient,
): ThunkResult<Promise<void>> {
  // Invert control!
  // Return a function that accepts `dispatch` so we can dispatch later.
  // Thunk middleware knows how to turn thunk async actions into actions.

  return async (dispatch, getState) => {
    console.log('start loading organization');
    const state = getState();
    const userOrganization = state.user?.organizations.find((o) => o.id === id);
    if (userOrganization === undefined) {
      const error = new Error('Illegal state (E701), no user organization');
      dispatch(loadedError(error));
    } else if (userOrganization.pending) {
      console.log('Organization pending ' + id);
    } else if (userOrganization.requiresSecureLogin && state.user?.provider === '') {
      console.log('Organization secured ' + id);
    } else if (state.loading) {
      console.log('Organization loading ' + id);
    } else {
      dispatch(loadingOrganization());
      console.log('Loading pages key ' + user.username + '-pages-' + id);
      const p = await cache.get(user.username + '-page-groups-' + id);
      if (p !== null) {
        console.log('cache pages not null');
        dispatch(loadedPageGroups(p));
      }

      console.log('Loading references key ' + user.username + '-references-' + id);
      const r = await cache.get(user.username + '-references-' + id);
      if (r !== null) {
        console.log('cache references not null');
        dispatch(loadedReferences(r));
      }

      const key = user.username + '-organization-' + id;
      console.log('Loading organization key ' + key);
      const o = await cache.get(key);
      if (o !== null) {
        console.log('cache organization not null');
        dispatch(loadedCachedOrganization(id, o, user));
      }

      const pageGroups = await fetchPageGroups(api, id);
      dispatch(loadedPageGroups(pageGroups));

      const references = await fetchReferences(api, id);
      dispatch(loadedReferences(references));

      fetchOrganization(api, id).then(
        (organization) => {
          dispatch(loadedOrganization(id, organization, user));
          fetchOrganizationEventOccurrences(api, id).then((eventOccurrences) => {
            dispatch(loadedEventOccurrences(eventOccurrences));
          });

          fetchOrganizationMeetingOccurrences(api, id).then((meetingOccurrences) => {
            dispatch(loadedMeetingOccurrences(meetingOccurrences));
          });

          fetchOrganizationTopics(api, id).then((topics) => {
            dispatch(loadedTopics(topics));
          });
        },
        (error) => {
          console.error(error);
          return dispatch(loadedError(error));
        },
      );
    }
  };
}

interface MessageType {
  path: string;
  eventType: string;
  item?: unknown;
  hidden?: boolean;
}

export function updateItem(api: ApiClient, message: MessageType): ThunkResult<Promise<UpdateItemAction | void>> {
  // Invert control!
  // Return a function that accepts `dispatch` so we can dispatch later.
  // Thunk middleware knows how to turn thunk async actions into actions.
  return (dispatch): Promise<UpdateItemAction | void> => {
    // Special handling if organization is updated:
    if (message.path.endsWith('/archive')) {
      console.log(message.path);
    }

    if (message.path.endsWith('/archive')) {
      return Promise.resolve();
    } else if (message.eventType === 'delete') {
      dispatch(deleteItemAction(message));
    } else if (message.hidden) {
      dispatch(updateItemAction(message));
    } else if (message.item) {
      dispatch(updateItemAction(message));
      setTimeout(() => {
        fetchEntity(api, message.path).then(function (entity) {
          const m = Object.assign({}, message, { item: entity });

          return dispatch(updateItemAction(m));
        });
      }, 600);
    } else {
      return fetchEntity(api, message.path).then(function (entity) {
        const m = Object.assign({}, message, { item: entity });

        return dispatch(updateItemAction(m));
      });
    }
    return Promise.resolve();
  };
}

export const taskDone =
  (api: ApiClient, taskUuid: string): ThunkResult<Promise<Response | void>> =>
  async (dispatch, getState): Promise<Response | void> => {
    const state = getState();
    const id = state.id;
    if (id !== null) {
      const org = state.organization;

      if (org === undefined) {
        throw new Error('Illegal state (E702), organization not found');
      }
      const functionUuid = org.tasksById[taskUuid].functionUuid;
      return await api.functions.markTaskDone({
        organizationId: id.toString(),
        functionId: functionUuid,
        taskId: taskUuid,
      });
    }
  };

async function withAuthorization(api: ApiClient, headers: Record<string, string>): Promise<Record<string, string>> {
  const config = api.config;
  if (config.username !== undefined || config.password !== undefined) {
    headers['Authorization'] = 'Basic ' + Buffer.from(config.username + ':' + config.password).toString('base64');
  }
  if (config.accessToken) {
    const token = config.accessToken;
    const tokenString = await token('UserSecurityWithToken', []);

    if (tokenString) {
      headers['Authorization'] = `Bearer ${tokenString}`;
    }
  }
  return headers;
}

export const declineTaskTemplateUpdate =
  (api: ApiClient, taskUuid: string): ThunkResult<void> =>
  async (dispatch, getState): Promise<Response | void> => {
    const state = getState();
    const id = state.id;
    const organization = getOrganization(state);

    if (organization === undefined) {
      throw new Error('Illegal state (E409), expected organization');
    }
    const task = organization.tasksById[taskUuid];
    const functionUuid = task.functionUuid;

    // return await api.functions.
    return fetch(
      BASE_PATH + '/organizations/' + id + '/functions/' + functionUuid + '/tasks/' + taskUuid + '/declineTemplate',
      {
        headers: await withAuthorization(api, {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Requested-With': __APP_VERSION__,
        }),
        method: 'POST',
        body: '',
      },
    ).catch(onFetchRejected('Der var et problem ved lagring. Vennligst prøv igjen.'));
  };

export const declineTemplateUpdate =
  (api: ApiClient, entityType: string, entityUuid: string): ThunkResult<void> =>
  async (dispatch, getState): Promise<Response | void> => {
    if (entityType === 'tasks') {
      // Ignore tasks. Decline using specific function 'declineTaskTemplateUpdate' only
      return Promise.resolve();
    }

    const state = getState();
    const id = state.id;

    return fetch(BASE_PATH + '/organizations/' + id + '/' + entityType + '/' + entityUuid + '/declineTemplate', {
      headers: await withAuthorization(api, {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': __APP_VERSION__,
      }),
      method: 'POST',
      body: '',
    }).catch(onFetchRejected('Der var et problem ved lagring. Vennligst prøv igjen.'));
  };

export function urlForItem(entityType: string, item: { uuid: string }): string {
  const mapPrefix: {
    tasks: (i: any) => string;
    contacts: (i: any) => string;
    [key: string]: (i: any) => string;
  } = {
    tasks: function (i: any) {
      return '/functions/' + i.functionUuid;
    },
    contacts: function (i: any) {
      return '/partners/' + i.partnerUuid;
    },
  };

  const defaultPrefix = (): string => '';

  const f = mapPrefix[entityType] || defaultPrefix;

  return f(item) + '/' + entityType + '/' + item.uuid;
}

export const saveItem =
  (api: ApiClient, type: string, id: string, item: any, parentId = ''): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    console.log('saveItem', type, id, item, parentId);

    const state = getState();
    const organizationId = state.id;

    const organization = state.organization;
    if (organization === undefined) {
      throw new Error('Illegal state (E703), organization not found');
    }
    let originalItem = organization[type + 'ById'][id];
    let url: string;
    if (originalItem) {
      url = urlForItem(type, originalItem);
    } else if (type === 'tasks') {
      url = '/functions/' + item.functionUuid + '/tasks/' + id;
    } else {
      url = '/' + type + '/' + id;

      const m: {
        tasks: { [p: string]: any };
        contacts: { [p: string]: any };
        [key: string]: { [p: string]: any };
      } = {
        tasks: organization.functionsById,
        contacts: organization.partnersById,
      };

      const parentItem = m[type] && m[type][parentId];

      if (parentItem) {
        const p: {
          tasks: string;
          contacts: string;
          [key: string]: string;
        } = {
          tasks: 'functions',
          contacts: 'partners',
        };

        url = urlForItem(p[type], parentItem) + url;

        const defaults: {
          tasks: { function: string };
          contacts: Record<string, unknown>;
          [key: string]: Record<string, unknown>;
        } = {
          tasks: {
            function: item.functionUuid,
          },
          contacts: {},
        };

        originalItem = defaults[type];
      }
    }

    const updatedItem = Object.assign({}, originalItem, item, {
      uuid: id,
      saving: true,
    });

    const message = {
      path: '/organizations/' + organizationId + url,
      eventType: 'update',
      item: updatedItem,
    };

    dispatch({
      type: 'UPDATE_ITEM',
      message: message,
    });

    if (type === 'computers' && item.unitType === 'printer') {
      item = {
        unitType: item.unitType,
        type: 'UNDEFINED',
        name: item.name,
        networkUuid: item.networkUuid,
        serviceProvider: '',
        contracts: [],
        systemUpdateOperatorType: 'UNDEFINED',
        systemUpdateOperator: '',
        antiVirusOperatorType: 'UNDEFINED',
        antiVirusOperator: '',
        antiVirusRequirements: [],
        connectionType: 'OTHER',
        location: item.location,
        elevated: false,
        locked: false,
        restrictedPhysicalAccess: false,
        displayPositionSecure: false,
        mobileMedicalDataRequirements: [],
        notes: item.notes,
        printerPositionSecure: item.printerPositionSecure,
        isConfirmedEntity: item.isConfirmedEntity,
      };
    }

    return fetch(BASE_PATH + '/organizations/' + organizationId + url, {
      headers: await withAuthorization(api, {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': __APP_VERSION__,
      }),
      method: 'PUT',
      body: JSON.stringify(item),
    })
      .then(function (res) {
        console.log(res);
        setTimeout(function () {
          const s = getState();
          const organization1 = s.organization;
          if (organization1 === undefined) {
            return;
          }
          const o = organization1[type + 'ById'][id];
          if (o) {
            console.log('After saving ' + type + ' ' + id + ': ' + o.saving);
            if (o.saving) {
              // Trigger GET item if message not received
              dispatch(
                updateItem(api, {
                  path: '/organizations/' + organizationId + url,
                  eventType: 'update',
                }),
              );
            }
          }
        }, 500);
      })
      .catch(onFetchRejected('Der var et problem ved lagring. Vennligst prøv igjen.'));
  };

export const createRiskAssessment = (
  api: ApiClient,
  uuid: string,
  name: string,
  organizationId: string,
): Promise<void> => {
  const p: CreateOrUpdateRiskAssessmentRequest = {
    organizationId: organizationId,
    riskAssessmentId: uuid,
    riskAssessmentUpdateMessage: {
      description: '',
      responsibleBy: '',
      probability: 0,
      consequence: 0,
      riskDescription: '',
      measures: '',
      assessmentDate: '',
      violatesInformationSecurityRequirements: false,
      violatesIntegrityRequirement: false,
      violatesConfidentialityRequirement: false,
      violatesNonRepudiationRequirement: false,
      violatesAvailabilityRequirement: false,
      determinedBy: '',
      name: name,
      pages: [pageIds.riskAssessments, pageIds.informationSecurity],
      isConfirmedEntity: true,
    },
  };
  return api.riskAssessments.createOrUpdateRiskAssessment(p);
};

export const createContract = (api: ApiClient, uuid: string, name: string, organizationId: string): Promise<void> => {
  const p: CreateOrUpdateContractRequest = {
    organizationId: organizationId,
    contractId: uuid,
    contractUpdateMessage: {
      status: '',
      profession: '',
      sideCode: '',
      validFromDate: '',
      templateId: '',
      content: '',
      templateEmployeeId: '',
      category: '',
      partners: [],
      name: name,
      validToDate: '',
      documentLocation: '',
      affiliation: '',
      required: false,
      employees: [],
      accessControl: [],
      classification: 'NONE',
      pages: [pageIds.informationSecurity],
      isConfirmedEntity: true,
    },
  };

  return api.contracts.createOrUpdateContract(p);
};

export const createPartner = (api: ApiClient, uuid: string, name: string, organizationId: string): Promise<void> => {
  const p: CreateOrUpdatePartnerRequest = {
    organizationId: organizationId,
    partnerId: uuid,
    partnerUpdateMessage: {
      remoteAccess: false,
      phone: '',
      address: '',
      name: name,
      physicalAccess: false,
      pageAccess: false,
      industry: '',
      notes: '',
      pages: [pageIds.informationSecurity],
      email: '',
      url: '',
      isConfirmedEntity: true,
    },
  };
  return api.partners.createOrUpdatePartner(p);
};

export const createAsset = (
  api: ApiClient,
  uuid: string,
  name: string,
  number: string,
  organizationId: string,
): Promise<void> => {
  const p: CreateOrUpdateAssetRequest = {
    organizationId: organizationId,
    assetId: uuid,
    assetsUpdateMessage: {
      model: '',
      location: '',
      maintenanceRequired: false,
      number: number,
      competency: '',
      userManual: '',
      storesSensitiveInformation: false,
      accessory: '',
      manufacturer: '',
      name: name,
      supplier: '',
      assetVerified: false,
      discardedYear: '',
      documentationVerified: false,
      requiresElectricity: false,
      acquiredYear: '',
      owner: '',
      maintenanceInterval: '',
      documented: false,
      category: 'Ingen',
      maintenance: '',
      complianceDeclaration: '',
      isConfirmedEntity: true,
      radiationType: '',
      radiation: false,
      dsaApproved: false,
      dsaRegistered: false,
    },
  };

  return api.assets.createOrUpdateAsset(p);
};

/**
 * Gets the first name, technically gets all words leading up to the last
 * Example: "Blake Robertson" --> "Blake"
 * Example: "Blake Andrew Robertson" --> "Blake Andrew"
 * Example: "Blake" --> "Blake"
 * @param str
 * @returns {*}
 */
const getFirstName = function (str: string): string {
  const arr = str.split(' ');
  if (arr.length === 1) {
    return arr[0];
  }
  return arr.slice(0, -1).join(' '); // returns "Paul Steve"
};

/**
 * Gets the last name (e.g. the last word in the supplied string)
 * Example: "Blake Robertson" --> "Robertson"
 * Example: "Blake Andrew Robertson" --> "Robertson"
 * Example: "Blake" --> "<None>"
 * @param str
 * @param {string} [ifNone] optional default value if there is not last name, defaults to "<None>"
 * @returns {string}
 */
const getLastName = function (str: string, ifNone: string): string {
  const arr = str.split(' ');
  if (arr.length === 1) {
    return ifNone || '<None>';
  }
  return arr.slice(-1).join(' ');
};

export const createEmployee = (api: ApiClient, uuid: string, name: string, organizationId: string): Promise<void> => {
  const p: CreateOrUpdateEmployeeRequest = {
    organizationId: organizationId,
    employeeId: uuid,
    employeeUpdateMessage: {
      lastName: getLastName(name, ''),
      phone: '',
      associationType: '',
      profession: '',
      accessLevel: 'NONE',
      secondaryPhone: '',
      hprNumber: '',
      address: '',
      email: '',
      herNumber: '',
      notes: '',
      firstName: getFirstName(name),
      expertise: '',
      status: 'ACTIVE',
      gender: EmployeeUpdateMessageGenderEnum.Undefined,
      isConfirmedEntity: true,
      nextOfKin: '',
    },
  };

  return api.employees.createOrUpdateEmployee(p);
};

export const deleteItem =
  (api: ApiClient, entityType: string, uuid: string): ThunkResult<void> =>
  async (dispatch, getState): Promise<Response | void> => {
    const state = getState();
    const id = state.id;
    if (id !== null) {
      const organization1 = state.organization;
      if (organization1 === undefined) {
        throw new Error('Illegal state (E704), organization not found');
      }
      const uuidNoInstance = uuid.split(':')[0];
      const item = organization1[entityType + 'ById'][uuidNoInstance];

      const newItem = { ...item, deleted: true };
      const url = urlForItem(entityType, item);

      dispatch({
        type: 'UPDATE_ITEM',
        message: {
          path: '/organizations/' + id + url,
          eventType: 'update',
          item: newItem,
        },
      });

      switch (entityType) {
        case 'assets':
          return await api.assets.deleteAsset({ organizationId: id.toString(), assetId: uuid });
        case 'constitutionalDocuments':
          return await api.constitutionalDocuments.deleteConstitutionalDocument({
            organizationId: id.toString(),
            constitutionalDocumentId: uuid,
          });
        case 'contacts':
          return await api.partners.deletePartnerContact({
            organizationId: id.toString(),
            partnerId: item.partnerUuid,
            contactId: uuid,
          });
        case 'contracts':
          return await api.contracts.deleteContract({ organizationId: id.toString(), contractId: uuid });
        case 'documents':
          return await api.documents.deleteDocument({ organizationId: id.toString(), documentId: uuid });
        case 'employees':
          return await api.employees.deleteEmployee({ organizationId: id.toString(), employeeId: uuid });
        case 'functions':
          return await api.functions.deleteFunction({ organizationId: id.toString(), functionId: uuid });
        case 'guidelines':
          return await api.guidelines.deleteGuideline({ organizationId: id.toString(), guidelineId: uuid });
        case 'issues':
          return await api.issues.deleteIssue({ organizationId: id.toString(), issueId: uuid });
        case 'partners':
          return await api.partners.deletePartner({ organizationId: id.toString(), partnerId: uuid });
        case 'reports':
          return await api.reports.deleteReport({ organizationId: id.toString(), reportId: uuid });
        case 'riskAssessments':
          return await api.riskAssessments.deleteRiskAssessment({
            organizationId: id.toString(),
            riskAssessmentId: uuid,
          });
        case 'substances':
          return await api.substances.deleteSubstance({ organizationId: id.toString(), substanceId: uuid });
        case 'tasks':
          return await api.functions.deleteFunctionTask({
            organizationId: id.toString(),
            functionId: item.functionUuid,
            taskId: uuid,
          });
        case 'computers':
          return await api.computers.deleteComputer({ organizationId: id.toString(), computerId: uuid });
        case 'networks':
          return await api.computers.deleteNetwork({ organizationId: id.toString(), networkId: uuid });
        case 'externalConnections':
          return await api.computers.deleteExternalConnection({
            organizationId: id.toString(),
            externalConnectionId: uuid,
          });
      }
    }
  };

function uploadEndpoint(uuid: string, organization1: OrganizationState, entityType: string, id: number, file: File) {
  const { url, endpoint } = attachmentEndpoint(uuid, organization1, entityType, id);
  return { url, endpoint: endpoint + '/' + encodeURIComponent(file.name) };
}

function attachmentEndpoint(uuid: string, organization1: OrganizationState, entityType: string, id: number) {
  if (entityType === 'eventOccurrences' || entityType === 'meetingOccurrences') {
    const url = '/' + entityType + '/' + uuid;

    const endpoint = BASE_PATH + '/organizations/' + id + url + '/attachments';
    return { url, endpoint };
  } else {
    const uuidNoInstance = uuid.split(':')[0];
    const item = organization1[entityType + 'ById'][uuidNoInstance];

    const url = urlForItem(entityType, item);

    const endpoint = BASE_PATH + '/organizations/' + id + url + '/attachments';
    return { url, endpoint };
  }
}

export const uploadFile =
  (api: ApiClient, entityType: string, uuid: string, file: File): ThunkResult<void> =>
  async (dispatch, getState): Promise<Response | void> => {
    const state = getState();
    const id = state.id;
    if (id === null) {
      throw new Error('Illegal state (E706), id not found');
    }

    const organization1 = state.organization;
    if (organization1 === undefined) {
      throw new Error('Illegal state (E705), organization not found');
    }
    const { url, endpoint } = uploadEndpoint(uuid, organization1, entityType, id, file);

    return fetch(endpoint, {
      method: 'PUT',
      headers: await withAuthorization(api, {
        'X-Requested-With': __APP_VERSION__,
        'Content-Type': 'application/octet-stream',
      }),
      body: file,
    })
      .then(checkStatus)
      .then(() => {
        dispatch(
          updateItem(api, {
            path: '/organizations/' + id + url,
            eventType: 'update',
          }),
        );
      })
      .catch(onFetchRejected('Feil ved opplasting. Prøv igjen.'));
  };

export const renameAttachment =
  (api: ApiClient, entityType: string, uuid: string, attachmentId: string, name: string): ThunkResult<void> =>
  async (dispatch, getState): Promise<Response | void> => {
    const state = getState();
    const id = state.id;
    if (id === null) {
      throw new Error('Illegal state (E706), id not found');
    }

    const organization1 = state.organization;
    if (organization1 === undefined) {
      throw new Error('Illegal state (E706), organization not found');
    }

    const e = attachmentEndpoint(uuid, organization1, entityType, id);

    const endpoint = e.endpoint + '/' + attachmentId + '/properties';

    const p = {
      name: name,
    };
    return fetch(endpoint, {
      method: 'PUT',
      headers: await withAuthorization(api, {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': __APP_VERSION__,
      }),
      body: JSON.stringify(p),
    })
      .then(checkStatus)
      .then(() => {
        dispatch(
          updateItem(api, {
            path: '/organizations/' + id + e.url,
            eventType: 'update',
          }),
        );
      })
      .catch(onFetchRejected('Feil ved endring av vedlegg. Prøv igjen.'));
  };

export const deleteAttachment =
  (api: ApiClient, entityType: string, uuid: string, attachmentId: string): ThunkResult<void> =>
  async (dispatch, getState): Promise<Response | void> => {
    const state = getState();
    const id = state.id;
    if (id === null) {
      throw new Error('Illegal state (E706), id not found');
    }

    const organization1 = state.organization;
    if (organization1 === undefined) {
      throw new Error('Illegal state (E707), organization not found');
    }

    const e = attachmentEndpoint(uuid, organization1, entityType, id);

    const endpoint = e.endpoint + '/' + attachmentId;

    return fetch(endpoint, {
      method: 'DELETE',
      headers: await withAuthorization(api, {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': __APP_VERSION__,
      }),
    })
      .then(checkStatus)
      .then(() => {
        dispatch(
          updateItem(api, {
            path: '/organizations/' + id + e.url,
            eventType: 'update',
          }),
        );
      })
      .catch(onFetchRejected('Feil ved sletting av vedlegg. Prøv igjen.'));
  };

export const updateOrganization =
  (api: ApiClient, item: any): ThunkResult<void> =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();
    const id = state.id;

    fetch(BASE_PATH + '/organizations/' + id, {
      headers: await withAuthorization(api, {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': __APP_VERSION__,
      }),
      method: 'PUT',
      body: JSON.stringify(item),
    })
      .then((res) => {
        console.log(res);
      })
      .then(() => {
        dispatch(
          updateItem(api, {
            path: '/organizations/' + id,
            eventType: 'update',
          }),
        );
      })
      .catch(onFetchRejected('Der var et problem ved lagring. Vennligst prøv igjen.'));
  };

/**
 * Updates the additional properties of the current user.
 *
 * User properties are used for properties that are relevant only to the current user. This includes per organization
 * properties. To set
 *
 * @param p Object with keys for each additional property to set. Other additional
 *        properties are not affected. Set value to null for a key to delete the key.
 * @returns {Function}
 */
export const updateUserProperties = (api: ApiClient, p: Record<string, unknown>): ThunkResult<Promise<void>> => {
  // Invert control!
  // Return a function that accepts `dispatch` so we can dispatch later.
  // Thunk middleware knows how to turn thunk async actions into actions.

  return async (dispatch, getState): Promise<void> => {
    const state = getState();

    if (!state.organization) {
      return new Promise<void>((resolve) => resolve());
    }

    dispatch(updateUserPropertiesAction(p));

    return fetch(BASE_PATH + '/accounts/properties', {
      headers: await withAuthorization(api, {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': __APP_VERSION__,
      }),
      method: 'PUT',
      body: JSON.stringify(p),
    })
      .then(function (res) {
        console.log(res);
      })
      .catch(onFetchRejected('Kan ikke oppdatere.'));
  };
};

/**
 * Updates the additional properties of the current user.
 *
 * User properties are used for properties that are relevant only to the current user. This includes per organization
 * properties. To set
 *
 * @param p Object with keys for each additional property to set. Other additional
 *        properties are not affected. Set value to null for a key to delete the key.
 * @param organizationId
 * @returns {Function}
 */
export const updateUserPropertiesForOrganization = (
  api: ApiClient,
  p: { [key: string]: any },
  organizationId: number,
): ThunkResult<Promise<void>> => {
  const prefixKeys = (m: { [key: string]: any }, prefix: string): { [key: string]: any } => {
    const keys = Object.keys(m);
    const result: { [key: string]: any } = {};

    keys.forEach(function (k) {
      const key = prefix + k;
      result[key] = m[k];
    });

    return result;
  };

  const prefixedProperties = prefixKeys(p, organizationId + '_');

  return updateUserProperties(api, prefixedProperties);
};

/**
 * Send a message to employees of an organization.
 *
 * @param p The send message command object.
 * @returns {Function}
 */
export const sendMessage = (api: ApiClient, p: SendMessageCommand): ThunkResult<Promise<void>> => {
  return sendOrganizationMessage(api, JSON.stringify(p), ':send-message');
};

export const saveTask =
  (api: ApiClient, id: string, item: FunctionTaskUpdateMessage): ThunkResult<void> =>
  async (dispatch, getState): Promise<void> => {
    console.log('saveTask', id, item);

    const state = getState();
    const organizationId = state.id;
    const organization1 = state.organization;
    if (organization1 === undefined) {
      throw new Error('Illegal state (E708), organization not found');
    }

    const originalItem = organization1.tasksById[id];
    const url: string = '/functions/' + item.functionUuid + '/tasks/' + id;

    const updatedItem = Object.assign({}, originalItem, item, {
      uuid: id,
      saving: true,
    });

    const message = {
      path: '/organizations/' + organizationId + url,
      eventType: 'update',
      item: updatedItem,
    };

    dispatch({
      type: 'UPDATE_ITEM',
      message: message,
    });

    return fetch(BASE_PATH + '/organizations/' + organizationId + url, {
      headers: await withAuthorization(api, {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': __APP_VERSION__,
      }),
      method: 'PUT',
      body: JSON.stringify(item),
    })
      .then(function (res) {
        console.log(res);
      })
      .catch(onFetchRejected('Der var et problem ved lagring. Vennligst prøv igjen.'));
  };

/**
 * Send a message to employees of an organization.
 *
 * @param p The send message command object.
 * @returns {Function}
 */
export const sendShareVacation = (api: ApiClient, p: SendShareVacationCommand): ThunkResult<Promise<void>> => {
  return sendOrganizationMessage(api, JSON.stringify(p), ':send-share-vacation');
};

function sendOrganizationMessage(api: ApiClient, body: string, target: string): ThunkResult<Promise<void>> {
  // Invert control!
  // Return a function that accepts `dispatch` so we can dispatch later.
  // Thunk middleware knows how to turn thunk async actions into actions.

  return async (dispatch, getState): Promise<void> => {
    const state = getState();

    if (!state.organization) {
      return new Promise<void>((resolve) => resolve());
    }

    const id = state.id;

    return fetch(BASE_PATH + '/organizations/' + id + '/' + target, {
      headers: await withAuthorization(api, {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': __APP_VERSION__,
      }),
      method: 'POST',
      body: body,
    })
      .then(function (res) {
        console.log(res);
      })
      .catch(onFetchRejected('Kan ikke sende melding.'));
  };
}

/**
 * Send a message to employees of an organization.
 *
 * @param p The send message command object.
 * @returns {Function}
 */
export const stopShareVacation = (api: ApiClient, p: StopShareVacationCommand): ThunkResult<Promise<void>> => {
  return sendOrganizationMessage(api, JSON.stringify(p), ':stop-share-vacation');
};

export const loginUserAction = (
  api: ApiClient,
  username: string,
): ThunkAction<Promise<boolean>, State, unknown, DAction> => {
  // Invert control!
  // Return a function that accepts `dispatch` so we can dispatch later.
  // Thunk middleware knows how to turn thunk async actions into actions.

  return async (dispatch): Promise<boolean> => {
    try {
      console.log('Logging in');
      const response = await api.accounts.listOrganizations({ accountEmail: username });
      console.log('Logging in response', response);
      const organizations = response.organizations;

      Bugsnag.setUser(undefined, username);

      identify(username);

      try {
        localStorage.setItem('dabih-username', username);
        localStorage.setItem('dabih-issued', new Date().toISOString());
      } catch (e: any) {
        console.error(e);
        Bugsnag.notify(e);
      }

      const user = {
        ...response,
        username: username,
        organizations: organizations,
        key: response.key,
        ablyTokenRequest: response.ablyTokenRequest,
      };

      console.log(user);

      dispatch(updateUserAction(user));

      // .catch((e) => console.error(e));

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };
};

export function updateDataItemAdditionalProperties(
  api: ApiClient,
  entityType: string,
  uuid: string,
  properties: Record<string, unknown>,
): ThunkResult<Promise<void>> {
  return async (dispatch, getState): Promise<void> => {
    const state = getState();

    if (!state.organization) {
      return Promise.resolve();
    }

    const id = state.id;
    const originalItem = state.organization[entityType + 'ById'][uuid];
    const updatedItem = Object.assign({}, originalItem, properties, {
      saving: true,
    });
    const message = {
      path: '/organizations/' + id + '/' + entityType + '/' + uuid,
      eventType: 'update',
      item: updatedItem,
    };

    dispatch({
      type: 'UPDATE_ITEM',
      message: message,
    });

    return fetch(BASE_PATH + '/organizations/' + id + '/' + entityType + '/' + uuid + '/properties', {
      headers: await withAuthorization(api, {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': __APP_VERSION__,
      }),
      method: 'PUT',
      body: JSON.stringify(properties),
    })
      .then(function (res) {
        console.log(res);
        setTimeout(function () {
          const s = getState();
          const organization = s.organization;
          if (organization !== undefined) {
            const o = organization[entityType + 'ById'][uuid];
            if (o) {
              console.log('After saving properties ' + entityType + ': ' + uuid + ': ' + o.saving);
              if (o.saving) {
                // Trigger GET item if message not received
                dispatch(
                  updateItem(api, {
                    path: '/organizations/' + id + '/' + entityType + '/' + uuid,
                    eventType: 'update',
                  }),
                );
              }
            }
          }
        }, 500);
      })
      .catch(function (res) {
        console.log(res);
      });
  };
}
