import type { State } from '../types.js';
import { _formatDate, toList } from './utilities.js';
import type { IssueViewModel } from '../api';
import { createSelector } from '@reduxjs/toolkit';
import { employeesNotDeleted, functionsNotDeleted, getOrganization, singleFunctionTemplateIds } from 'src/store';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import { isEmptyOrInvalidString } from 'src/utilities/text';
import { assertIsDefined } from 'src/lib';
import { getFeatureStates } from 'src/store/selectors/features';

export function issuesNotDeleted(state: State): IssueViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.issuesById).filter((e) => !e.deleted);
}

export function issuesDeleted(state: State): IssueViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.issuesById).filter((e) => e.deleted);
}

/**
 * Issues registered before new processing conditions are evaluated using previous criteria.
 *
 * @param issue The issue to evaluate
 */
function previousIsIssueProcessed(issue: IssueViewModel): boolean {
  if (issue.evaluatedDate && issue.evaluatedBy) {
    return true;
  }
  if (issue.evaluated) {
    // skal evalueres
    return false;
  }
  if (issue.implementedMeasure) {
    return true;
  }
  if (issue.requiresMeasures) {
    return false;
  }
  return !!(issue.processedDate && issue.processedBy);
}

export function isIssueProcessed(issue: IssueViewModel): boolean {
  if (issue.reportedDate && issue.reportedDate < '2024-08-23') {
    return previousIsIssueProcessed(issue);
  }

  if (issue.processedDate && issue.processedBy) {
    if (issue.requiresMeasures === false) {
      return true;
    }
    if (
      issue.requiresMeasures === true &&
      !isEmptyOrInvalidString(issue.identifiedMeasures) &&
      issue.implementedMeasure === true
    ) {
      /**
       * I.e. 'needs evaluation'
       */
      if (issue.evaluated === false) {
        return true;
      }
      if (issue.evaluated === true && issue.evaluatedBy && issue.evaluatedDate && issue.evaluatedOk === true) {
        return true;
      }
    }
  }
  return false;
}

export interface IssueViewModelExtended extends IssueViewModel {
  reportedDateFormatted: string;
  status: string;
}

export function toIssueViewModel(item: IssueViewModel): IssueViewModelExtended {
  return Object.assign({}, item, {
    reportedDateFormatted: _formatDate(item.reportedDate?.toString()),
    status: isIssueProcessed(item) ? 'ferdigbehandlet' : 'aktiv',
  });
}

function issuesRecipientsForCore(functions, employees, organization) {
  const result: SelectDropdownOption[] = [];
  const employeeIds: string[] = [];
  const qualityManager = functions.find((f) => {
    return f.mainTemplateId === singleFunctionTemplateIds.qualityManager;
  });
  if (qualityManager) {
    const employee = employees.find((e) => {
      return e.uuid === qualityManager.employees[0];
    });
    if (employee) {
      employeeIds.push(employee.uuid);
    }
  }
  const recipientFunctionIds: number[] = [
    singleFunctionTemplateIds.qualityManager,
    singleFunctionTemplateIds.hseManager,
    singleFunctionTemplateIds.generalManager,
    singleFunctionTemplateIds.hrManager,
    singleFunctionTemplateIds.equipmentManager,
    singleFunctionTemplateIds.ictResponsible,
  ];
  const recipientFunctions = functions.filter((f) => {
    return f.mainTemplateId && recipientFunctionIds.includes(f.mainTemplateId);
  });
  const recipientFunctionEmployeeIds = recipientFunctions.map((f) => {
    return f.employees[0];
  });
  employees.forEach((e) => {
    if (!employeeIds.includes(e.uuid) && recipientFunctionEmployeeIds.includes(e.uuid)) {
      employeeIds.push(e.uuid);
    }
  });
  employeeIds.forEach((id) => {
    const employee = employees.find((e) => {
      return e.uuid === id;
    });
    if (employee) {
      const functionNames: string[] = recipientFunctions
        .filter((f) => {
          return f.employees[0] === id && f.name;
        })
        .map((f) => {
          return f.name ?? '';
        });
      let functionsList = functionNames.join(', ');
      if (functionNames.length > 2) {
        functionsList = functionNames.slice(0, 3).join(', ') + ' ...';
      }
      result.push({ value: id, text: employee.name + ' (' + functionsList + ')' });
    }
  });
  if (result.length === 0) {
    const e = employees.find((e) => e.email?.toLowerCase() === organization?.ownerEmail.toLowerCase());
    assertIsDefined(e);
    result.push({ value: e.uuid, text: e.name });
  }
  return result;
}

export const getRecipientOptionsForIssues = createSelector(
  getOrganization,
  functionsNotDeleted,
  employeesNotDeleted,
  getFeatureStates,
  (organization, functions, employees, featureStates): SelectDropdownOption[] => {
    if (featureStates.core) {
      return issuesRecipientsForCore(functions, employees, organization);
    } else {
      return employees.filter((e) => e.accessLevel === 'USER').map((e) => ({ value: e.uuid, text: e.name }));
    }
  },
);
