import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

/**
 *
 * STATUS OK
 */
@customElement('d-robot-header')
export class DRobotHeader extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      position: sticky;
      top: 0;
      z-index: 1;
      background: var(--themeColorDarkerTwo) url(/images/paper.png) 0 0 repeat;
      background-size: 800px 800px;
      min-height: 100px;
      box-shadow: inset 0 -8px 12px -6px hsl(0deg 0% 0% / 60%);
    }

    :host > div {
      display: flex;
      margin: 0 auto;
      width: 768px;
      padding: 20px;
      max-width: var(--appWidth);
      min-width: 360px;
      box-sizing: border-box;
    }

    #robotHead {
      position: relative;
      width: 80px;
      height: 80px;
      flex: none;
      background: var(--themeColor);
      border-radius: 12px;
      box-shadow: 4px 4px 12px hsla(0, 0%, 0%, 0.6);
    }

    #eyes {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 50px;
      top: 18px;
      left: 15px;
      animation: robotHeaderBlink 8s infinite both;
    }

    .eye {
      position: relative;
      flex: none;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: white;
    }

    .pupil {
      position: absolute;
      bottom: 4px;
      right: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: var(--themeColor);
      animation: robotHeaderPeek 8s infinite both;
    }

    @keyframes robotHeaderBlink {
      56.999% {
        opacity: 1;
      }
      57% {
        opacity: 0;
      }
      59.999% {
        opacity: 0;
      }
      60% {
        opacity: 1;
      }
      96.999% {
        opacity: 1;
      }
      97% {
        opacity: 0;
      }
      99.999% {
        opacity: 0;
      }
    }

    @keyframes robotHeaderPeek {
      59.999% {
        bottom: 4px;
        right: 4px;
      }
      60% {
        bottom: 2px;
        right: 2px;
      }
      99.999% {
        bottom: 2px;
        right: 2px;
      }
    }

    #mouth {
      width: 40px;
      height: 20px;
      position: absolute;
      top: 45px;
      left: 20px;
      background: hsl(0, 0%, 30%);
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      box-shadow: inset 3px 3px 8px hsla(0, 0%, 0%, 0.6);
    }

    #callout {
      box-sizing: border-box;
      flex: 1;
      margin-left: 30px;
      padding: 15px 20px;
      border-radius: 12px;
      background: var(--robot-callout-color, white);
      position: relative;
      min-height: 80px;
      box-shadow: 4px 4px 12px hsla(0, 0%, 0%, 0.6);
    }

    #callout:before {
      content: ' ';
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-right: 20px solid var(--robot-callout-color, white);
      border-bottom: 7px solid transparent;
      display: inline-block;
      position: absolute;
      top: 35px;
      left: -20px;
    }

    #message {
      line-height: 130%;
      font-weight: 400;
    }

    #message a {
      color: var(--themeColorDarkerOne);
    }

    body:not(.touch) d-robot-header #message a:hover {
      color: black;
    }
  `;
  @property({ type: String })
  message = '';

  render() {
    return html`
      <div>
        <div id="robotHead">
          <div id="eyes">
            <div class="eye">
              <div class="pupil"></div>
            </div>
            <div class="eye">
              <div class="pupil"></div>
            </div>
          </div>
          <div id="mouth"></div>
        </div>
        <div id="callout">
          <div id="message">${unsafeHTML(this.message)}</div>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-robot-header': DRobotHeader;
  }
}
