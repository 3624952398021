import _ from 'lodash';
import { FunctionTask, FunctionView, FunctionViewEditItem } from 'src/content/functions/d-function-view.js';
import {
  fetchDraft,
  fetchRevisions,
  pagesAsSelectTagOptions,
  toAttachmentItems,
} from 'src/models/factory-utilities.js';
import { BASE_PATH, FunctionViewModel, TaskViewModel } from 'src/store/api';
import {
  employeesNotDeleted,
  getOrganization,
  key,
  singleFunctionTemplateIds,
  tasksNotDeleted,
  toFunctionViewModel,
  writeAccess,
} from 'src/store/selectors';
import type { OrganizationState, State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { assertIsDefined } from 'src/lib';
import { entityNameForLists } from 'src/utilities/text';
import { CommonDataEntityView } from 'src/content/entity-content';
import { ApiClient } from 'src/store';
import type { ListSectionItemInput } from 'src/library/lists/utilities';
import { calculateUpdateStatus } from 'src/models/resolvers/calculate-update-status';

function functionRotation(f: FunctionViewModel, selectedEmployees: string[], state: State) {
  if (f.type === 'ROTATED' && selectedEmployees.length > 1) {
    return {
      type: f.rotation,
      count: Number(f.rotation.split('-')[1]),
      employees: employeesNotDeleted(state).map((e) => ({
        uuid: e.uuid,
        firstName: e.firstName,
        lastName: e.lastName,
      })),
      rotations: f.rotations.map((r) => {
        return {
          startDate: r.startDate ?? '',
          employee: r.employee ?? '',
          day1: r.day1 + '',
          day2: r.day2 + '',
          day3: r.day3 + '',
          day4: r.day4 + '',
          day5: r.day5 + '',
          day6: r.day6 + '',
          day7: r.day7 + '',
        };
      }),
      selectedEmployees: selectedEmployees,
    };
  } else {
    return undefined;
  }
}

function asListItems(list: FunctionTask[], today: LocalDate): ListSectionItemInput[] {
  return list.map((v) => ({
    href: v.href,
    label: v.name,
    mainIcon: v.locked ? 'lock' : undefined,
    accessible: true,
    updateStatus: calculateUpdateStatus(v, today),
    hasDraft: v.hasDraft,
  }));
}

export async function buildFunctionView(
  api: ApiClient,
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): Promise<FunctionView> {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const availablePages = pagesAsSelectTagOptions(state);
  const item = organization.functionsById[uuid];
  assertIsDefined(item);

  const entity = toFunctionViewModel(item, organization);
  const tasks: TaskViewModel[] = tasksNotDeleted(state);
  const href = currentParent + '/functions/' + uuid;
  const employees = entity.employees ?? [];

  const today = LocalDate.fromString(state.today);

  const assignedTasks = _.sortBy(
    tasks
      .filter(function (item) {
        return item.functionUuid === entity.uuid;
      })
      .filter((t) => !t.locked),
    ['name', 'locked'],
  ).map((e) => toFunctionTask(entity.uuid, e, organization, href));

  const responsibleTasks = _.sortBy(
    tasks.filter(function (item) {
      return item.responsibleFunctionUuid === entity.uuid;
    }),
    ['name', 'locked'],
  ).map((e) => toFunctionTask(entity.uuid, e, organization, href));

  return {
    ...commonData,
    docsForLinking: [],
    contentLastModified: entity.contentLastModified ?? '',
    contentLastModifiedBy: entity.contentLastModifiedBy ?? '',
    contentLastModifiedWasCreate: entity.contentLastModifiedWasCreate ?? false,
    hasTemplateDelete: entity.hasTemplateDelete ?? false,
    hasTemplateUpdate: entity.hasTemplateUpdate ?? false,
    templateDeclined: entity.templateDeclined ?? '',
    templateDeleted: entity.templateDeleted ?? '',
    templateDeletedMessage: entity.templateDeletedMessage ?? '',
    templateUpdated: entity.templateUpdated ?? '',
    currentUserHasAccess: true,
    currentUserHasWriteAccess: writeAccess(state),
    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    isGeneralManager: entity.mainTemplateId === singleFunctionTemplateIds.generalManager,
    helpContent: entity.helpContent ?? '',
    href: href,
    parentHref: currentParent,
    pdfHref:
      BASE_PATH + '/organizations/' + organization.organizationId + '/functions/' + uuid + '.pdf?key=' + key(state),

    deleted: entity.deleted,
    type: 'functions',
    name: entity.name ?? '',
    revisions: await fetchRevisions(
      api,
      'functions',
      uuid,
      organization.organizationId,
      entity.templateDeleted !== undefined && entity.templateDeleted !== '',
    ),
    attachments: toAttachmentItems(api, 'functions', entity.uuid, entity.attachments),
    description: entity.description ?? '',
    functionType: entity.type ?? '',
    assignedEmployeeNames: entity.assignedEmployeeNames ?? '',
    rotation: functionRotation(entity, employees, state),
    assignedTasks: asListItems(assignedTasks, today),
    responsibleTasks: asListItems(responsibleTasks, today),
    availablePages: availablePages,
    isCommon: entity.type === 'COMMON',
    isRotated: entity.type === 'ROTATED',
    availableEmployees: employeesNotDeleted(state)
      .filter((e) => employees.includes(e.uuid) || e.status === 'ACTIVE')
      .map((p) => ({ value: p.uuid, text: p.name })),
    employees: employees,
    responsibilityLocked: entity.responsibilityLocked ?? false,
    pages: entity.pages.map((x) => '' + x),
    today,
    deletable: entity.type !== 'COMMON',
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(api, 'functions', uuid, organization.organizationId);
      return command.draft as FunctionViewEditItem;
    },
  };
}

function _taskLabel(functionUuid: string, task: TaskViewModel, organization: OrganizationState) {
  const name = entityNameForLists(task.name, task.draftName, task.isConfirmedEntity);
  if (functionUuid === task.functionUuid) {
    if (task.responsibleFunctionUuid) {
      if (
        task.responsibleFunctionUuid !== task.uuid &&
        task.responsibleFunctionUuid !== '' &&
        task.responsibleFunctionUuid !== task.functionUuid
      ) {
        const responsibleFunction = organization.functionsById[task.responsibleFunctionUuid];
        if (responsibleFunction) {
          return name + ' (følges opp av ' + responsibleFunction.name + ')';
        }
      }
      return name;
    }
    return name;
  }
  if (!task.responsibleFunctionUuid) {
    return name;
  }
  let executive = organization.functionsById[task.functionUuid].name;
  if (organization.functionsById[task.functionUuid].type === 'COMMON') {
    executive = 'alle';
  }
  return name + ' (utføres av ' + executive + ')';
}

function toFunctionTask(
  uuid: string,
  e: TaskViewModel,
  organization: OrganizationState,
  baseHref: string,
): FunctionTask {
  let href = baseHref + '/tasks/' + e.uuid;
  if (!e.isConfirmedEntity) {
    href += '?edit';
  }
  return {
    name: _taskLabel(uuid, e, organization) ?? '',
    responsibleFunctionUuid: e.responsibleFunctionUuid ?? '',
    functionUuid: e.functionUuid,
    locked: e.locked ?? false,
    uuid: e.uuid,
    templateDeleted: e.templateDeleted ?? '',
    templateDeclined: e.templateDeclined ?? '',
    templateUpdated: e.templateUpdated ?? '',
    hasTemplateUpdate: e.hasTemplateUpdate ?? false,
    contentLastModified: e.contentLastModified ?? '',
    hasDraft: e.hasDraft,
    href,
  };
}
