import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ContentEditDoneDetail, RelateAssetToEventOccurrencesDetail } from 'src/content/d-content.js';

import type { ApplicationViewModel } from 'src/layout/application-view-model.js';
import type { SearchResult } from 'src/layout/parts/d-organization-search.js';
import type { Subscription } from 'src/layout/parts/d-organization-subscription.js';
import type { TutorialParticipant } from 'src/library/lists/d-list-section-tutorial-participants.js';
import type {
  DeleteTimeCorrectionEvent,
  UpdateTimeCorrectionEvent,
} from 'src/library/lists/d-timekeeping-list-section.js';

import type { HelpPageModel } from 'src/outskirts/d-help-viewer.js';
import type { EditPeriodResult } from 'src/pages/edit-periods-dialog.js';
import type { OrganizationEditItem } from 'src/pages/organization-page/d-organization-edit.js';
import type { ShareVacationResult } from 'src/pages/staffing-page/share-staffing-dialog.js';
import type {
  AccountStateViewModel,
  EmployeeViewModelGenderEnum,
  IssueUpdateMessage,
  OrganizationReference,
  WorkSchedule,
} from 'src/store/api';
import { ResponsiveElement } from './library/elements/responsive-container.js';
import './library/fields/d-spinner-robot.js';
import './outskirts/account/d-account.js';
import './outskirts/d-help-viewer.js';
import './outskirts/signin/d-signin.js';
import './outskirts/signup/d-signup';
import type { User } from './store/types.js';
import type { TutorialState } from 'src/outskirts/d-tutorial-viewer.js';
import type { CloudServiceEditItem } from 'src/pages/computers-page/d-computer-unit-cloud-service.js';
import type { RemoteServerEditItem } from 'src/pages/computers-page/d-computer-unit-remote-server.js';
import type { PrinterEditItem } from 'src/pages/computers-page/d-computer-unit-printer.js';
import type { LocalEditItem } from 'src/pages/computers-page/d-computer-unit-local.js';
import type { ExternalConnectionEditItem } from 'src/pages/computers-page/d-external-connection-view.js';
import type { NetworkConnectionEditItem } from 'src/pages/computers-page/d-network-connection-view.js';
import type { NetworkFirewallEditItem } from 'src/pages/computers-page/d-network-firewall-view.js';
import type { NetworkEditItem } from 'src/pages/computers-page/d-network-item-view.js';
import type { MessageForSend } from 'src/content/meeting-occurrences/meeting-message-dialog.js';
import type { EmployeeWithStaffGroup } from 'src/pages/staffing-page/staffing-groups-dialog.js';
import type { InfosecDataItem, InfosecNetwork } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import type { ComputerNetworkChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-computers-network.js';
import type { NetworkExternalConnectionChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-network-external-connections.js';
import type { AccessChange } from 'src/pages/computers-page/infosec-procedure/d-infosec-access.js';
import type { BackupChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-backup.js';
import type { DataEditItem } from 'src/pages/computers-page/d-data-item-view.js';
import type { AddDataItemApplication } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-application.js';
import type { EditException } from 'src/pages/staffing-page/staffing-employee-day-dialog.js';
import type { FileViewerDocument } from 'src/layout/parts/file-viewer-dialog.js';
import type { UpdateUserAccessEvent } from 'src/layout/parts/d-organization-edit-access.js';
import { SlideNotificationContent } from 'src/layout/parts/d-slide-notification.js';
import { until } from 'lit-html/directives/until.js';
import { CreateEntityInput } from 'src/layout/parts/d-new-documents-list';
import { CreateEmployeeInput } from 'src/content/meeting-occurrences/d-meeting-occurrence-view';
import { CreateContactAndPartnerInput } from 'src/layout/parts/d-new-contact-and-partner-dialog';
import type { ZoomUnit } from 'src/pages/staffing-page/d-staffing-calendar-data';
import { CollectionFilter } from 'src/store/selectors/collections';
import { NewOrganization, UserForSignup } from 'src/outskirts/signup/d-signup-form';
import { EventDoneDetail } from 'src/content/event-occurrences/d-event-occurrence-view';
import { UploadedFile } from 'src/pages/substances-page/d-substances-page-content';
import { ContentItem } from 'src/layout/parts/request-signatures-dialog';
import { LocalDate } from 'src/utilities/local-date';
import 'src/layout/parts/d-slide-notification.js';

export interface AppStateSignin {
  page: 'signin';
}

export interface AppStateSignup {
  page: 'signup';
  currentUser?: UserForSignup;
}

export interface AppStateSecured {
  page: 'secured';
  user: User;
  selectedUserOrganization: OrganizationReference;
}

export interface AppStateNoUser {
  page: 'noUser';
}

export interface AppStateLink {
  page: 'link';
  locationSearch: string;
}

export interface AppStateNotFound {
  page: 'notFound';
  requestedPage?: string;
}

export interface AppStateLoading {
  page: 'loading';
}

export interface AppStateAccount {
  page: 'account';
  user: User;
  helpViewerOpen: boolean;
  helpViewerPage: string;
  viewModel?: ApplicationViewModel;
  writeAccess: boolean;
}

export type AppState =
  | AppStateSignin
  | AppStateSignup
  | AppStateAccount
  | AppStateNotFound
  | AppStateLoading
  | AppStateLink
  | AppStateSecured
  | AppStateNoUser;

export interface CloudServiceChanged {
  uuid: string;
  unitType: 'cloudService';
  element: CloudServiceEditItem;
}

export interface RemoteServerChanged {
  uuid: string;
  unitType: 'remoteServer';
  element: RemoteServerEditItem;
}

export interface PrinterChanged {
  uuid: string;
  unitType: 'printer';
  element: PrinterEditItem;
}

export interface ComputerChanged {
  uuid: string;
  unitType: 'computer';
  element: LocalEditItem;
}

export interface ExternalConnectionChanged {
  uuid: string;
  unitType: 'externalConnection';
  element: ExternalConnectionEditItem;
}

export interface NetworkConnectionChanged {
  uuid: string;
  unitType: 'connection';
  element: NetworkConnectionEditItem;
}

export interface NetworkFirewallChanged {
  uuid: string;
  unitType: 'firewall';
  element: NetworkFirewallEditItem;
}

export interface NetworkChanged {
  uuid: string;
  unitType: 'network';
  element: NetworkEditItem;
}

export interface PersonalDataChanged {
  uuid: string;
  unitType: 'personalDataItem';
  element: DataEditItem;
  extra?: AddDataItemApplication;
}

export type MapElementChangedDetail =
  | CloudServiceChanged
  | RemoteServerChanged
  | PrinterChanged
  | ComputerChanged
  | ExternalConnectionChanged
  | NetworkConnectionChanged
  | NetworkFirewallChanged
  | NetworkChanged
  | PersonalDataChanged;

/**
 * Viser enten signin eller 'account' avhengig av om brukeren er kjent
 * eller ikke.
 *
 * Viser også hjelpedelen.
 *
 * ENDRINGER
 *
 * Dette elementet skrives om slik at det ikke setter verdier "utenfor" seg selv og heller ikke forholder seg til
 * viewport størrelse. Det blir en "boks" som plasserer og tilpasser sine egne elementer basert på egen størrelse.
 *
 * Funksjonaliteten i session skal flyttes til dabih store eller lignende.
 *
 *
 */
@customElement('d-app-core')
export class DAppCore extends ResponsiveElement {
  static readonly styles = css`
    :host {
      display: flex;
      flex-direction: row;
    }

    d-signin {
      height: 100%;
      width: 100%;
    }

    d-signup {
      height: 100%;
      width: 100%;
    }

    d-account {
      height: 100%;
      width: 100%;
    }

    d-account-secured {
      height: 100%;
      width: 100%;
    }

    .overlay {
      height: 100vh;
    }

    #appContent {
      transition: all 0.3s;
      flex: 1;
    }

    d-article-viewer {
      transition: all 0.3s;
    }

    .colorBackground {
      background: hsl(196, 83%, 49%);
    }

    .colorBackground input,
    .colorBackground #box {
      box-shadow: none;
      background-color: white;
    }

    .colorBackground #label {
      color: hsla(1, 0%, 0%, 0.8);
    }

    .colorBackground a.toggle {
      color: white;
    }

    .container {
      background-color: white;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
      position: relative;
      width: 1000px;
    }

    #loading {
      width: 600px;
      margin: 50px auto;
      font-size: 150%;
      height: 400px;
    }

    #not-found {
      width: 600px;
      margin: 50px auto;
      font-size: 150%;
      color: white;
    }

    #no-user {
      width: 600px;
      margin: 50px auto;
      font-size: 150%;
      color: white;
    }

    d-spinner-robot {
      min-height: 200px;
    }

    d-spinner-robot > div {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 200;
      color: white;
    }
    /**
     * Space for status bar when application runs as native app
     */
    .safe-area-top {
      position: fixed;
      top: 0;
      width: 100vw;
      height: var(--safe-area-inset-top);
      background: var(--app-background-color);
      z-index: 1000;
    }
  `;

  @state()
  windowWidth = 600;

  @property({ type: Object })
  appState: AppState | undefined = undefined;
  @property({ type: Array })
  helpPages: HelpPageModel[] = [];
  @property()
  client = '';
  @property({
    type: Array,
  })
  currentPathArray: string[] = [];
  @property({ type: String })
  searchQuery = '';
  @property({ type: Array })
  searchResults: SearchResult[] = [];
  @property({ type: Boolean })
  online = false;
  @property({ type: Number })
  bodyScrollTop = 0;
  @property({ type: Number })
  bodyScrollLeft = 0;
  @property({ type: Boolean })
  edit = false;
  @property({ type: String })
  roomCode = '';
  @property({ type: Boolean })
  meet = false;
  @property({ type: Boolean })
  copilot = false;
  /**
   * Slide notification content
   */
  @state()
  protected slideNotificationContent: SlideNotificationContent | undefined = undefined;
  /**
   * Display slide notification
   */
  @state()
  protected showSlideNotification = false;

  slideNotification(content: SlideNotificationContent) {
    this.slideNotificationContent = content;
    this.showSlideNotification = true;
    setTimeout(() => {
      this.showSlideNotification = false;
    }, 5000);
  }

  onResize() {
    this.windowWidth = this.clientWidth;
  }

  onRequestLogin(e: CustomEvent<{ user: { username: string; password: string }; loginFailed: () => void }>) {
    console.log(e);
  }

  onRequestPassword(e: CustomEvent<{ email: string }>) {
    console.log(e);
  }

  _requestLink(e) {
    console.log(e);
  }

  onRequestLogout(e: CustomEvent) {
    console.log(e);
  }

  onToggleHelp(e) {
    console.log(e);
  }

  onToggleCopilot(e) {
    console.log(e);
  }

  onToggleSideContent(e) {
    console.log(e);
  }

  onToggleTutorial(e) {
    console.log(e);
  }

  onContentEditDone(e: CustomEvent<ContentEditDoneDetail>) {
    console.log(e);
  }

  onSaveOrganization(e: CustomEvent<OrganizationEditItem>) {
    console.log(e);
  }

  onSaveHideDoneOption(e: CustomEvent<{ value: string }>) {
    console.log(e);
  }

  onWeekStartChanged(e: CustomEvent<{ value: string }>) {
    console.log(e);
  }

  onEntityDelete(e: CustomEvent<{ url: string; type: string; uuid: string }>) {
    console.log(e);
  }

  onUploadFile(e: CustomEvent<{ file: File; callback: () => void; type: string; uuid: string }>) {
    console.log(e);
  }

  onUpdateHelp(e: CustomEvent<{ page: string }>) {
    console.log(e);
  }

  onUpdateRecognizedText(e: CustomEvent<{ text?: string }>) {
    console.log(e);
  }

  onUpdateTutorial(e: CustomEvent<{ value: string }>) {
    console.log(e);
  }

  onUpdateSideContent(e: CustomEvent<{ value: number }>) {
    console.log(e);
  }

  onSendMeetingNotice(e: CustomEvent<MessageForSend>) {
    console.log(e);
  }

  onSendMeetingReport(e: CustomEvent<MessageForSend>) {
    console.log(e);
  }

  onEmployeeGender(e: CustomEvent<{ uuid: string; gender: EmployeeViewModelGenderEnum }>) {
    console.log(e);
  }

  onTaskDone(e: CustomEvent<{ taskUuid: string }>) {
    console.log(e);
  }

  onSearchQueryChanged(e: CustomEvent<{ query: string }>) {
    console.log(e);
  }

  onOpenPage(e: CustomEvent<{ pageId: number; queryString: string }>) {
    console.log(e);
  }

  onToggleEventDone(e: CustomEvent<EventDoneDetail>) {
    console.log(e);
  }

  onToggleMeetingDone(e: CustomEvent<EventDoneDetail>) {
    console.log(e);
  }

  onStartTaskSelected(e: CustomEvent<{ uuid: string }>) {
    console.log(e);
  }

  onStartTaskClosed() {
    console.log('No details');
  }

  onShare(e: CustomEvent<{ message: string; recipients: string[] }>) {
    console.log(e);
  }

  onIgnoreRobot() {
    console.log('No details');
  }

  onIgnoreThisRobotAlert(e: CustomEvent<{ id: string }>) {
    console.log(e);
  }

  onSaveStaffingAccess(e: CustomEvent<{ accessList: string[]; leavePeriodEditRestriction: boolean }>) {
    console.log(e);
  }

  onStaffingYearChanged(e: CustomEvent<{ value: string }>) {
    console.log(e);
  }

  onStaffingCalendarUserDisplaySelectionChanged(e: CustomEvent<{ start: string; end: string; zoom: ZoomUnit }>) {
    console.log(e);
  }

  onUpdateEmployeeStaffGroups(e: CustomEvent<{ value: EmployeeWithStaffGroup[] }>) {
    console.log(e);
  }

  onDataItemChanged(e: CustomEvent<InfosecDataItem>) {
    console.log(e);
  }

  onDataItemsRemoved(e: CustomEvent<{ uuid: string; dataType: string; category: string }>) {
    console.log(e);
  }

  onComputerNetworkChange(e: CustomEvent<ComputerNetworkChange>) {
    console.log(e);
  }

  onExternalConnectionChange(e: CustomEvent<NetworkExternalConnectionChange>) {
    console.log(e);
  }

  onAccessChange(e: CustomEvent<AccessChange>) {
    console.log(e);
  }

  onBackupChange(e: CustomEvent<BackupChange>) {
    console.log(e);
  }

  onSaveTimekeepingPeriod(e: CustomEvent<EditPeriodResult>) {
    console.log(e);
  }

  onDeleteTimekeepingPeriod(e: CustomEvent<{ employeeUuid: string; periodId: string }>) {
    console.log(e);
  }

  onSaveLeavePeriod(e: CustomEvent<EditPeriodResult>) {
    console.log(e);
  }

  onDeleteLeavePeriod(e: CustomEvent<{ employeeUuid: string; periodId: string }>) {
    console.log(e);
  }

  onUploadSubstanceFiles(e: CustomEvent<{ files: File[]; callback: (data: UploadedFile[]) => void }>) {
    console.log(e);
  }

  onUpdateSubstanceFile(
    e: CustomEvent<{ files: File[]; substanceId: string; callback: (data: UploadedFile[]) => void }>,
  ) {
    console.log(e);
  }

  onResetSubstances() {
    console.log('No details');
  }

  onShareVacation(e: CustomEvent<ShareVacationResult>) {
    console.log(e);
  }

  onUnShareVacation(e: CustomEvent<{ recipient: string }>) {
    console.log(e);
  }

  onAssignFunction(e: CustomEvent<{ functionUuid: string; employeeUuid: string }>) {
    console.log(e);
  }

  onUpdateUser(e: CustomEvent<{ email: string; password?: string; alerts: string; receiveReminders: string }>) {
    console.log(e);
  }

  onUpdateSubscription(e: CustomEvent<Subscription>) {
    console.log(e);
  }

  onCreateIssue(e: CustomEvent<{ id: string; message: IssueUpdateMessage }>) {
    console.log(e);
  }

  onAddTask(e: CustomEvent<{ taskUuid: string; assetUuid: string }>) {
    console.log(e);
  }

  onRemoveTask(e: CustomEvent<{ taskUuid: string; assetUuid: string }>) {
    console.log(e);
  }

  onLinkAuth(e: CustomEvent<{ provider: 'oidc' | 'criipto' }>) {
    console.log(e);
  }

  onUnlinkAuth(e: CustomEvent<{ provider: 'oidc' | 'criipto' }>) {
    console.log(e);
  }

  onDisconnectSecureLogin() {
    console.log('No details');
  }

  onConnectSecureLogin() {
    console.log('No details');
  }

  onSavePersonalDataItem(e: CustomEvent) {
    console.log(e);
  }

  onTutorialChanged(
    e: CustomEvent<{
      uuid: string;
      participants: TutorialParticipant[];
      isCommonDate: boolean;
      commonDate: string;
      comment: string;
    }>,
  ) {
    console.log(e);
  }

  onTutorialStateChanged(e: CustomEvent<TutorialState>) {
    console.log(e);
  }

  onMapElementChanged(e: CustomEvent<MapElementChangedDetail>) {
    console.log(e);
  }

  onMapElementDeleted(e: CustomEvent<{ uuid: string; unitType: string }>) {
    console.log(e);
  }

  onSaveWorkday(e: CustomEvent<{ value: EditException; employeeUuid: string }>) {
    console.log(e);
  }

  onContentSaveEvent(e: CustomEvent<ContentEditDoneDetail>) {
    console.log(e);
  }

  onRelateAssetToEventOccurrences(e: CustomEvent<RelateAssetToEventOccurrencesDetail>) {
    console.log(e);
  }

  onContentDeleteEvent(e: CustomEvent<{ url: string; type: string; uuid: string; saveType: string }>) {
    console.log(e);
  }

  onContentSaveMeetingOccurrence(e: CustomEvent<ContentEditDoneDetail>) {
    console.log(e);
  }

  onContentDeleteMeetingOccurrence(
    e: CustomEvent<{ url: string; type: string; uuid: string; saveType: string; alerts: boolean }>,
  ) {
    console.log(e);
  }

  onSendFeedback(e: CustomEvent<{ url: string; message: string }>) {
    console.log(e);
  }

  onNewPartner(e: CustomEvent<{ uuid: string; name: string }>) {
    console.log(e);
  }

  onSavePrepareState(
    e: CustomEvent<{
      organizationId: string;
      state: AccountStateViewModel;
      saveToServer: boolean;
      afterSave: (() => void) | undefined;
    }>,
  ) {
    console.log(e);
  }

  onUpdateTimeCorrection(e: UpdateTimeCorrectionEvent) {
    console.log(e);
  }

  onDeleteTimeCorrection(e: DeleteTimeCorrectionEvent) {
    console.log(e);
  }

  onUpdateEmployeeWorkSchedules(
    e: CustomEvent<{
      employeeUuid: string;
      workSchedules: WorkSchedule[];
    }>,
  ) {
    console.log(e);
  }

  onRenameAttachment(
    e: CustomEvent<{
      document: FileViewerDocument;
    }>,
  ) {
    console.log(e);
  }

  onDeleteAttachment(
    e: CustomEvent<{
      document: FileViewerDocument;
    }>,
  ) {
    console.log(e);
  }

  onSlideNotification(e: CustomEvent<SlideNotificationContent>) {
    this.slideNotification(e.detail);
  }

  onRestore(e: CustomEvent<{ entityType: string; entityId: string }>) {
    console.log(e);
  }

  onUpdateDeclined(e: CustomEvent<{ entityType: string; entityId: string }>) {
    console.log(e);
  }

  onUpdateEventAsset(
    e: CustomEvent<{
      checkedBy: string;
      checkedTime: string;
      status: string;
      comments: string;
      assetUuid: string;
      eventOccurrenceUuid: string;
    }>,
  ) {
    console.log(e);
  }

  onRepeatMeetingOccurrence(
    e: CustomEvent<{
      uuid: string;
      dateTimesToAdd: string[];
      occurrencesToRemove: string[];
      applyToCollections: boolean;
    }>,
  ) {
    console.log(e);
  }

  onRepeatEventOccurrence(
    e: CustomEvent<{
      uuid: string;
      dateTimesToAdd: string[];
      occurrencesToRemove: string[];
      applyToCollections: boolean;
    }>,
  ) {
    console.log(e);
  }

  onRestoreRevision(e: CustomEvent<{ name: string; content: string; status: string; type: string; uuid: string }>) {
    console.log(e);
  }

  onChecklistChanged(e: CustomEvent<{ content: string; uuid: string }>) {
    console.log(e);
  }

  onShowShareVacation(e: CustomEvent<{ email: string }>) {
    console.log(e);
  }

  onCreateMeetingReport(e: CustomEvent<{ uuid: string; href: string }>) {
    console.log(e);
  }

  onCancelSigningOrder(
    e: CustomEvent<{
      entityType: string;
      entityUuid: string;
      signingOrderId: string;
    }>,
  ) {
    console.log(e);
  }

  onExtendSigningOrder(
    e: CustomEvent<{
      entityType: string;
      entityUuid: string;
      signingOrderId: string;
      dueDate: string;
    }>,
  ) {
    console.log(e);
  }

  onCreateSignaturePerson(
    e: CustomEvent<{
      uuid: string;
      firstName: string;
      lastName: string;
      email?: string;
      partnerUuid: string;
      personalIdentifier?: string;
    }>,
  ) {
    console.log(e);
  }

  onUpdateSignaturePerson(
    e: CustomEvent<{
      uuid: string;
      email?: string;
      personalIdentifier?: string;
    }>,
  ) {
    console.log(e);
  }

  onRequestSignatures(
    e: CustomEvent<{
      entityType: string;
      entityUuid: string;
      signeesForOrganization: string[];
      signeesForPartners: string[];
      signeesAsEmployees: string[];
      content: ContentItem[];
      message: string;
      dueDate: LocalDate;
    }>,
  ) {
    console.log(e);
  }

  onCreateEntity(e: CustomEvent<CreateEntityInput>) {
    console.log(e);
  }

  onSaveDraft(
    e: CustomEvent<{ entityType: string; entityUuid: string; draft: Record<string, unknown>; done: () => void }>,
  ) {
    console.log(e);
  }

  onCreateEmployee(e: CustomEvent<CreateEmployeeInput>) {
    console.log(e);
  }

  onCheckEmail(e: CustomEvent<{ email: string }>) {
    console.log(e);
  }

  onCreateOrganization(e: CustomEvent<NewOrganization>) {
    console.log(e);
  }

  onCreateUser(e: CustomEvent<{ email: string; module: string; serviceType: string }>) {
    console.log(e);
  }

  onCreateContactAndPartner(e: CustomEvent<CreateContactAndPartnerInput>) {
    console.log(e);
  }

  onClearDraft(e: CustomEvent<{ entityType: string; entityUuid: string }>) {
    console.log(e);
  }

  onUpdateUserAccess(e: UpdateUserAccessEvent) {
    console.log(e);
  }

  onNewRiskAssessment(e: CustomEvent<{ uuid: string; name: string }>) {
    console.log(e);
  }

  onNewEmployee(e: CustomEvent<{ uuid: string; name: string }>) {
    console.log(e);
  }

  onNewContract(e: CustomEvent<{ uuid: string; name: string }>) {
    console.log(e);
  }

  onNewAsset(e: CustomEvent<{ uuid: string; name: string }>) {
    console.log(e);
  }

  onUpdateSpecialTerms(e: CustomEvent<{ specialTerms: string }>) {
    console.log(e);
  }

  onShowMeet(e: CustomEvent<{ meetingOccurrenceId: string }>) {
    console.log(e);
  }
  onHideMeet(e: CustomEvent) {
    console.log(e);
  }

  onUpdateUiSettings(e: CustomEvent<{ isAppPlatform: boolean; isSimplifiedUi: boolean }>) {
    console.log(e);
  }
  onNewNetwork(e: CustomEvent<{ item: InfosecNetwork }>) {
    console.log(e);
  }
  onNewComputer(e: CustomEvent<{ uuid: string; name: string; type: string }>) {
    console.log(e);
  }

  onUpdateCollectionsFilter(e: CustomEvent<CollectionFilter[]>) {
    console.log(e);
  }

  onUpdateAllCollections(e: CustomEvent<{ entityUuid: string; existingTopics: string[]; newCollectionName?: string }>) {
    console.log(e);
  }

  onAddToCollection(e: CustomEvent<{ collectionUuid: string; entityUuid: string; entityType: string }>) {
    console.log(e);
  }

  renderAppStatePage(appState: AppState) {
    switch (appState.page) {
      case 'signin':
        return this.renderSignin(appState);
      case 'signup':
        return this.renderSignup(appState);
      case 'account':
        return this.renderAccount(appState);
      case 'notFound':
        return this.renderNotFound(appState);
      case 'loading':
        return this.renderLoading(appState);
      case 'link':
        return this.renderLink(appState);
      case 'secured':
        return this.renderSecured(appState);
      case 'noUser':
        return this.renderNoUser(appState);
    }
  }

  render() {
    return this.appState === undefined
      ? nothing
      : html`
          <d-slide-notification
            ?show=${this.showSlideNotification}
            ?hold=${false}
            .content=${this.slideNotificationContent}
          ></d-slide-notification>
          ${this.renderAppStatePage(this.appState)}
          <div class="safe-area-top"></div>
        `;
  }

  onEdit() {
    this.edit = true;
  }

  onEditOff() {
    this.edit = false;
  }

  onToggleFeature(e: CustomEvent<{ feature: string }>) {
    console.log(e);
  }

  onAddFeatures(e: CustomEvent<{ features: string[] }>) {
    console.log(e);
  }

  private renderSignin(_appState: AppStateSignin) {
    return html`
      <d-signin
        id="signin"
        name="signin"
        @request-login=${this.onRequestLogin}
        @request-password=${this.onRequestPassword}
      >
        Laster inn TrinnVis
        <slot slot="usernameInput" name="usernameInput"></slot>
        <slot slot="passwordInput" name="passwordInput"></slot>
      </d-signin>
    `;
  }
  private renderSignup(appState: AppStateSignup) {
    return html`
      <d-signup
        .currentUser=${appState.currentUser}
        @check-email=${this.onCheckEmail}
        @create-organization=${this.onCreateOrganization}
        @request-login=${this.onRequestLogin}
        @request-password=${this.onRequestPassword}
      >
        <slot slot="usernameInput" name="usernameInput"></slot>
        <slot slot="passwordInput" name="passwordInput"></slot>
      </d-signup>
    `;
  }

  private renderLink(appState: AppStateLink) {
    return html`
      <d-signin
        id="link"
        name="link"
        mode="link"
        .locationSearch=${appState.locationSearch}
        @request-link=${this._requestLink}
      >
        Laster inn TrinnVis.
        <slot slot="usernameInput" name="usernameInput"></slot>
        <slot slot="passwordInput" name="passwordInput"></slot>
      </d-signin>
    `;
  }

  private renderLoading(_appState: AppStateLoading) {
    const p = new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          html` <d-spinner-robot @click=${this.onSpinnerClick}>
            <div>Laster inn</div>
          </d-spinner-robot>`,
        );
      }, 500);
    });

    return until(p, nothing);
  }

  private onSpinnerClick() {
    this.dispatchEvent(new CustomEvent('navigate', { composed: true, bubbles: true, detail: { href: '/account' } }));
  }

  private renderAccount(appState: AppStateAccount) {
    return html`
      <d-account
        client="${this.client}"
        name="account"
        .user=${appState.user}
        .helpViewerOpen=${appState.helpViewerOpen}
        .helpViewerPage=${appState.helpViewerPage}
        .bodyScrollTop=${this.bodyScrollTop}
        .bodyScrollLeft=${this.bodyScrollLeft}
        .pathSegments=${this.currentPathArray.slice(2)}
        .applicationViewModel=${appState.viewModel}
        .searchQuery=${this.searchQuery}
        .searchResults=${this.searchResults}
        .online=${this.online}
        .edit=${this.edit}
        .meet=${this.meet}
        .copilot=${this.copilot}
        .roomCode=${this.roomCode}
        ?writeAccess=${appState.writeAccess}
        @request-logout=${this.onRequestLogout}
        @toggle-help=${this.onToggleHelp}
        @toggle-copilot=${this.onToggleCopilot}
        @update-help=${this.onUpdateHelp}
        @update-recognized-text=${this.onUpdateRecognizedText}
        @toggle-tutorial=${this.onToggleTutorial}
        @update-tutorial=${this.onUpdateTutorial}
        @toggle-side-content=${this.onToggleSideContent}
        @update-side-content=${this.onUpdateSideContent}
        @content-edit-done=${this.onContentEditDone}
        @save-organization=${this.onSaveOrganization}
        @save-hide-done-option=${this.onSaveHideDoneOption}
        @week-start-changed=${this.onWeekStartChanged}
        @entity-delete=${this.onEntityDelete}
        @upload-file=${this.onUploadFile}
        @search-query-changed=${this.onSearchQueryChanged}
        @share=${this.onShare}
        @open-page=${this.onOpenPage}
        @ignore-robot=${this.onIgnoreRobot}
        @ignore-this-robot-alert=${this.onIgnoreThisRobotAlert}
        @toggle-event-done=${this.onToggleEventDone}
        @toggle-meeting-done=${this.onToggleMeetingDone}
        @start-task-selected=${this.onStartTaskSelected}
        @start-task-closed=${this.onStartTaskClosed}
        @upload-substance-files=${this.onUploadSubstanceFiles}
        @update-substance-file=${this.onUpdateSubstanceFile}
        @reset-substances=${this.onResetSubstances}
        @save-staffing-access=${this.onSaveStaffingAccess}
        @staffing-year-changed=${this.onStaffingYearChanged}
        @staffing-calendar-user-display-selection-changed=${this.onStaffingCalendarUserDisplaySelectionChanged}
        @save-timekeeping-period=${this.onSaveTimekeepingPeriod}
        @delete-timekeeping-period=${this.onDeleteTimekeepingPeriod}
        @save-leave-period=${this.onSaveLeavePeriod}
        @delete-leave-period=${this.onDeleteLeavePeriod}
        @share-vacation=${this.onShareVacation}
        @unshare-vacation=${this.onUnShareVacation}
        @assign-function=${this.onAssignFunction}
        @update-user=${this.onUpdateUser}
        @update-subscription=${this.onUpdateSubscription}
        @create-issue=${this.onCreateIssue}
        @add-task=${this.onAddTask}
        @remove-task=${this.onRemoveTask}
        @link-auth=${this.onLinkAuth}
        @unlink-auth=${this.onUnlinkAuth}
        @disconnect-secure-login=${this.onDisconnectSecureLogin}
        @connect-secure-login=${this.onConnectSecureLogin}
        @save-personal-data-item=${this.onSavePersonalDataItem}
        @tutorial-changed=${this.onTutorialChanged}
        @tutorial-state-changed=${this.onTutorialStateChanged}
        @map-element-changed=${this.onMapElementChanged}
        @new-partner=${this.onNewPartner}
        @new-risk-assessment=${this.onNewRiskAssessment}
        @new-employee=${this.onNewEmployee}
        @new-network=${this.onNewNetwork}
        @new-computer=${this.onNewComputer}
        @new-contract=${this.onNewContract}
        @new-asset=${this.onNewAsset}
        @send-meeting-notice=${this.onSendMeetingNotice}
        @send-meeting-report=${this.onSendMeetingReport}
        @task-done=${this.onTaskDone}
        @employee-gender=${this.onEmployeeGender}
        @update-employee-staff-groups=${this.onUpdateEmployeeStaffGroups}
        @data-item-changed=${this.onDataItemChanged}
        @data-items-removed=${this.onDataItemsRemoved}
        @computer-network-change=${this.onComputerNetworkChange}
        @external-connection-change=${this.onExternalConnectionChange}
        @access-change=${this.onAccessChange}
        @backup-change=${this.onBackupChange}
        @map-element-deleted=${this.onMapElementDeleted}
        @save-workday=${this.onSaveWorkday}
        @content-save-event=${this.onContentSaveEvent}
        @relate-asset-to-event-occurrences=${this.onRelateAssetToEventOccurrences}
        @content-delete-event=${this.onContentDeleteEvent}
        @content-save-meeting-occurrence=${this.onContentSaveMeetingOccurrence}
        @content-delete-meeting-occurrence=${this.onContentDeleteMeetingOccurrence}
        @send-feedback=${this.onSendFeedback}
        @update-employee-work-schedules=${this.onUpdateEmployeeWorkSchedules}
        @save-prepare-state=${this.onSavePrepareState}
        @update-time-correction=${this.onUpdateTimeCorrection}
        @delete-time-correction=${this.onDeleteTimeCorrection}
        @rename-attachment=${this.onRenameAttachment}
        @delete-attachment=${this.onDeleteAttachment}
        @slide-notification=${(e) => this.onSlideNotification(e)}
        @update-declined=${this.onUpdateDeclined}
        @restore=${this.onRestore}
        @update-user-access=${this.onUpdateUserAccess}
        @update-event-asset=${this.onUpdateEventAsset}
        @repeat-meeting-occurrence=${this.onRepeatMeetingOccurrence}
        @repeat-event-occurrence=${this.onRepeatEventOccurrence}
        @restore-revision=${this.onRestoreRevision}
        @save-draft=${this.onSaveDraft}
        @clear-draft=${this.onClearDraft}
        @checklist-changed=${this.onChecklistChanged}
        @show-share-vacation=${this.onShowShareVacation}
        @create-meeting-report=${this.onCreateMeetingReport}
        @request-signatures=${this.onRequestSignatures}
        @cancel-signing-order=${this.onCancelSigningOrder}
        @extend-signing-order=${this.onExtendSigningOrder}
        @create-signature-person=${this.onCreateSignaturePerson}
        @update-signature-person=${this.onUpdateSignaturePerson}
        @create-entity=${this.onCreateEntity}
        @create-employee=${this.onCreateEmployee}
        @create-contact-and-partner=${this.onCreateContactAndPartner}
        @edit=${() => this.onEdit()}
        @edit-off=${() => this.onEditOff()}
        @toggle-feature=${this.onToggleFeature}
        @add-features=${this.onAddFeatures}
        @update-special-terms=${this.onUpdateSpecialTerms}
        @show-meet=${this.onShowMeet}
        @hide-meet=${this.onHideMeet}
        @update-ui-settings=${this.onUpdateUiSettings}
        @update-all-collections=${this.onUpdateAllCollections}
        @add-to-collection=${this.onAddToCollection}
        @update-collections-filter=${this.onUpdateCollectionsFilter}
      >
      </d-account>
    `;
  }

  private renderNotFound(appState: AppStateNotFound) {
    if (appState.requestedPage === 'signup') return nothing;
    return html` <div id="not-found">Ikke funnet: ${appState.requestedPage}</div> `;
  }

  private renderNoUser(_appState: AppStateNoUser) {
    const p = new Promise((resolve) => {
      setTimeout(() => {
        resolve(html`
          <d-spinner-robot @click=${this.onSpinnerClick}>
            <div>Henter bruker</div>
          </d-spinner-robot>
        `);
      }, 500);
    });

    return until(p, nothing);
  }

  private renderSecured(appState: AppStateSecured) {
    return html` <d-account-secured
      .user=${appState.user}
      .userOrganization=${appState.selectedUserOrganization}
      @request-logout=${this.onRequestLogout}
    >
    </d-account-secured>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-app-core': DAppCore;
  }
}
