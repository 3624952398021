import type { ContentView } from 'src/content/d-content';
import { CopilotFieldPrompt } from 'src/layout/parts/d-copilot-field';
import { memoizedFetchAndExtract } from 'src/utilities/text';
import { memoizedAsk } from 'src/store';
import { EmployeeView } from 'src/content/employees/d-employee-view';
import { MeetingOccurrenceView } from 'src/content/meeting-occurrences/d-meeting-occurrence-view';

async function calculateEmployeePrompt(organizationId: string, view: EmployeeView) {
  const name = view.name;
  const attachments = view.attachments;
  console.log('Employee copilot', organizationId, name);

  let c = '';

  if (attachments && attachments.length > 0) {
    for (const a of attachments) {
      const p = await memoizedFetchAndExtract(a.previewHref);

      const prompt = `
    Du er en rådgiver for det elektroniske styringssystemet TrinnVis. Av og til registrerer brukere avtaler som vedlegg på personalle. Du skal sjekke dette vedlegget og heller anbefale at de bruker f.eks. dokumenttypen avtaler i systemet hvis det er relevant.

      Her er teksten til en avtale. Sjekk om dette er en arbeidsavtale. Hvis du er usikker så sier du hva som er mest sannsynlig. Svaralternativene er ARBEIDSAVTALE, IKKE ARBEIDSAVTALE, eventuelt også hvilken type dokument dette faktisk er. Hvis det er en arbeidsavtale så gi råd om å flyttet vedlegget til en avtale i stedet for å beholde det som et vedlegg.


      Avtaletekst: ${p}
      `;

      const r = await memoizedAsk(organizationId, prompt);

      // console.log(r);

      c += '<p><strong>' + a.name + '</strong>: ' + r + '</p>\n\n';
    }
  }
  return {
    title: 'Oppdater ' + name,
    content: c,
    helpContent: '',
  };
}

async function calculateMeetingOccurrencePrompt(organizationId: string, view: MeetingOccurrenceView) {
  const prompt = `
        Du er en møteleder for interne møter i denne lille bedriften. Lag en oppsummert agenda formattert som lister opp hvert hovedpunkt i møtet i et utsagn eller setning. Maksimalt 7 pukter.
           Helst bruk 4 punkter men mindre eller flere dersom det er nødvendig. Skriv bare den resulterende som rå HTML koden uten noe markdown.
   
   
           
    her er agendaen: ${view.meetingAgenda}
    
    slutt på agenda
    
 
    
    `;

  console.log('prompt: ', prompt);

  const r = await memoizedAsk(organizationId, prompt);

  const prompt2 = `
        Du er en møteleder for interne møter i denne lille bedriften. Her er en oppsummert agenda formattert som en liste med hvert hovedpunkt i møtet
   
   
           
    her er agendaen: ${r}
    
    slutt på agenda
    
    Basert på denne listen pluss det som er sagt i møtet hittl identifiser det mest sannsynlige punktet i listen som viser hvor vi er i møtet akkurat nå. 

    her er det som er sagt i møtet hittil: ${view.recognizedText ?? ''}
    
    slutt på det som er sagt
    
   
    
    `;

  console.log('prompt2: ', prompt2);

  const r2 = await memoizedAsk(organizationId, prompt2);

  // console.log(r);

  return {
    title: 'Møteplan for ' + view.name,
    content: r + '<h2>Gjeldende punkt</h2>' + r2,
    helpContent: '',
  };
}

export async function calculatePrompt(
  organizationId: string,
  view: ContentView,
): Promise<CopilotFieldPrompt | undefined> {
  return throttledCalculatePrompt(organizationId, view);
}

async function doCalculatePrompt(organizationId: string, view: ContentView): Promise<CopilotFieldPrompt | undefined> {
  switch (view.type) {
    case 'employees':
      return calculateEmployeePrompt(organizationId, view);
    case 'meetingOccurrences':
      return calculateMeetingOccurrencePrompt(organizationId, view);
    default:
      return undefined;
  }
}

const throttledCalculatePrompt = throttle(doCalculatePrompt, 5000);

function throttle(fn, interval) {
  let lastResult;
  let lastTime = 0;

  return function (arg1, arg2) {
    const now = Date.now();

    if (now - lastTime >= interval) {
      lastTime = now;
      lastResult = fn(arg1, arg2);
    }

    return lastResult;
  };
}
