import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { PageContent } from 'src/pages/page-content.js';
import 'src/utilities/display-alert';
import '../../content/d-entity-header.js';
import '../../library/components/d-help-section.js';
import '../../library/elements/d-action.js';
import { PageViewModel } from 'src/store/api';
import type { AbstractPageView } from 'src/pages/abstract-page-view';
import type { ListSectionItemInput } from 'src/library/lists/utilities';
import { features, promo } from 'src/store/selectors/features';
import { registerItemsPromptStyles } from 'src/library/register-items-prompt-styles';
import { isEmpty } from 'lodash';
import { uuid } from 'src/utilities/text';
import { CreateCollectionInput, CreateEntityInput } from 'src/layout/parts/d-new-documents-list';
import { pageIds } from 'src/store';

export interface CollectionsPageViewModel extends AbstractPageView {
  type: 'collections-page';
  icon: 'collections';
  collections: ListSectionItemInput[];
  pdfHref: string;
}

/**
 *
 */
@customElement('d-collections-page-content')
export class DCollectionsPageContent extends PageContent<CollectionsPageViewModel> {
  static readonly styles = [...super.styles, registerItemsPromptStyles];
  @property({ type: Array })
  pages: PageViewModel[] = [];
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  editMode = false;
  @property({ type: Object })
  token = {};
  @property({ type: Boolean })
  uncoverPageMenu = false;
  @property({ type: String })
  key = '';

  onShareContent() {
    this.dispatchEvent(new CustomEvent('open-send-message', { bubbles: true, composed: true, detail: {} }));
  }

  renderPromoSection() {
    const promoData = promo.find((p) => {
      return p.id === 'complete';
    });
    if (promoData) {
      return html` <d-promo-section
        theme-page
        .employeesCount=${this.pageView.employeesCount}
        .specialTerms=${this.pageView.specialTerms}
        .promoData=${promoData}
        .features=${features}
        .canUpgrade=${this.writeAccess}
      ></d-promo-section>`;
    }
    return nothing;
  }

  renderRegisterItemsPrompt() {
    return html`<div class="register-items-prompt collections" @click=${() => this.onCreateCollection()}>
      <h2>Ingen samlinger er opprettet.</h2>
      <p>
        Samlinger er egendefinerte grupper av TrinnVis-dokumenter. Samlinger kan brukes til prosjekter, handlingsplaner
        og andre oversikter.
      </p>
      ${this.pageView.writeAccess ? html` <p>Trykk for å lage en samling.</p>` : nothing}
    </div>`;
  }

  renderContent() {
    if (!this.pageView.featureStates.core) {
      return this.renderPromoSection();
    }
    return html`
      ${isEmpty(this.pageView.collections)
        ? this.renderRegisterItemsPrompt()
        : html`
            <d-list-section
              no-header
              icon="collections"
              label="Samlinger"
              .items=${this.pageView.collections}
              theme-page
              .contentStickyTop=${this.contentStickyTop}
            >
            </d-list-section>
          `}
    `;
  }

  private onCreateCollection() {
    if (this.pageView.writeAccess) {
      const id = uuid();
      const input: CreateCollectionInput = {
        entityType: 'collections',
        entityUuid: id,
        pageId: 0,
        targetUrl:
          '/account/' + this.pageView.organizationId + '/' + pageIds.collections + '/collections/' + id + '?edit',
      };
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: input,
        }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-collections-page-content': DCollectionsPageContent;
  }
}
