import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { PageContent } from 'src/pages/page-content.js';
import 'src/utilities/display-alert';
import '../../content/d-entity-header.js';
import '../../library/components/d-help-section.js';
import '../../library/elements/d-action.js';
import { PageViewModel } from 'src/store/api';
import type { AbstractPageView } from 'src/pages/abstract-page-view';
import type { ListSectionItemInput } from 'src/library/lists/utilities';
import { ActivityCode } from 'src/pages/organization-page/organization-page-view-model';
import { features, promo } from 'src/store/selectors/features';
export interface TutorialsPageViewModel extends AbstractPageView {
  type: 'tutorials-page';
  availableActivityCodes: ActivityCode[];
  list: ListSectionItemInput[];
  pdfHref: string;
}

/**
 *
 */
@customElement('d-tutorials-page-content')
export class DTutorialsPageContent extends PageContent<TutorialsPageViewModel> {
  @property({ type: Array })
  pages: PageViewModel[] = [];
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  editMode = false;
  @property({ type: Object })
  token = {};
  @property({ type: Boolean })
  uncoverPageMenu = false;
  @property({ type: String })
  key = '';
  @property({ type: Number })
  organizationId = 0;

  onShareContent() {
    this.dispatchEvent(new CustomEvent('open-send-message', { bubbles: true, composed: true, detail: {} }));
  }

  renderContent() {
    if (!this.pageView.featureStates.core && !this.pageView.featureStates.data) {
      const promoData = promo.find((p) => {
        return p.id === 'complete';
      });
      if (promoData) {
        return html` <d-promo-section
          theme-page
          .employeesCount=${this.pageView.employeesCount}
          .specialTerms=${this.pageView.specialTerms}
          .promoData=${promoData}
          .features=${features}
          .canUpgrade=${this.writeAccess}
        ></d-promo-section>`;
      }
      return nothing;
    }
    return html`
      <d-list-section
        no-header
        write-access="${this.writeAccess}"
        theme-page
        .items=${this.pageView.list}
        .contentStickyTop=${this.contentStickyTop}
      >
      </d-list-section>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-tutorials-page-content': DTutorialsPageContent;
  }
}
