// 2019 - 2029, data from https://webapi.no/api/v1/holydays/[year]
import { createSelector } from '@reduxjs/toolkit';
import { sortBy, orderBy } from 'lodash';
import { DayOfWeek, LocalDate } from '../../utilities/local-date.js';
import {
  EmployeeViewModel,
  EmployeeViewModelGenderEnum,
  EmployeeViewModelStaffGroupEnum,
  FunctionViewModel,
  LeavePeriod,
  LeavePeriodDay,
  LeavePeriodDayLeaveStatusEnum,
  PlusTimePeriod,
  TaskViewModel,
  TimekeepingCorrection,
  TimekeepingCorrectionCorrectionTypeEnum,
  WorkSchedule,
  WorkScheduleDay,
  WorkScheduleException,
  WorkScheduleRestPeriodEnum,
  WorkScheduleWeek,
} from '../api';

import holidaysList from '../data/holidays.json';
import leavePeriodTypesList from '../data/leave-period-types.json';
import type { EmployeeViewModelWithName, State } from '../types.js';
import { getOrganization } from './organization.js';
import { currentEmployeeUuid, employeesNotDeleted, employeesShortNames } from './organization-employees.js';
import { functionsNotDeleted, tasksNotDeleted } from './organization-functions.js';
import type { StaffingEmployeeDayTask } from '../../pages/staffing-page/staffing-employee-day-dialog.js';
import { findHolidayName, RecurrenceRule } from '../../store';
import { WorkDay, WorkDayWithException } from '../../pages/staffing-page/staffing-employee-day-dialog.js';
import { displayName } from 'src/utilities/text';
import { StaffingCalendarUserDisplaySelection } from 'src/pages/staffing-page/staffing-page-view-model';
import { userPropertiesForCurrentOrganization } from 'src/store/selectors/userPropertiesForCurrentOrganization';

export const leavePeriodTypes: LeavePeriodLookupType[] = leavePeriodTypesList as LeavePeriodLookupType[];

export interface LeavePeriodLookupType {
  value: string;
  text: string;
  color: string;
}

interface EmployeePeriodDay {
  day: string;
  start: string;
  end: string;
  status: string;
}

export interface EmployeePeriod {
  start: string;
  startTime?: string;
  end: string;
  endTime?: string;
  type: string;
  grade: number;
  days: LeavePeriodDay[];
  subPeriods?: EmployeePeriod[];
  confirmed: boolean;
  notes: string;
  uuid: string;
}

export interface PeriodWithMinutes {
  start: string;
  startTime?: string;
  end: string;
  endTime?: string;
  startMinutes: number;
  endMinutes: number;
  type: string;
  grade: number;
  days?: EmployeePeriodDay[];
  subPeriods: SubPeriodWithMinutes[];
  confirmed: boolean;
  notes: string;
  id?: string;
}

interface PeriodGroupWithMinutes {
  type: string;
  start: string;
  end: string;
  startMinutes: number;
  endMinutes: number;
  periods: PeriodWithMinutes[];
}

export interface EmployeeForStaffingCalendar {
  uuid: string;
  firstName: string;
  lastName: string;
  name: string;
  displayName: string;
  staffGroup: EmployeeViewModelStaffGroupEnum;
  treatmentProvider: boolean;
  treatmentHelper: boolean;
  workSchedules: WorkSchedule[];
  workScheduleExceptions: WorkScheduleException[];
  leavePeriods: LeavePeriod[];
  plusTimePeriods: PlusTimePeriod[];
  timekeepingCorrections: TimekeepingCorrection[];
  gender: EmployeeViewModelGenderEnum;
  tasksLookup: (date: string) => StaffingEmployeeDayTask[];
}

export interface ScheduleDayData {
  workHours: number;
  start: string;
  end: string;
  holidays: boolean;
  exception: boolean;
  exceptionWork: boolean;
  scheduleStart: string;
  scheduleEnd: string;
}

export interface EmployeesLeavePeriods {
  periods: EmployeePeriod[];
}

export interface YearDay {
  date: string;
  displayDate: number;
  holiday: string;
  weekday: string;
  displayDateFull: string;
  unitStart: boolean;
  today: boolean;
  startMinutes: number;
  endMinutes: number;
  workDays?: number;
  workdayStyle?: string;
  plusTimePeriods?: DataPeriod[];
  leavePeriods?: DataPeriod[];
}

export interface PeriodRowGroup {
  startMinutes: number;
  endMinutes: number;
  rows: PeriodWithMinutes[][];
}

interface DataPeriod {
  type: string;
  subPeriods?: DataPeriod[];
  confirmed: boolean;
  notes: string;
  relStartMinutes: number;
  relEndMinutes: number;
  classes: string;
  style: string;
}

export interface TimekeepingPeriod {
  type: string;
  confirmed: boolean;
  start: string;
  startTime: string;
  end: string;
  endTime: string;
  timeDisplay: string;
  minutes: number;
  hoursDisplay: string;
  notes: string;
  id?: string;
}

export interface EmployeeWithTimekeepingPeriods {
  uuid: string;
  name: string;
  periods: TimekeepingPeriod[];
  forStaffing: EmployeeForStaffingCalendar;
}

export interface SharedVacation {
  email: string;
  time: string;
}

/* DEFINITIONS */

export const defaultWorkStartTime = '08:00';
export const defaultWorkEndTime = '16:00';
export const defaultExcludedBreakMinutes = 30;

export const defaultWorkWeek: WorkScheduleWeek = {
  monday: { workDay: true, start: defaultWorkStartTime, end: defaultWorkEndTime },
  tuesday: { workDay: true, start: defaultWorkStartTime, end: defaultWorkEndTime },
  wednesday: { workDay: true, start: defaultWorkStartTime, end: defaultWorkEndTime },
  thursday: { workDay: true, start: defaultWorkStartTime, end: defaultWorkEndTime },
  friday: { workDay: true, start: defaultWorkStartTime, end: defaultWorkEndTime },
  saturday: { workDay: false, start: defaultWorkStartTime, end: defaultWorkEndTime },
  sunday: { workDay: false, start: defaultWorkStartTime, end: defaultWorkEndTime },
};

export const defaultWorkSchedule: WorkSchedule = {
  start: '',
  holidaysAreTimeOff: true,
  restPeriod: WorkScheduleRestPeriodEnum.WithoutPay,
  workHoursDefinedPerDay: false,
  workDayStartTime: defaultWorkStartTime,
  workDayEndTime: defaultWorkEndTime,
  workWeeks: [defaultWorkWeek],
};

export const defaultWorkHours =
  (durationInMinutes(defaultWorkStartTime, defaultWorkEndTime) - defaultExcludedBreakMinutes) / 60;

function normalizeHolidays(): { [key: string]: string } {
  const r: { [key: string]: string } = {};
  holidaysList.forEach(function (h) {
    const key = h.date.substring(0, 10);
    r[key] = h.description;
  });
  return r;
}

const leaveTypesThatAllowPlusTime = ['vacation', 'leave'];

/**
 * Lookup table for holidays.
 * @type object
 */
const _holidaysForStaffingCalendar = normalizeHolidays();

export type HolidaysType = { [key: string]: string };

export const holidaysForStaffingCalendar = (): HolidaysType => _holidaysForStaffingCalendar;

export const staffingCalendarYear = (state: State): string => state.staffingCalendarYear;

export const staffingCalendarUserDisplaySelection = createSelector(
  [userPropertiesForCurrentOrganization],
  function (userProperties): StaffingCalendarUserDisplaySelection {
    return (
      userProperties.staffingCalendarUserDisplaySelection || {
        zoom: 'day',
        start: '08',
        end: '16',
      }
    );
  },
);

export const staffingCalendarDisplayDayStart = createSelector(
  [userPropertiesForCurrentOrganization],
  function (userProperties): string {
    if (
      userProperties.staffingCalendarUserDisplaySelection &&
      userProperties.staffingCalendarUserDisplaySelection.start
    ) {
      return userProperties.staffingCalendarUserDisplaySelection.start;
    }
    return '08:00';
  },
);

export const staffingCalendarDisplayDayEnd = createSelector(
  [userPropertiesForCurrentOrganization],
  function (userProperties): string {
    if (
      userProperties.staffingCalendarUserDisplaySelection &&
      userProperties.staffingCalendarUserDisplaySelection.end
    ) {
      return userProperties.staffingCalendarUserDisplaySelection.end;
    }
    return '16:00';
  },
);

function calculateStaffingCalendarYearOptions(): { id: string; value: string }[] {
  const options: { id: string; value: string }[] = [];
  const startYear = 2018;
  const thisYear = LocalDate.now().year();
  const yearsFromNow = 10;
  const yearsCount = thisYear - startYear + yearsFromNow;
  let optionYear = startYear;
  for (let y = 0; y < yearsCount; y++) {
    options.push({ id: optionYear.toString(), value: optionYear.toString() });
    optionYear += 1;
  }
  return options;
}

const _sortEmployees = function (
  employees: EmployeeForStaffingCalendar[],
  treatmentProvidersFirst: boolean,
): EmployeeForStaffingCalendar[] {
  if (treatmentProvidersFirst) {
    const treatmentProviders = employees.filter(function (employee) {
      return employee.treatmentProvider;
    });
    const notTreatmentProviders = employees.filter(function (employee) {
      return !employee.treatmentProvider;
    });
    return treatmentProviders.concat(notTreatmentProviders);
  }
  return employees;
};

function displayNameForEmployee(employees: { uuid: string; name: string }[], e: EmployeeViewModel): string {
  return employees.filter(function (employee) {
    return employee.uuid === e.uuid;
  })[0].name;
}

function isTreatmentProvider(e: EmployeeViewModel, functions: FunctionViewModel[]): boolean {
  const treatmentProviderFunctions = functions.filter(function (item) {
    return (
      item.employees.includes(e.uuid) &&
      (item.templateId === 3765 || // lege
        item.templateId === 3784 || // fysioterapeut
        item.templateId === 3777 || // tannlege
        item.templateId === 3775 || // kiropraktor
        item.templateId === 3775 || // kiropraktor
        item.templateId === 3769) // psykolog
    );
  });
  return treatmentProviderFunctions.length > 0;
}

function isTreatmentHelper(e: EmployeeViewModel, functions: FunctionViewModel[]): boolean {
  const treatmentHelperFunctions = functions.filter(function (item) {
    return (
      item.employees.includes(e.uuid) &&
      (item.templateId === 171 || // akuttrom/skifterom
        item.templateId === 174 || // laboratorium
        item.templateId === 168 || // pasientmottak
        item.templateId === 7079) // tannhelsesekretær/assistent
    );
  });
  return treatmentHelperFunctions.length > 0;
}

/**
 * Takes time in string format (hh:mm)
 * Returns a number of minutes
 */
export function timeInMinutes(time: string): number {
  return Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]);
}

/**
 * Takes start and end time in string format (hh:mm)
 * Returns the number of minutes between
 */
function durationInMinutes(start: string, end: string): number {
  if (start && end) {
    return timeInMinutes(end) - timeInMinutes(start);
  }
  return 0;
}

/**
 * Takes start and end time in minutes for periods a and b
 * Returns the number of minutes periods overlap
 */
export function overlap(aStart: number, aEnd: number, bStart: number, bEnd: number) {
  if (bStart < aEnd && bEnd > aStart) {
    let start = aStart;
    if (bStart > aStart) {
      start = bStart;
    }
    let end = aEnd;
    if (bEnd < aEnd) {
      end = bEnd;
    }
    return end - start;
  }
  return 0;
}

/**
 * Compares a list of periods to a single period,
 * returns true if the single period does not overlap with any period in the list
 */
export function vacant(periods: PeriodWithMinutes[], period: PeriodWithMinutes) {
  let result = true;
  if (periods.length) {
    periods.forEach(function (item) {
      if (period.startMinutes < item.endMinutes && period.endMinutes > item.startMinutes) {
        result = false;
      }
    });
  }
  return result;
}

/**
 * Sets start and end for a period group corresponding to first start and last end of periods
 */
export function finalizeGroup(group: PeriodGroupWithMinutes) {
  let startMinutes = 1000000;
  let start = '';
  let endMinutes = 0;
  let end = '';
  group.periods.forEach(function (period) {
    if (period.startMinutes < startMinutes) {
      startMinutes = period.startMinutes;
      start = period.start;
    }
    if (period.endMinutes > endMinutes) {
      endMinutes = period.endMinutes;
      end = period.end;
    }
  });
  group.start = start;
  group.startMinutes = startMinutes;
  group.end = end;
  group.endMinutes = endMinutes;
  return group;
}

export function forDayIndex(workScheduleWeek: WorkScheduleWeek, dayIndex: number) {
  switch (dayIndex) {
    case 0:
      return workScheduleWeek.monday;
    case 1:
      return workScheduleWeek.tuesday;
    case 2:
      return workScheduleWeek.wednesday;
    case 3:
      return workScheduleWeek.thursday;
    case 4:
      return workScheduleWeek.friday;
    case 5:
      return workScheduleWeek.saturday;
    case 6:
      return workScheduleWeek.sunday;
    default:
      throw new Error('Illegal index ' + dayIndex);
  }
}

/**
 * Takes a number of weeks in schedule, the current schedule and a day date
 * Returns the week schedule for the day
 */
export function getScheduleForDay(
  weeksInSchedule: number,
  currentSchedule: WorkSchedule,
  day: LocalDate,
): WorkScheduleDay {
  const dayIndex = day.dayOfWeek().valueOf();
  if (weeksInSchedule === 1) {
    return forDayIndex(currentSchedule.workWeeks[0], dayIndex);
  } else {
    const startDate = LocalDate.fromString(currentSchedule.start);
    const startDateWeekStart = startDate.withPreviousOrSame(DayOfWeek.MONDAY);
    const thisDayWeekStart = day.withPreviousOrSame(DayOfWeek.MONDAY);
    const thisWeekCount = startDateWeekStart.until(thisDayWeekStart) / 7 + 1;
    let theRightWeek;
    if (thisWeekCount <= weeksInSchedule) {
      theRightWeek = thisWeekCount;
    } else {
      theRightWeek = thisWeekCount % weeksInSchedule;
    }
    if (theRightWeek === 0) {
      theRightWeek = weeksInSchedule;
    }
    const weekIndex = theRightWeek - 1;
    return forDayIndex(currentSchedule.workWeeks[weekIndex], dayIndex);
  }
}

/**
 * Takes a list of employee groups
 * Returns a list of employees
 */
export function flattenEmployeeGroups(groups: EmployeeForStaffingCalendar[][]) {
  const employees: EmployeeForStaffingCalendar[] = [];
  groups.forEach(function (group) {
    group.forEach(function (employee) {
      employees.push(employee);
    });
  });
  return employees;
}

/**
 * Takes dates for year start and end, and period start and end
 * Returns period dates cropped to year
 */
export function crop(
  yearStartDate: LocalDate,
  yearEndDate: LocalDate,
  periodStartDate: LocalDate,
  periodEndDate: LocalDate,
) {
  let startDate = yearStartDate;
  if (periodStartDate.isAfter(yearStartDate)) {
    startDate = periodStartDate;
  }
  let endDate = yearEndDate;
  if (periodEndDate.isBefore(yearEndDate)) {
    endDate = periodEndDate;
  }
  return {
    startDate: startDate,
    endDate: endDate,
  };
}

/**
 * Takes a year and a list of holidays
 * Returns a list of days in the year with data for display in calendarium
 */
export function getYearDays(year: string, holidays: { [key: string]: string }) {
  const days: YearDay[] = [];
  const firstDay = LocalDate.fromString(year + '-01-01');
  const firstDayNextYear = firstDay.plusYears(1);
  let day = firstDay;
  let startMinutes = 0;
  while (day.isBefore(firstDayNextYear)) {
    let unitStart = false;
    let today = false;
    const date = day.toString();
    if (day.dayOfWeek() === DayOfWeek.MONDAY || day.day() === 1) {
      unitStart = true;
    }
    if (LocalDate.now().isSame(day)) {
      today = true;
    }
    days.push({
      date: date,
      displayDate: day.day(),
      holiday: holidays[date],
      weekday: day.weekDayOneLetter(),
      displayDateFull: day.weekDayThreeLetters() + ' ' + day.day() + '.',
      unitStart: unitStart,
      today: today,
      startMinutes: startMinutes,
      endMinutes: startMinutes + 1440,
    });
    day = day.plusDays(1);
    startMinutes += 1440;
  }
  return days;
}

type PeriodWithSubPeriods = LeavePeriod & {
  subPeriods: {
    start: string;
    startTime?: string;
    end: string;
    endTime?: string;
    type: 'work';
    grade: 100;
    confirmed: true;
    notes: '';
  }[];
};

/**
 * Takes a list of periods and a date for year start and end
 * Returns the list cropped to the year
 */
export function leavePeriodsForYear(p: LeavePeriod[], yearStartDate: LocalDate, yearEndDate: LocalDate) {
  const value = p
    .filter(
      (period) =>
        period.start.startsWith(yearStartDate.year() + '') || period.end.startsWith(yearStartDate.year() + ''),
    )
    .map((period) => {
      const periodStartDate = LocalDate.fromString(period.start);
      const periodEndDate = LocalDate.fromString(period.end);
      const cropped = crop(yearStartDate, yearEndDate, periodStartDate, periodEndDate);
      const pp: PeriodWithSubPeriods = {
        start: cropped.startDate.toString(),
        startTime: period.startTime,
        end: cropped.endDate.toString(),
        endTime: period.endTime,
        type: period.type,
        grade: period.grade,
        days: period.days,
        confirmed: period.confirmed,
        notes: period.notes,
        uuid: period.uuid,
        subPeriods: [],
      };
      // Convert work days in graded leave to periods
      if (period.grade < 100 && period.days && period.days.length) {
        pp.subPeriods = period.days
          .filter(
            (day) =>
              day.leaveStatus === LeavePeriodDayLeaveStatusEnum.Work &&
              LocalDate.fromString(day.date).isSameOrAfter(yearStartDate) &&
              LocalDate.fromString(day.date).isSameOrBefore(yearEndDate),
          )
          .map((day) => ({
            start: day.date,
            startTime: day.start,
            end: day.date,
            endTime: day.end,
            type: 'work',
            grade: 100,
            confirmed: true,
            notes: '',
          }));
      }
      return pp;
    });
  return sortBy(value, [(e) => e.type, (e) => e.start]);
}
interface PeriodForMinutes {
  start: string;
  startTime?: string;
  end: string;
  endTime?: string;
  type?: string;
  grade?: number;
  confirmed: boolean;
  notes: string;
  uuid: string;
  subPeriods?: {
    start: string;
    startTime?: string;
    end: string;
    endTime?: string;
    type?: string;
  }[];
}

/**
 * Takes a list of periods and a date for year start
 * Returns a list of periods with start and end time in minutes from year start
 */
function getPeriodsWithMinutes(periods: PeriodForMinutes[], yearStartDate: LocalDate): PeriodWithMinutes[] {
  const oPeriods = periods.map((period) => {
    const startDate = LocalDate.fromString(period.start);
    let startMinutes = yearStartDate.until(startDate) * 1440;
    if (period.startTime) {
      startMinutes += timeInMinutes(period.startTime);
    }
    const endDate = LocalDate.fromString(period.end);
    let endMinutes = yearStartDate.until(endDate) * 1440;
    if (period.endTime) {
      endMinutes += timeInMinutes(period.endTime);
    } else {
      endMinutes += 1440;
    }
    let subPeriods: SubPeriodWithMinutes[] = [];
    if ('subPeriods' in period && period.subPeriods && period.subPeriods.length) {
      subPeriods = getSubPeriodsWithMinutes(period.subPeriods, yearStartDate);
    }
    return {
      start: period.start,
      end: period.end,
      startMinutes: startMinutes,
      endMinutes: endMinutes,
      type: 'type' in period ? period.type ?? '' : 'plusTime',
      grade: 'grade' in period ? period.grade || 100 : 100,
      confirmed: period.confirmed,
      notes: period.notes,
      id: period.uuid,
      subPeriods: subPeriods,
    };
  });
  return sortBy(oPeriods, [(e) => e.startMinutes]);
}

interface SubPeriodWithMinutes {
  start: string;
  end: string;
  type: string;
  startMinutes: number;
  endMinutes: number;
}

function getSubPeriodsWithMinutes(
  periods: { start: string; startTime?: string; end: string; endTime?: string; type?: string }[],
  yearStartDate: LocalDate,
): SubPeriodWithMinutes[] {
  const oPeriods = periods.map((period) => {
    const startDate = LocalDate.fromString(period.start);
    let startMinutes = yearStartDate.until(startDate) * 1440;
    if (period.startTime) {
      startMinutes += timeInMinutes(period.startTime);
    }
    const endDate = LocalDate.fromString(period.end);
    let endMinutes = yearStartDate.until(endDate) * 1440;
    if (period.endTime) {
      endMinutes += timeInMinutes(period.endTime);
    } else {
      endMinutes += 1440;
    }
    return {
      start: period.start,
      end: period.end,
      startMinutes: startMinutes,
      endMinutes: endMinutes,
      type: 'type' in period ? period.type ?? '' : 'plusTime',
    };
  });
  return sortBy(oPeriods, [(e) => e.startMinutes]);
}

/**
 * Takes a list of periods and a date for year start
 * Returns a list of periods in that year with start and end time in minutes from year start
 */
export function plusTimePeriodsForYear(p: PlusTimePeriod[], yearStartDate: LocalDate): PeriodWithMinutes[] {
  const periodsForYear = p.filter(function (period) {
    return period.start.startsWith(yearStartDate.year() + '') || period.end.startsWith(yearStartDate.year() + '');
  });
  return getPeriodsWithMinutes(periodsForYear, yearStartDate);
}

export type LeavePeriodWithMinutes = LeavePeriod & { startMinutes: number; endMinutes: number };

/**
 * Takes a list of periods and returns a list of groups containing single or overlapping periods.
 * The periods are placed in a minimal number of rows, each row containing non-overlapping periods.
 * Groups and periods are given properties for start and end time
 * represented by the number of minutes from year start
 */
export function createPeriodGroupsWithMinutes(periods: PeriodWithSubPeriods[], yearStartDate: LocalDate) {
  // set start and end time in minutes
  const periodsWithMinutes: PeriodWithMinutes[] = getPeriodsWithMinutes(periods, yearStartDate);

  // collect single or overlapping periods in groups
  let prevEndMinutes = 0;
  const groups: PeriodGroupWithMinutes[] = [];
  let group: PeriodGroupWithMinutes = {
    type: '',
    start: '',
    end: '',
    startMinutes: 0,
    endMinutes: 0,
    periods: [],
  };
  periodsWithMinutes.forEach((period, index) => {
    // hvis perioden starter før den forrige sluttet: legg perioden til i gruppen
    if (period.startMinutes < prevEndMinutes) {
      group.periods.push(period);
      // hvis perioden starter etter at den forrige sluttet:
      // legg eventuelt til den akkumulerte gruppen på listen og lag en ny gruppe med perioden
    } else {
      if (group.periods.length) {
        groups.push(finalizeGroup(group));
      }
      group = {
        type: period.type,
        start: period.start,
        end: period.end,
        startMinutes: period.startMinutes,
        endMinutes: period.endMinutes,
        periods: [period],
      };
    }
    if (period.endMinutes > prevEndMinutes) {
      prevEndMinutes = period.endMinutes;
    }
    // hvis dette er den siste perioden og gruppen har innhold: legg til gruppen på listen
    if (index === periodsWithMinutes.length - 1 && group.periods.length) {
      groups.push(finalizeGroup(group));
    }
  });
  // fordel periodene i rader
  const rowGroups: PeriodRowGroup[] = groups.map((g) => {
    const rows: PeriodWithMinutes[][] = [[]];
    g.periods.forEach(function (period) {
      let placed = false;
      rows.forEach(function (r) {
        if (!placed && vacant(r, period)) {
          r.push(period);
          placed = true;
        }
      });
      if (!placed) {
        rows.push([period]);
      }
    });
    return {
      startMinutes: g.startMinutes,
      endMinutes: g.endMinutes,
      rows: rows,
    };
  });
  return rowGroups;
}

/**
 * Returns current schedule (or default schedule)
 */
function getCurrentSchedule(day: LocalDate, employee: EmployeeForStaffingCalendar): WorkSchedule {
  const schedules = orderBy(employee.workSchedules, [(e) => e.start], ['desc']);
  let currentSchedule: WorkSchedule = defaultWorkSchedule;
  for (const item of schedules) {
    if (!item.start || LocalDate.fromString(item.start).isSameOrBefore(day)) {
      currentSchedule = item;
      break;
    }
  }
  if (employee.uuid === '47f37665-7d88-4932-8d71-6f629f0674de') {
    console.log(day.toString(), currentSchedule, employee);
  }
  return currentSchedule;
}

/**
 * Returns schedule exception for day if it exists
 */
function getScheduleException(
  day: LocalDate,
  employee: EmployeeForStaffingCalendar,
): WorkScheduleException | undefined {
  if (day && employee.workScheduleExceptions && employee.workScheduleExceptions.length) {
    const exception = employee.workScheduleExceptions.filter((ex) => {
      return day.isSame(LocalDate.fromString(ex.date));
    });
    if (exception.length) {
      return exception[0];
    }
    return undefined;
  }
  return undefined;
}

/**
 * Takes a day date, an employee and a list of holidays
 * Returns the number of work hours and work start and end time
 */
export function getScheduleDayData(day: LocalDate, employee: EmployeeForStaffingCalendar): ScheduleDayData {
  const scheduleDayData: ScheduleDayData = {
    workHours: 0,
    start: '',
    end: '',
    holidays: true,
    exception: false,
    exceptionWork: false,
    scheduleStart: '',
    scheduleEnd: '',
  };
  let workMinutes = 0;
  let breakMinutes = 0;

  // FIND CURRENT SCHEDULE
  const currentSchedule = getCurrentSchedule(day, employee);

  scheduleDayData.holidays = currentSchedule.holidaysAreTimeOff;
  if (currentSchedule.restPeriod === WorkScheduleRestPeriodEnum.WithoutPay) {
    breakMinutes = 30;
  }
  // FIND CURRENT DAY IN SCHEDULE
  const weeksInSchedule = currentSchedule.workWeeks.length;
  const scheduleDay: WorkScheduleDay = getScheduleForDay(weeksInSchedule, currentSchedule, day);
  if (scheduleDay === undefined) {
    console.log('sss');
  }
  if (!scheduleDay.workDay) {
    workMinutes = 0;
  } else {
    if (
      !currentSchedule.workHoursDefinedPerDay &&
      currentSchedule.workDayStartTime !== undefined &&
      currentSchedule.workDayEndTime !== undefined
    ) {
      scheduleDayData.start = currentSchedule.workDayStartTime;
      scheduleDayData.scheduleStart = currentSchedule.workDayStartTime;
      scheduleDayData.end = currentSchedule.workDayEndTime;
      scheduleDayData.scheduleEnd = currentSchedule.workDayEndTime;
      workMinutes = durationInMinutes(currentSchedule.workDayStartTime, currentSchedule.workDayEndTime);
    } else if (scheduleDay.start && scheduleDay.end) {
      scheduleDayData.start = scheduleDay.start;
      scheduleDayData.scheduleStart = scheduleDay.start;
      scheduleDayData.end = scheduleDay.end;
      scheduleDayData.scheduleEnd = scheduleDay.end;
      workMinutes = durationInMinutes(scheduleDay.start, scheduleDay.end);
    } else {
      throw new Error('Illegal state (E422)');
    }
  }
  if (workMinutes < 30) {
    breakMinutes = 0;
  }
  scheduleDayData.workHours = (workMinutes - breakMinutes) / 60;
  if (scheduleDayData.holidays && day.toString() in _holidaysForStaffingCalendar) {
    scheduleDayData.workHours = 0;
    scheduleDayData.start = '';
    scheduleDayData.scheduleStart = '';
    scheduleDayData.end = '';
    scheduleDayData.scheduleEnd = '';
  }

  // CHECK FOR SCHEDULE EXCEPTION
  const exception = getScheduleException(day, employee);
  if (exception !== undefined && exception.start && exception.end) {
    scheduleDayData.exception = true;
    // Set start and end for exception
    scheduleDayData.start = exception.start;
    scheduleDayData.end = exception.end;
    if (exception.work) {
      scheduleDayData.exceptionWork = true;
      // Get minutes for exception
      workMinutes = durationInMinutes(exception.start, exception.end);
      scheduleDayData.workHours = (workMinutes - breakMinutes) / 60;
    } else {
      scheduleDayData.workHours = 0;
    }
  }
  return scheduleDayData;
}

/**
 * Takes a list of periods, a list of period type names and a day
 * Returns a list of minute ranges (represented by start minutes and end minutes) for periods that matches types and day
 */
function periodMinutesForDay(periods, types, confirmed, day) {
  const result: { start: number; end: number }[] = [];
  const validPeriods = periods.filter((period) => {
    if (period.confirmed || !confirmed) {
      return period;
    }
  });
  validPeriods.forEach((period) => {
    const startDate = LocalDate.fromString(period.start);
    const endDate = LocalDate.fromString(period.end);
    if (types.includes(period.type) && startDate.isSameOrBefore(day) && endDate.isSameOrAfter(day)) {
      let start = 0;
      if (startDate.isSame(day) && period.startTime) {
        start = timeInMinutes(period.startTime);
      }
      let end = 1440;
      if (endDate.isSame(day) && period.endTime) {
        end = timeInMinutes(period.endTime);
      }
      result.push({
        start: start,
        end: end,
      });
    }
  });
  return result;
}

/**
 * Takes two lists of ranges, each range represented by a start and end number
 * Returns a list of ranges representing the first ranges minus the last ranges
 */
export function rangesMinusRanges(ra, rb) {
  const rangesMinusRange = (ra, rb) => {
    const result: { start: string; end: string }[] = [];
    ra.forEach((a) => {
      if (rb.end <= a.start || rb.start >= a.end) {
        result.push(a);
      } else if (rb.start > a.start && rb.end < a.end) {
        result.push({ start: a.start, end: rb.start });
        result.push({ start: rb.end, end: a.end });
      } else if (rb.start <= a.start && rb.end > a.start && rb.end < a.end) {
        result.push({
          start: rb.end,
          end: a.end,
        });
      } else if (rb.start > a.start && rb.start < a.end && rb.end >= a.end) {
        result.push({
          start: a.start,
          end: rb.start,
        });
      }
    });
    return result;
  };
  rb.forEach((b) => {
    ra = rangesMinusRange(ra, b);
  });
  return ra;
}

export function summarizeRanges(ranges) {
  let result = 0;
  ranges.forEach((range) => {
    result += range.end - range.start;
  });
  return result;
}

export function validPlusTimeMinutes(
  plusTimeStartMinutes,
  plusTimeEndMinutes,
  workStartMinutes,
  workEndMinutes,
  leavePeriods,
  day,
) {
  const offPeriodsInMinutes = periodMinutesForDay(leavePeriods, leaveTypesThatAllowPlusTime, true, day);
  const workPeriodsInMinutes = [
    {
      start: workStartMinutes,
      end: workEndMinutes,
    },
  ];
  const workPeriodsNotOnLeave = rangesMinusRanges(workPeriodsInMinutes, offPeriodsInMinutes);
  const validPlusTimeRanges = rangesMinusRanges(
    [{ start: plusTimeStartMinutes, end: plusTimeEndMinutes }],
    workPeriodsNotOnLeave,
  );
  return summarizeRanges(validPlusTimeRanges);
}

/**
 * Returns a timekeeping period with data needed for display as a list entry
 */
export function timekeepingPeriod(
  type: string,
  period: LeavePeriod | PlusTimePeriod,
  employee: EmployeeForStaffingCalendar,
  holidays: { [p: string]: string },
): TimekeepingPeriod {
  const startDate = LocalDate.fromString(period.start);
  const endDate = LocalDate.fromString(period.end);
  let startTime = '';
  let registeredStartTimeInMinutes = 0;
  if (period.startTime) {
    startTime = period.startTime;
    registeredStartTimeInMinutes = timeInMinutes(period.startTime);
  }
  let endTime = '';
  let registeredEndTimeInMinutes = 1440;
  if (period.endTime) {
    endTime = period.endTime;
    registeredEndTimeInMinutes = timeInMinutes(period.endTime);
  }
  let unconfirmedText = '';
  let prefix = '';
  let minutes = 0;
  LocalDate.dates(startDate, endDate.plusDays(1)).forEach(function (day) {
    let dayMinutes = 0;
    const scheduleDayData = getScheduleDayData(day, employee);
    const isEffectiveHoliday = scheduleDayData.holidays && holidays[day.toString()];
    let periodDayStartMinutes = registeredStartTimeInMinutes;
    if (day.isAfter(startDate)) {
      periodDayStartMinutes = 0;
    }
    let periodDayEndMinutes = registeredEndTimeInMinutes;
    if (day.isBefore(endDate)) {
      periodDayEndMinutes = 1440;
    }
    const workStartMinutes = timeInMinutes(scheduleDayData.start);
    const workEndMinutes = timeInMinutes(scheduleDayData.end);
    if (type === 'timeOff' && scheduleDayData.workHours > 0 && !isEffectiveHoliday) {
      let leaveStartMinutes = periodDayStartMinutes;
      let leaveEndMinutes = periodDayEndMinutes;
      if (periodDayStartMinutes <= workStartMinutes) {
        leaveStartMinutes = workStartMinutes;
      }
      if (workEndMinutes <= periodDayEndMinutes) {
        leaveEndMinutes = workEndMinutes;
      }
      dayMinutes = leaveEndMinutes - leaveStartMinutes;
      if (dayMinutes > scheduleDayData.workHours * 60) {
        dayMinutes = scheduleDayData.workHours * 60;
      }
    }
    if (type === 'plusTime') {
      if (scheduleDayData.workHours > 0 && !isEffectiveHoliday) {
        minutes = validPlusTimeMinutes(
          periodDayStartMinutes,
          periodDayEndMinutes,
          workStartMinutes,
          workEndMinutes,
          employee.leavePeriods,
          day,
        );
      } else {
        dayMinutes = periodDayEndMinutes - periodDayStartMinutes;
      }
    }
    minutes += dayMinutes;
  });
  if (minutes > 0) {
    if (type === 'timeOff') {
      prefix = '- ';
    }
    if (type === 'plusTime') {
      prefix = '+ ';
    }
  }
  if (!period.confirmed) {
    unconfirmedText = ' (ubekreftet)';
  }
  return {
    type: type,
    confirmed: period.confirmed,
    start: period.start,
    startTime: startTime,
    end: period.end,
    endTime: endTime,
    timeDisplay: periodTimeDisplayText(startDate, startTime, endDate, endTime, true) + unconfirmedText,
    minutes: minutes,
    hoursDisplay: prefix + (minutes / 60).toFixed(2).replace('.', ',') + ' t',
    notes: period.notes,
    id: period.uuid,
  };
}

/**
 * Takes an employee and a list of holidays
 * Returns a list of time keeping periods with data for display
 */
export function timekeepingPeriods(
  employees: EmployeeForStaffingCalendar[],
  employeeUuid: string,
  holidays: { [p: string]: string },
) {
  if (employees && employeeUuid) {
    const timeKeepingStartDate = LocalDate.fromString('2021-01-01');
    const timekeepingPeriods: TimekeepingPeriod[] = [];
    let timeOffPeriods: LeavePeriod[] = [];
    let plusTimePeriods: PlusTimePeriod[] = [];
    const employee = employees.filter((item) => {
      return item.uuid === employeeUuid;
    })[0];
    if (employee === undefined) {
      return [];
    }
    if (employee.plusTimePeriods) {
      plusTimePeriods = employee.plusTimePeriods.filter((item) => {
        const start = LocalDate.fromString(item.start);
        const end = LocalDate.fromString(item.end);
        return start.isSameOrAfter(timeKeepingStartDate) && end.isSameOrAfter(timeKeepingStartDate);
      });
    }
    const leavePeriods = employee.leavePeriods;
    if (leavePeriods.length) {
      timeOffPeriods = leavePeriods.filter((item) => {
        const start = LocalDate.fromString(item.start);
        const end = LocalDate.fromString(item.end);
        return (
          item.type === 'timeOff' &&
          start.isSameOrAfter(timeKeepingStartDate) &&
          end.isSameOrAfter(timeKeepingStartDate)
        );
      });
    }
    timeOffPeriods.forEach((period) => {
      timekeepingPeriods.push(timekeepingPeriod('timeOff', period, employee, holidays));
    });
    plusTimePeriods.forEach((period) => {
      timekeepingPeriods.push(timekeepingPeriod('plusTime', period, employee, holidays));
    });
    if (employee.timekeepingCorrections) {
      employee.timekeepingCorrections.forEach(function (correction) {
        let type = 'correction';
        let prefix = '- ';
        if (correction.correctionType === TimekeepingCorrectionCorrectionTypeEnum.Plus) {
          type = 'correctionPlus';
          prefix = '+ ';
        }
        const hoursDisplay = prefix + correction.hours.toFixed(2).replace('.', ',') + ' t';
        timekeepingPeriods.push({
          type: type,
          confirmed: true,
          start: correction.date,
          startTime: '',
          end: correction.date,
          endTime: '',
          timeDisplay: LocalDate.fromString(correction.date).toStringForDisplayWithDayOfWeekAndYear() + ': Avregning',
          minutes: correction.hours * 60,
          hoursDisplay: hoursDisplay,
          notes: correction.notes,
          id: correction.uuid,
        });
      });
    }
    return orderBy(timekeepingPeriods, [(e) => e.start], ['desc']);
  }
  return [];
}

/**
 * Takes a start and end time for a period
 * Returns a display text representing the period
 */
export function periodTimeDisplayText(
  startDate: LocalDate,
  startTime: string,
  endDate: LocalDate,
  endTime: string,
  yearIfOther: boolean,
): string {
  let startYear = '';
  let endYear = '';
  if (yearIfOther) {
    const thisYear = LocalDate.now().toString().slice(0, 4);
    const periodStartYear = startDate.toString().slice(0, 4);
    const periodEndYear = endDate.toString().slice(0, 4);
    if (thisYear !== periodStartYear || thisYear !== periodEndYear) {
      startYear = ' ' + periodStartYear;
      if (periodStartYear !== periodEndYear) {
        endYear = ' ' + periodEndYear;
      }
    }
  }
  let startTimeText = '';
  if (startTime && (startTime !== '00:00' || (startDate.isSame(endDate) && endTime !== '00:00'))) {
    startTimeText = startTime.replace(':', '.');
  }
  let endTimeText = '';
  if (endTime) {
    endTimeText = endTime.replace(':', '.');
  }
  if (startDate.isSame(endDate)) {
    let divider = ' - ';
    if (!startTimeText && !endTimeText) {
      divider = '';
    }
    return startDate.toStringForDisplayWithDayOfWeek() + startYear + ' ' + startTimeText + divider + endTimeText;
  }
  return (
    startDate.toStringForDisplayWithDayOfWeek() +
    startYear +
    ' ' +
    startTimeText +
    ' - ' +
    endDate.toStringForDisplayWithDayOfWeek() +
    endYear +
    ' ' +
    endTimeText
  );
}

/**
 * Oversetter fra ISO dato/tid til forenklet lokal dato tid: '2020-01-01 12:30'
 * @param t
 */
function convertToDateTimeString(t: string): string {
  const s = t.split('T');
  return s[0] + ' ' + s[1].substring(0, 5);
}

export const staffingCalendarYearOptions = calculateStaffingCalendarYearOptions();

export const staffingCalendarAccessList = createSelector(getOrganization, (organization): string[] => {
  if (organization === undefined) return [];
  return organization.staffingCalendarAccessList;
});

export const currentUserHasStaffingCalendarAccess = createSelector(
  currentEmployeeUuid,
  staffingCalendarAccessList,
  function (currentEmployeeUuid, accessList) {
    if (currentEmployeeUuid === undefined) return false;
    return accessList.includes(currentEmployeeUuid);
  },
);

export function calendariumMonths(year: string) {
  const months: { name: string; count: number }[] = [];
  let i = 0;
  while (i < 12) {
    const month = LocalDate.fromString(year + '-01-01').plusMonths(i);
    months.push({
      name: month.nameOfMonth(),
      count: month.daysInMonth(),
    });
    i++;
  }
  return months;
}

export function calendariumWeeks(year: string) {
  const weeks: { name: string; count: number }[] = [];
  let day = LocalDate.fromString(year + '-01-01');
  while (day.year() === Number(year)) {
    const week = day.isoWeek();
    const count = day.daysOfWeekWithinYear();
    let name = '';
    if (count > 2) {
      name = 'uke ' + week;
    }
    weeks.push({
      name: name,
      count: count,
    });
    day = day.plusWeeks(1);
  }
  return weeks;
}

export function calendariumDays(year: string) {
  return getYearDays(year, _holidaysForStaffingCalendar);
}

export const employeesForStaffingCalendar = createSelector(
  employeesNotDeleted,
  functionsNotDeleted,
  employeesShortNames,
  tasksNotDeleted,
  // TODO  eventsNotDeleted,
  (employees, functions, employeesShortNames, tasks): EmployeeForStaffingCalendar[] => {
    const m = employees
      .filter((e) => {
        return e.status !== 'TERMINATED' && e.isConfirmedEntity;
      })
      .map((e) => {
        return {
          uuid: e.uuid,
          firstName: e.firstName,
          lastName: e.lastName,
          name: e.name,
          displayName: displayNameForEmployee(employeesShortNames, e),
          staffGroup: e.staffGroup || EmployeeViewModelStaffGroupEnum.G1,
          treatmentProvider: isTreatmentProvider(e, functions),
          treatmentHelper: isTreatmentHelper(e, functions),
          workSchedules: e.workSchedules,
          workScheduleExceptions: e.workScheduleExceptions,
          leavePeriods: e.leavePeriods,
          plusTimePeriods: e.plusTimePeriods,
          timekeepingCorrections: e.timekeepingCorrections,
          gender: e.gender,
          tasksLookup: (date: string) => {
            const d = LocalDate.fromString(date);
            return calculateTasksForEmployeeDate(e, d, functions, tasks);
          },
        };
      });

    return _sortEmployees(m, true);
  },
);

function calculateTasksForEmployeeDate(
  employee: EmployeeViewModelWithName,
  localDate: LocalDate,
  functions: FunctionViewModel[],
  tasks: TaskViewModel[],
): StaffingEmployeeDayTask[] {
  const employeeFunctions = functions.filter((f) => f.employees.includes(employee.uuid)).map((item) => item.uuid);
  const until = localDate.plusDays(1);
  let t: StaffingEmployeeDayTask[] = [];
  tasks.forEach(function (task) {
    if (employeeFunctions.includes(task.functionUuid) && task.rrule && !task.locked) {
      const rrule = RecurrenceRule.fromString(task.rrule);
      const instances = rrule.between(localDate, until);
      const dayTask = task.rrule.indexOf('BYHOUR') < 0;
      instances.forEach(function (instance) {
        let hours = '';
        if (!dayTask) {
          hours = rrule.formattedTime('HH:mm', '');
        }
        t.push({
          uuid: task.uuid,
          name: displayName(task.name),
          functionUuid: task.functionUuid,
          time: instance,
          dayTask: dayTask,
          hours: hours,
        });
      });
    }
  });
  t = sortBy(t, [(item) => item.time.toEpochDay()]);

  return t;
}

export const employeeGroups = createSelector(employeesForStaffingCalendar, function (employees) {
  const employeeGroup1: EmployeeForStaffingCalendar[] = [];
  const employeeGroup2: EmployeeForStaffingCalendar[] = [];
  const employeeGroup3: EmployeeForStaffingCalendar[] = [];
  employees.forEach(function (employee) {
    if (employee.staffGroup) {
      if (employee.staffGroup === EmployeeViewModelStaffGroupEnum.G1) {
        employeeGroup1.push(employee);
      }
      if (employee.staffGroup === EmployeeViewModelStaffGroupEnum.G2) {
        employeeGroup2.push(employee);
      }
      if (employee.staffGroup === EmployeeViewModelStaffGroupEnum.G3) {
        employeeGroup3.push(employee);
      }
    } else {
      if (employee.treatmentProvider) {
        employeeGroup1.push(employee);
      } else if (employee.treatmentHelper) {
        employeeGroup2.push(employee);
      } else {
        employeeGroup3.push(employee);
      }
    }
  });
  return [employeeGroup1, employeeGroup2, employeeGroup3];
});

export const timekeepingPeriodsForCurrentEmployee = createSelector(
  employeesForStaffingCalendar,
  currentEmployeeUuid,
  holidaysForStaffingCalendar,
  function (employees, employeeUuid, holidays) {
    if (employees && employeeUuid) {
      return timekeepingPeriods(employees, employeeUuid, holidays);
    }
    return [];
  },
);

export const employeesWithTimekeepingPeriods = createSelector(
  employeesForStaffingCalendar,
  holidaysForStaffingCalendar,
  function (employees, holidays) {
    const result: EmployeeWithTimekeepingPeriods[] = [];
    if (employees) {
      employees = sortBy(employees, (e) => e.name);
      employees.forEach((employee) => {
        const periods = timekeepingPeriods(employees, employee.uuid, holidays);
        result.push({
          uuid: employee.uuid,
          name: employee.name,
          periods: periods,
          forStaffing: employee,
        });
      });
    }
    return result;
  },
);

/**
 * Foreløpig er denne listen kun et eller null element. Hvordan dele med flere har noen uavklarte forhold
 */
export const sharedVacations = createSelector(getOrganization, (organization): SharedVacation[] => {
  if (organization === undefined) return [];
  if (organization.vacationSummaryShared && organization.vacationSummaryRecipient) {
    return [
      {
        email: organization.vacationSummaryRecipient,
        time: convertToDateTimeString(organization.vacationSummaryShared),
      },
    ];
  }
  return [];
});

export const defaultSelectedEmployeesForShareVacation = createSelector(
  getOrganization,
  employeesNotDeleted,
  (organization, employees): string[] => {
    if (organization === undefined) return [];
    if (organization.vacationSummaryEmployees.length === 0) {
      return employees
        .filter((item) => item.profession === 'Lege')
        .filter((item) => item.status !== 'TERMINATED')
        .map((item) => item.uuid);
    }
    return organization.vacationSummaryEmployees;
  },
);

export const defaultNotesForShareVacation = createSelector(getOrganization, (organization): string => {
  if (organization === undefined) return '';
  return organization.vacationSummaryNotes;
});

export function employeeCurrentSchedule(workSchedules: WorkSchedule[], date: LocalDate): WorkSchedule {
  let currentSchedule: WorkSchedule = defaultWorkSchedule;
  const validWorkSchedules = workSchedules.filter((schedule) =>
    LocalDate.fromString(schedule.start).isSameOrBefore(date),
  );

  if (validWorkSchedules.length > 0) {
    const orderedWorkSchedules = sortBy(validWorkSchedules, (e) => e.start);
    currentSchedule = orderedWorkSchedules[orderedWorkSchedules.length - 1];
  }

  const normalizeWorkDay = (d: WorkScheduleDay): WorkScheduleDay => {
    if (d.workDay) {
      return {
        workDay: true,
        start: currentSchedule.workDayStartTime || '08:00',
        end: currentSchedule.workDayEndTime || '16:00',
      };
    } else {
      return {
        workDay: false,
      };
    }
  };

  return {
    ...currentSchedule,
    workWeeks: currentSchedule.workHoursDefinedPerDay
      ? currentSchedule.workWeeks
      : currentSchedule.workWeeks.map((w) => {
          return {
            monday: normalizeWorkDay(w.monday),
            tuesday: normalizeWorkDay(w.tuesday),
            wednesday: normalizeWorkDay(w.wednesday),
            thursday: normalizeWorkDay(w.thursday),
            friday: normalizeWorkDay(w.friday),
            saturday: normalizeWorkDay(w.saturday),
            sunday: normalizeWorkDay(w.sunday),
          };
        }),
  };
}

export function calculateScheduledWorkDay(workSchedules, day): WorkDay {
  const date = LocalDate.fromString(day);
  const currentSchedule = employeeCurrentSchedule(workSchedules, date);
  if (currentSchedule.holidaysAreTimeOff && findHolidayName(day) !== '') {
    return { workDay: false };
  }
  const weeksInSchedule = currentSchedule.workWeeks.length;
  const scheduleDay: WorkScheduleDay = getScheduleForDay(weeksInSchedule, currentSchedule, date);
  if (scheduleDay.workDay) {
    const start = scheduleDay.start || '00:00';
    const end = scheduleDay.end || '00:00';
    let workMinutes = timeInMinutes(end) - timeInMinutes(start);
    if (currentSchedule.restPeriod === 'WITHOUT_PAY') {
      workMinutes -= 30;
    }
    return {
      workDay: true,
      start,
      end,
      workMinutes,
    };
  } else {
    return {
      workDay: false,
      workMinutes: 0,
    };
  }
}

export function calculateActualWorkDay(employee, day, workScheduleException): WorkDayWithException {
  const exception = workScheduleException || employee.workScheduleExceptions.find((e) => e.date === day);
  if (exception !== undefined) {
    if (exception.work) {
      return {
        workDay: true,
        start: exception.start || '00:00',
        end: exception.end || '00:00',
        exception: true,
      };
    } else {
      return { workDay: false, exception: true };
    }
  } else {
    return {
      ...calculateScheduledWorkDay(employee.workSchedules, day),
      exception: false,
    };
  }
}

export function getPeriodTimesForDay(period, day) {
  let startTime = period.startTime;
  if (!startTime || LocalDate.fromString(period.start).isBefore(day)) {
    startTime = '00:00';
  }
  let endTime = period.endTime;
  if (!endTime || LocalDate.fromString(period.end).isAfter(day)) {
    endTime = '24:00';
  }
  let unconfirmedText = '';
  if (!period.confirmed) {
    unconfirmedText = ' (ubekreftet)';
  }
  return {
    startTime,
    endTime,
    unconfirmedText,
  };
}

export function getPeriodDayData(employee, period, type, date, workScheduleException) {
  const periodData = getPeriodTimesForDay(period, date);
  let timeDisplay = periodData.startTime + '-' + periodData.endTime + periodData.unconfirmedText;
  if (periodData.startTime === '00:00' && periodData.endTime === '24:00') {
    timeDisplay = 'Hele dagen' + periodData.unconfirmedText;
  }
  let comment = '';
  let workStartInMinutes = 0;
  let workEndInMinutes = 0;
  const actualWorkDay = calculateActualWorkDay(employee, date.toString(), workScheduleException);
  if (actualWorkDay.workDay) {
    workStartInMinutes = timeInMinutes(actualWorkDay.start);
    workEndInMinutes = timeInMinutes(actualWorkDay.end);
  }
  const minutes = timeInMinutes(periodData.endTime) - timeInMinutes(periodData.startTime);
  let validMinutes = 0;
  let validHoursText = '';
  if (type === 'plusTime') {
    const plusTimeHours = Math.round((minutes / 60 + Number.EPSILON) * 100) / 100;
    const validPlusTime = validPlusTimeMinutes(
      timeInMinutes(periodData.startTime),
      timeInMinutes(periodData.endTime),
      workStartInMinutes,
      workEndInMinutes,
      employee.leavePeriods ?? [],
      date,
    );
    const validHours = Math.round((validPlusTime / 60 + Number.EPSILON) * 100) / 100;
    validMinutes = validPlusTime;
    validHoursText = validHours + ' plusstimer';
    if (!periodData.unconfirmedText) {
      timeDisplay += ' (' + validHours + 't)';
    }
    if (plusTimeHours > validHours) {
      comment = 'Plusstid innenfor vanlig arbeidstid telles ikke';
    }
  }
  if (type === 'timeOff') {
    const nonWorkRanges = rangesMinusRanges(
      [{ start: 0, end: 1440 }],
      [{ start: workStartInMinutes, end: workEndInMinutes }],
    );
    const validTimeOffRanges = rangesMinusRanges(
      [{ start: timeInMinutes(periodData.startTime), end: timeInMinutes(periodData.endTime) }],
      nonWorkRanges,
    );
    validMinutes = summarizeRanges(validTimeOffRanges);
    if (actualWorkDay.workMinutes && validMinutes > actualWorkDay.workMinutes) {
      validMinutes = actualWorkDay.workMinutes;
    }
    const validHours = Math.round((validMinutes / 60 + Number.EPSILON) * 100) / 100;
    if (!periodData.unconfirmedText) {
      timeDisplay += ' (' + validHours + 't)';
    }
    if (minutes > validMinutes) {
      comment = 'Avspasering ut over vanlig arbeidstid telles ikke';
    }
  }
  return {
    id: period.uuid,
    type,
    confirmed: period.confirmed,
    timeDisplay,
    notes: period.notes,
    comment: comment,
    item: period,
    minutes,
    validMinutes,
    validHoursText,
  };
}

export function isInvalidPeriod(startDate: string, startTime: string, endDate: string, endTime: string): boolean {
  const startDateObj = LocalDate.fromString(startDate);
  const endDateObj = LocalDate.fromString(endDate);
  return (
    startDateObj.isAfter(endDateObj) ||
    (startDateObj.isSame(endDateObj) &&
      startTime !== 'NONE' &&
      endTime !== 'NONE' &&
      timeInMinutes(endTime) < timeInMinutes(startTime) + 5)
  );
}
