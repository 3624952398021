import { css, html, LitElement, PropertyValueMap, unsafeCSS } from 'lit';

import '../library/fields/d-spinner-robot.js';
import { customElement, property, query } from 'lit/decorators.js';
import '../library/fields/d-expansion.js';
import { createRoot, Root } from 'react-dom/client';
import React, { useState } from 'react';

import styles from '@copilotkit/react-ui/styles.css?inline' assert { type: 'css' };
import { CopilotKit, useCoAgent, useCopilotAction, useCopilotReadable } from '@copilotkit/react-core';
import { CopilotChat, useCopilotChatSuggestions } from '@copilotkit/react-ui';
import { type ApplicationViewModel, LoadedApplicationViewModel } from 'src/layout/application-view-model';
import { EventOccurrenceEditDoneDetail, MeetingOccurrenceEditDoneDetail } from 'src/content/d-content';
import { uuid } from 'src/utilities/text';
import { createComponent } from '@lit/react';

import './account/d-robot-header';
import { DRobotHeader } from 'src/outskirts/account/d-robot-header';

type AgentState = {
  count: number;
  research_question: string;
  report: string;
  resources: any[];
  logs: any[];
};

type CopilotChatProps = {
  model: LoadedApplicationViewModel;
  onGotoPage: (pageId: number) => void;
  onCreateEventOccurrence: (detail: EventOccurrenceEditDoneDetail) => void;
  onUpdateAgenda: (agenda: string) => void;
};

const RobotHeaderReact = createComponent({
  tagName: 'd-robot-header',
  elementClass: DRobotHeader,
  react: React,
  events: {},
});

function CopilotContext({ model, onGotoPage, onCreateEventOccurrence, onUpdateAgenda }: CopilotChatProps) {
  let message = 'Hallo';
  useCopilotReadable({
    description: 'Gjeldende organisasjon',
    value: model.currentPageView.name,
  });
  useCopilotReadable({
    description: 'Dagens dato',
    value: new Date().toISOString(),
  });
  useCopilotReadable({
    description: 'Kollegaer av gjeldende bruker',
    value: model.employeesForShare.map((p) => ({ name: p.name, uuid: p.uuid })),
  });
  useCopilotReadable({
    description: 'Den gjeldende brukeren',
    value: model.currentEmployee,
  });

  let r = '';
  const contentView = model.contentViews[model.contentViews.length - 1];
  message = 'Kontekst ' + (contentView?.type ?? model.currentPageView.name);
  useCopilotReadable({
    description: 'Navnet på det gjeldende siden',
    value: model.currentPageView.name,
  });

  if (contentView?.type === 'contracts') {
    r = contentView.content;
  } else if (contentView?.type === 'meetingOccurrences') {
    r = contentView.meetingAgenda;
    message = 'Kontekst ' + contentView.name + ' (møte)';

    //}
  }
  useCopilotReadable({
    description: 'Navnet på det gjeldende kontekst med type',
    value: message,
  });

  useCopilotReadable({
    description: 'Navnet på det gjeldende møte',
    value: contentView?.type === 'meetingOccurrences' ? contentView.name : '',
  });
  //if (contentView.meetingAgenda === '') {
  useCopilotAction({
    name: 'updateMeetingAgenda',
    description: 'Oppdaterer agendaen for gjeldende møte og lagrer de nye opplysningene',
    parameters: [
      {
        name: 'agenda',
        type: 'string',
        description: 'Teksten for den nye agendaen for møtet',
      },
    ],
    handler({ agenda }) {
      onUpdateAgenda(agenda);
    },
  });
  useCoAgent<AgentState>({
    name: 'agent',
    initialState: {
      count: 768,
      research_question: '',
      report: r,
      resources: [],
      logs: [],
    },
  });

  const [backgroundColor, setBackgroundColor] = useState('#ADD8E6');
  useCopilotAction({
    name: 'setBackgroundColor',
    parameters: [
      {
        name: 'backgroundColor',
        description: 'The background color to set. Make sure to pick nice colors. Always use english name',
      },
    ],
    handler({ backgroundColor }) {
      setBackgroundColor(backgroundColor);
    },
  });

  const targetPages = model.loaded
    ? model.pageMenu.flatMap((g) => g.pages).map((p) => ({ name: p.name, pageId: p.pageId }))
    : [];
  useCopilotReadable({
    description: 'Tilgjengelige sider systemet kan gå til',
    value: targetPages,
  });

  useCopilotAction({
    name: 'gotoPage',
    parameters: [
      {
        name: 'pageId',
        type: 'number',
        description: 'The page to go to',
      },
    ],
    handler({ pageId }) {
      if (targetPages.find((e) => e.pageId === pageId) !== undefined) {
        onGotoPage(pageId);
      }
    },
  });

  useCopilotAction({
    name: 'addTodoItem',
    description:
      'Legger til en oppgave i ukeplanen. Kan være bra å gå til ukeplan etterpå hvis ikke brukeren vil noe annet.',
    parameters: [
      {
        name: 'todoText',
        type: 'string',
        description: 'The text of the todo item to add',
        required: true,
      },
      {
        name: 'todoWhen',
        type: 'string',
        description: 'the iso date of the todo or blank fo asap',
        required: true,
      },
      {
        name: 'todoWho',
        type: 'string',
        description: 'the uuid of the responsible employee',
        required: true,
      },
    ],
    handler: async ({ todoText, todoWhen, todoWho }) => {
      const e: EventOccurrenceEditDoneDetail = {
        uuid: uuid(),
        type: 'eventOccurrences',
        editItem: {
          name: todoText,
          type: 'standard',
          taskUuid: '',
          date: todoWhen !== '' ? todoWhen.substring(0, 10) : '',
          time: '',
          durationMinutes: 0,
          persistent: false,
          assignedToEmployees: todoWho !== '' ? [todoWho] : [],
          assignedToContacts: [],
          assignedToFunction: '',
          reminder: 'NONE',
          notes: '',
          procedures: '',
          assets: [],
          nameConfirmed: false,
          recurrence: '',
          alert: false,
          message: '',
          includeDetailsInAlert: false,
          saveType: 'single',
          existingRecurrences: 'KEEP',
        },
        saveType: 'single',
        alert: false,
        message: '!!',
        includeDetailsInAlert: false,
      };
      onCreateEventOccurrence(e);
    },
  });

  useCopilotChatSuggestions({
    instructions:
      'Hvilken side bør brukeren gå til. Er der noen verktøy som kan hjelpe. Alle forslagene må være på norsk. Lag forslag basert på gjeldende side',
  });

  const style: React.CSSProperties & { [key: string]: any } = {
    '--robot-callout-color': backgroundColor as any, // Explicitly cast to `any`
  };

  return (
    <div style={style}>
      <RobotHeaderReact message={message}></RobotHeaderReact>
    </div>
  );
}

/**
 *
 *
 *
 */
@customElement('d-copilot-viewer')
export class DCopilotViewer extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
        background: white;
        height: 100vh;
      }

      #root {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      #root .copilotKitChat {
        overflow-y: scroll;
      }

      .header {
        position: -webkit-sticky;
        position: sticky;
        top: var(--safe-area-inset-top);
        width: 100%;
        min-height: 42px;
        background: white;
        box-sizing: border-box;
        z-index: 1;
        opacity: 1;
        box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.3);
        transition: all 0.5s;
      }

      .header.no-shadow {
        box-shadow: 0 0 10px hsla(0, 0%, 0%, 0);
      }

      .buttonsWrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 6px;
        transition: all 0.5s 0.5s;
      }

      .buttonsWrapper > div:first-child {
        display: flex;
      }

      .buttonsWrapper > div > div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        cursor: pointer;
        z-index: 3;
      }

      .buttonsWrapper > div > div svg {
        width: 15px;
        height: 15px;
      }

      .buttonsWrapper > div > div[disabled] {
        cursor: default;
        opacity: 0.4;
      }

      .closer {
        width: 42px;
      }

      .helpPrev,
      .helpNext {
        width: 42px;
      }

      .helpPrev {
        transform: scaleX(-1);
      }

      #searchWrapper {
        position: relative;
        margin: 6px 20px 12px 20px;
        width: calc(100% - 40px);
      }

      #helpSearchInput {
        width: 100%;
        -webkit-appearance: none;
        background: hsl(0, 0%, 94%);
        box-shadow: none;
        border: 1px solid hsl(0, 0%, 90%);
        padding: 3px 10px;
        border-radius: 15px;
        font-family: var(--mainSans);
        font-weight: 400;
        font-size: 15px;
        line-height: 160%;
        box-sizing: border-box;
        resize: none;
        color: black;
      }

      #searchWrapper .clearSearch {
        display: none;
        width: 32px;
        height: 32px;
        position: absolute;
        top: 0;
        right: 0;
        background: url(/images/x-thin-black.svg) 50% 50% no-repeat;
        background-size: 20px 20px;
        opacity: 0.4;
        cursor: pointer;
      }

      #searchWrapper .clearSearch:hover {
        opacity: 1;
      }

      #searchWrapper.hasContent .clearSearch {
        display: block;
      }

      .menu {
        padding-bottom: 70px;
        line-height: 140%;
      }

      .menuItem {
        display: block;
        padding: 4px 20px;
        color: var(--themeColor);
        cursor: pointer;
        text-decoration: none;
      }

      :host(:not([touch])) .menuItem:hover {
        color: black;
      }

      .helpHome {
        display: flex;
        align-items: center;
        padding: 0 12px;
        color: var(--themeColor);
        cursor: pointer;
      }

      .buttonsWrapper > div > div:not([disabled]):hover svg rect,
      .buttonsWrapper > div > div:not([disabled]):hover svg polygon {
        fill: black;
      }

      :host(:not([touch])) .helpHome:hover {
        color: black;
      }

      #supportInfo {
        margin: 20px;
        font-family: var(--mainSerif);
      }

      #supportInfo a {
        color: var(--themeColor);
      }

      :host(:not([touch])) #supportInfo a:hover {
        color: black;
      }

      .businessCard {
        display: flex;
        align-items: center;
      }

      .businessCard:before {
        flex: none;
        content: '';
        width: 96px;
        height: 96px;
        margin-right: 12px;
        border-radius: 50%;
        background: silver;
      }

      .businessCard.hege:before {
        background: url(/images/hege.png) 50% 50% no-repeat;
        background-size: contain;
      }

      .businessCard p {
        margin: 0;
        font-size: 16px;
        line-height: 160%;
      }

      #content {
        padding: 0 20px 70px 20px;
        font-family: var(--mainSerif);
        line-height: 160%;
        color: hsl(0, 0%, 20%);
      }

      #content figure,
      #content img {
        margin-top: 1.2em;
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px) !important;
        height: auto;
      }

      #content figure:first-child,
      #content img:first-child {
        margin-top: 0;
      }

      #content img {
        margin-bottom: 1em;
        border-top: 1px solid hsl(1, 0%, 80%);
        border-bottom: 1px solid hsl(1, 0%, 80%);
      }

      #content figure img {
        margin: 0;
        width: 100% !important;
        height: auto;
      }

      #content figcaption {
        font-family: var(--mainSans);
        font-size: 14px;
        line-height: 150%;
        padding-top: 6px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 1em;
      }

      #content p,
      #content ul,
      #content ol {
        font-size: 16px;
        margin: 0.5em 0;
      }

      #content ul,
      #content ol {
        padding-left: 24px;
      }

      #content ul li,
      #content ol li {
        margin-bottom: 0.3em;
      }

      #content li ul,
      #content li ol {
        margin-top: 0.3em;
      }

      #content h1 {
        font-size: 28px;
        line-height: 130%;
        font-weight: 500;
        margin-bottom: 0.5em;
      }

      #content h2 {
        font-size: 20px;
        line-height: 130%;
        font-weight: 500;
        margin-top: 1em;
        margin-bottom: 0.5em;
      }

      #content a {
        color: var(--themeColor);
      }

      #content a.appLink {
        font-family: var(--mainSans);
        font-size: 15.5px;
        font-weight: 500;
      }

      #content strong,
      #content b {
        font-weight: 500;
      }

      body:not(.touch) #content a:hover {
        color: black;
      }

      #content hr {
        border-width: 0;
      }

      #content .attention {
        position: relative;
        margin: 30px 0 20px 15px;
        padding: 12px 20px 6px 20px;
        background: hsl(196, 83%, 90%);
        font-family: var(--mainSans);
        line-height: 160%;
        border-radius: 6px;
      }

      #content .attention p {
        font-size: 15px;
        margin-bottom: 8px;
      }

      #content .attention:before {
        content: '!';
        display: block;
        position: absolute;
        left: -15px;
        top: -15px;
        box-sizing: border-box;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: hsl(196, 83%, 49%);
        padding-top: 3px;
        text-align: center;
        font-size: 26px;
        line-height: 100%;
        font-weight: 700;
        color: white;
      }

      #appContent a[href^="https://trinnvis.no/hjelp"]
      {
        font-weight: 500;
        margin-left: 10px;
        color: var(--themeColor);
      }

      #appContent a[href^="https://trinnvis.no/hjelp"]:before
      {
        content: '? ';
        display: inline-block;
        position: relative;
        top: 1px;
        width: 20px;
        height: 20px;
        top: 1px;
        border-radius: 50%;
        background: var(--themeColor);
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: white;
        text-align: center;
        margin-right: 4px;
        box-sizing: border-box;
      }

      #appContent .outskirts a[href^="https://trinnvis.no/hjelp"]
      {
        margin-left: 0;
      }

      #appContent .outskirts a[href^="https://trinnvis.no/hjelp"]:before
      {
        display: none;
      }

      :host(:not([touch])) #appContent a[href^="https://trinnvis.no/hjelp"]:hover
      {
        color: var(--themeColorDarkerTwo);
      }

      :host(:not([touch])) #appContent .outskirts a[href^="https://trinnvis.no/hjelp"]:hover
      {
        color: black;
        border-bottom-color: black;
      }

      :host(:not([touch])) #appContent a[href^="https://trinnvis.no/hjelp"]:hover:before
      {
        background: var(--themeColorDarkerTwo);
      }

      #content .attention {
        position: relative;
        margin: 30px 0 20px 15px;
        padding: 12px 20px 6px 20px;
        background: hsl(196, 83%, 90%);
        font-family: var(--mainSans);
        line-height: 160%;
        border-radius: 6px;
      }

      #content .attention p {
        font-size: 15px;
        margin-bottom: 8px;
      }

      #content .attention:before {
        content: '!';
        display: block;
        position: absolute;
        left: -15px;
        top: -15px;
        box-sizing: border-box;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: hsl(196, 83%, 49%);
        padding-top: 3px;
        text-align: center;
        font-size: 26px;
        line-height: 100%;
        font-weight: 700;
        color: white;
      }
    `,
    css`
      ${unsafeCSS(styles)}
    `,
  ];

  @query('#root')
  root!: HTMLDivElement;

  @property({ type: Object })
  model!: ApplicationViewModel;

  #reactRoot: Root | undefined = undefined;

  onGotoPage(pageId: number) {
    if (this.model.loaded) {
      this.dispatchEvent(
        new CustomEvent('navigate', {
          bubbles: true,
          composed: true,
          detail: { href: '/account/' + this.model.currentOrganizationId + '/' + pageId },
        }),
      );
    }
  }

  onCreateEventOccurrence(detail: EventOccurrenceEditDoneDetail) {
    if (this.model.loaded) {
      this.dispatchEvent(
        new CustomEvent('content-save-event', {
          bubbles: true,
          composed: true,
          detail: detail,
        }),
      );
    }
  }

  onUpdateAgenda(detail: string) {
    if (this.model.loaded) {
      const c = this.model.contentViews[this.model.contentViews.length - 1];
      if (c && c.type === 'meetingOccurrences') {
        const e: MeetingOccurrenceEditDoneDetail = {
          editItem: {
            newItem: false,
            name: c.name,
            nameConfirmed: true,
            date: c.date,
            time: c.time,
            recurrence: '',
            assignedToEmployees: [...c.assignedToEmployees],
            assignedToContacts: [...c.assignedToContacts],
            reminder: c.reminder,
            accessControl: [...c.accessControl],
            classification: c.classification,
            meetingAgenda: detail,
            meetingResponsibleFunctionUuid: c.meetingResponsibleFunctionUuid,
            meetingResponsibleEmployeeUuid: c.meetingResponsibleEmployeeUuid,
            durationMinutes: c.durationMinutes,
            saveType: 'single',
            meetingUuid: '',
          },
          saveType: 'single',
          type: 'meetingOccurrences',
          uuid: c.uuid,
        };
        this.dispatchEvent(
          new CustomEvent('content-save-meeting-occurrence', {
            bubbles: true,
            composed: true,
            detail: e,
          }),
        );
      }
    }
  }

  updated(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>) {
    if (this.#reactRoot === undefined) {
      this.#reactRoot = createRoot(this.root);
    }

    if (this.model.loaded) {
      this.#reactRoot.render(
        <CopilotKit runtimeUrl="https://8w45t2hfy3.eu-central-1.awsapprunner.com/copilotkit" showDevConsole={false}>
          <CopilotContext
            model={this.model}
            onGotoPage={(pageId) => this.onGotoPage(pageId)}
            onCreateEventOccurrence={(e) => this.onCreateEventOccurrence(e)}
            onUpdateAgenda={(e) => this.onUpdateAgenda(e)}
          ></CopilotContext>
          <CopilotChat
            labels={{
              title: 'Robot',
              initial: 'Hvordan kan jeg hjelpe deg i dag?',
              regenerateResponse: 'Prøv igjen',
              placeholder: 'Skriv en melding ...',
              stopGenerating: 'Stopp',
            }}
            instructions="Du er en ekspert på kvalitetsarbeid og ledelse i små bedrifter ved hjelp av styringssystemet trinnvis. Du skal hjelpe den gjeldende brukeren med oppgaver som helst skal løses i trinnvis. Eventuelt får du finne informasjon i hjelpetekster, artikler og nyheter eller i maldokumentene til systemet. Alle svar skal være på norsk"
          />
        </CopilotKit>,
      );
    }

    super.updated(changedProperties);
  }

  render() {
    return html` <div id="root"></div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-copilot-viewer': DCopilotViewer;
  }
}
