import { LocalDate } from 'src/utilities/local-date.js';
import { createSelector } from '@reduxjs/toolkit';
import { professionsInformationMap } from './professions.js';
import { getOrganization, hasOrganizationNotPending } from './organization.js';
import { employeesNotDeleted } from './organization-employees.js';
import { writeAccess } from './user.js';
import { functionsNotDeleted } from './organization-functions.js';
import type { EmployeeViewModel, FunctionViewModel } from '../api';
import type { OrganizationState, State } from '../types.js';
import { userPropertiesForCurrentOrganization } from 'src/store/selectors/userPropertiesForCurrentOrganization';

function flatMap<T, U>(array: T[], callbackfn: (value: T, index: number, array: T[]) => U[]): U[] {
  return Array.prototype.concat(...array.map(callbackfn));
}

function checkIgnoreRobot(state: State): boolean {
  try {
    if (state.user?.ignoreRobotUntil) {
      const e = LocalDate.fromString(state.today).toString();
      return e < state.user.ignoreRobotUntil;
    }
    return false;
  } catch (e) {
    return false;
  }
}

export function usernameAsPropertySuffix(state: State): string {
  function toCamel(s: string): string {
    return s.replace(/([-_@.][a-z])/gi, function ($1) {
      return $1.toUpperCase().replace('@', '').replace('.', '').replace('-', '').replace('_', '');
    });
  }

  try {
    if (state.username) {
      const d = state.username.toLowerCase();
      const e2 = toCamel(d);
      return e2.slice(0, 1).toLocaleUpperCase() + e2.substring(1);
    }
    return '';
  } catch (e) {
    return '';
  }
}

/**
 * Check if the profession corresponds to the default function.
 *
 * @param employeeProfession
 * @param defaultFunction
 */
function hasDefaultFunction(employeeProfession: string, defaultFunction: string): boolean {
  const element = professionsInformationMap.get(employeeProfession);
  return element?.defaultFunction === defaultFunction;
}

function containsAny<T>(source: T[], target: T[]): boolean {
  return source.some((item) => target.includes(item));
}

const checkProfessionDefaultFunction = function (defaultFunction: string, activityCodes: string[]) {
  return function (organization: OrganizationState, employees: EmployeeViewModel[]): boolean {
    const a = employees
      .filter(function (e) {
        return e.status === 'ACTIVE';
      })
      .filter(function (e) {
        if (e.profession !== undefined) {
          return hasDefaultFunction(e.profession, defaultFunction);
        } else {
          return false;
        }
      });
    const b = containsAny(organization.activityCodes, activityCodes);
    return a.length > 0 && !b;
  };
};

const checkSpecialityDefaultFunction = function (profession: string, speciality: string, activityCodes: string[]) {
  return function (organization: OrganizationState, employees: EmployeeViewModel[]): boolean {
    const a = employees
      .filter(function (e) {
        return e.status === 'ACTIVE';
      })
      .filter(function (e) {
        return e.profession === profession && e.expertise === speciality;
      });
    const b = containsAny(organization.activityCodes, activityCodes);
    return a.length > 0 && !b;
  };
};

const checkProfessionHasDefaultFunction = function (profession: string) {
  return function (employee: EmployeeViewModel, functionViewModels: Map<number, FunctionViewModel>): boolean {
    const n = professionsInformationMap.get(profession);

    return (
      n !== undefined &&
      employee.profession === profession &&
      n.defaultFunctionTemplateId !== undefined &&
      functionViewModels.has(n.defaultFunctionTemplateId) &&
      !functionViewModels.get(n.defaultFunctionTemplateId)?.employees.includes(employee.uuid)
    );
  };
};

const checkSpecialityHasFunction = function (profession: string, speciality: string, functionTemplateId: number) {
  return function (employee: EmployeeViewModel, functionViewModels: Map<number, FunctionViewModel>): boolean {
    return (
      employee.profession === profession &&
      employee.expertise === speciality &&
      functionViewModels.has(functionTemplateId) &&
      !functionViewModels.get(functionTemplateId)?.employees.includes(employee.uuid)
    );
  };
};

export type RobotAlertRuleAction =
  | {
      title: string;
      href: string;
    }
  | {
      title: string;
      href: '';
      action: {
        type: 'ASSIGN_FUNCTION';
        payload: { employeeId: '[employeeUuid]'; templateId: number };
      };
    };

export type RobotAlertRule = {
  id: string;
  message: string;
  actions: RobotAlertRuleAction[];
  check: (organization: OrganizationState, employees: EmployeeViewModel[]) => boolean;
};

export type RobotAlertRuleForEmployee = {
  id: string;
  message: string;
  actions: RobotAlertRuleAction[];
  check: (employee: EmployeeViewModel, functionViewModels: Map<number, FunctionViewModel>) => boolean;
};

const robotAlertRules: RobotAlertRule[] = [
  {
    id: 'activityCodes-lege',
    message:
      'Du har personale registrert med lege som yrke, men legetjeneste er ikke registrert under Tjenestetype.\n' +
      '    Sjekk at du har registrert riktige tjenestetyper, ellers vil viktig innhold mangle i TrinnVis!',
    actions: [
      {
        title: 'Rediger tjenestetype',
        href: '/account/[organizationId]/104?mode=edit',
      },
    ],
    check: checkProfessionDefaultFunction('Lege', ['86.21', '86.22']),
  },
  {
    id: 'activityCodes-fysioterapeut',
    message:
      'Du har personale registrert med fysioterapeut som yrke, men fysioterapitjeneste er ikke registrert under Tjenestetype.\n' +
      '    Sjekk at du har registrert riktige tjenestetyper, ellers vil viktig innhold mangle i TrinnVis!',
    actions: [
      {
        title: 'Rediger tjenestetype',
        href: '/account/[organizationId]/104?mode=edit',
      },
    ],
    check: checkProfessionDefaultFunction('Fysioterapeut', ['86.902']),
  },
  {
    id: 'activityCodes-osteopat',
    message:
      'Du har personale registrert med osteopat som yrke, men osteopati er ikke registrert under Tjenestetype.\n' +
      '    Sjekk at du har registrert riktige tjenestetyper, ellers vil viktig innhold mangle i TrinnVis!',
    actions: [
      {
        title: 'Rediger tjenestetype',
        href: '/account/[organizationId]/104?mode=edit',
      },
    ],
    check: checkProfessionDefaultFunction('Osteopat', ['86.909.03']),
  },
  {
    id: 'activityCodes-kiropraktor',
    message:
      'Du har personale registrert med kiropraktor som yrke, men kiropraktortjeneste er ikke registrert under Tjenestetype.\n' +
      '    Sjekk at du har registrert riktige tjenestetyper, ellers vil viktig innhold mangle i TrinnVis!',
    actions: [
      {
        title: 'Rediger tjenestetype',
        href: '/account/[organizationId]/104?mode=edit',
      },
    ],
    check: checkProfessionDefaultFunction('Kiropraktor', ['86.909.02']),
  },
  {
    id: 'activityCodes-naprapat',
    message:
      'Du har personale registrert med naprapat som yrke, men naprapati er ikke registrert under Tjenestetype.\n' +
      '    Sjekk at du har registrert riktige tjenestetyper, ellers vil viktig innhold mangle i TrinnVis!',
    actions: [
      {
        title: 'Rediger tjenestetype',
        href: '/account/[organizationId]/104?mode=edit',
      },
    ],
    check: checkProfessionDefaultFunction('Naprapat', ['86.909.19']),
  },
  {
    id: 'activityCodes-psykolog',
    message:
      'Du har personale registrert med psykolog som yrke, men psykologtjeneste er ikke registrert under Tjenestetype.\n' +
      '    Sjekk at du har registrert riktige tjenestetyper, ellers vil viktig innhold mangle i TrinnVis!',
    actions: [
      {
        title: 'Rediger tjenestetype',
        href: '/account/[organizationId]/104?mode=edit',
      },
    ],
    check: checkProfessionDefaultFunction('Psykolog', ['86.905']),
  },
  {
    id: 'activityCodes-tannlege',
    message:
      'Du har personale registrert med tannlege som yrke, men tannlegetjeneste er ikke registrert under Tjenestetype.\n' +
      '    Sjekk at du har registrert riktige tjenestetyper, ellers vil viktig innhold mangle i TrinnVis!',
    actions: [
      {
        title: 'Rediger tjenestetype',
        href: '/account/[organizationId]/104?mode=edit',
      },
    ],
    check: checkProfessionDefaultFunction('Tannlege', ['86.23']),
  },
  {
    id: 'activityCodes-manuellterapi',
    message:
      'Du har personale registrert med manuellterapi som spesialitet, men manuellterapi er ikke registrert under Tjenestetype.\n' +
      '    Sjekk at du har registrert riktige tjenestetyper, ellers vil viktig innhold mangle i TrinnVis!',
    actions: [
      {
        title: 'Rediger tjenestetype',
        href: '/account/[organizationId]/104?mode=edit',
      },
    ],
    check: checkSpecialityDefaultFunction('Fysioterapeut', 'Manuellterapi', ['86.902.10']),
  },
];

const robotAlertRulesPerEmployee: RobotAlertRuleForEmployee[] = [
  {
    id: 'missing-function-fysioterapeut',
    message: '[employeeName] er registrert med fysioterapeut som yrke, men har ikke ansvarsområdet Fysioterapeut.',
    actions: [
      {
        title: 'Tildel ansvarsområde',
        href: '',
        action: {
          type: 'ASSIGN_FUNCTION',
          payload: { employeeId: '[employeeUuid]', templateId: 3784 },
        },
      },
    ],
    check: checkProfessionHasDefaultFunction('Fysioterapeut'),
  },
  {
    id: 'missing-function-kiropraktor',
    message: '[employeeName] er registrert med kiropraktor som yrke, men har ikke ansvarsområdet Kiropraktor.',
    actions: [
      {
        title: 'Tildel ansvarsområde',
        href: '',
        action: {
          type: 'ASSIGN_FUNCTION',
          payload: { employeeId: '[employeeUuid]', templateId: 3775 },
        },
      },
    ],
    check: checkProfessionHasDefaultFunction('Kiropraktor'),
  },
  {
    id: 'missing-function-naprapat',
    message: '[employeeName] er registrert med naprapat som yrke, men har ikke funksjonen Naprapat.',
    actions: [
      {
        title: 'Tildel funksjon',
        href: '',
        action: {
          type: 'ASSIGN_FUNCTION',
          payload: { employeeId: '[employeeUuid]', templateId: 11281 },
        },
      },
    ],
    check: checkProfessionHasDefaultFunction('Naprapat'),
  },
  {
    id: 'missing-function-lege',
    message: '[employeeName] er registrert med lege som yrke, men har ikke ansvarsområdet Lege.',
    actions: [
      {
        title: 'Tildel ansvarsområde',
        href: '',
        action: {
          type: 'ASSIGN_FUNCTION',
          payload: { employeeId: '[employeeUuid]', templateId: 3765 },
        },
      },
    ],
    check: checkProfessionHasDefaultFunction('Lege'),
  },
  {
    id: 'missing-function-psykolog',
    message: '[employeeName] er registrert med psykolog som yrke, men har ikke ansvarsområdet Psykolog.',
    actions: [
      {
        title: 'Tildel ansvarsområde',
        href: '',
        action: {
          type: 'ASSIGN_FUNCTION',
          payload: { employeeId: '[employeeUuid]', templateId: 3769 },
        },
      },
    ],
    check: checkProfessionHasDefaultFunction('Psykolog'),
  },
  {
    id: 'missing-function-tannpleier',
    message: '[employeeName] er registrert med tannpleier som yrke, men har ikke ansvarsområdet Tannpleier.',
    actions: [
      {
        title: 'Tildel ansvarsområde',
        href: '',
        action: {
          type: 'ASSIGN_FUNCTION',
          payload: { employeeId: '[employeeUuid]', templateId: 7063 },
        },
      },
    ],
    check: checkProfessionHasDefaultFunction('Tannpleier'),
  },
  {
    id: 'missing-function-tannbehandlingsassistent',
    message:
      '[employeeName] er registrert med tannhelsesekretær som yrke. Skal denne personen ha ansvarsområdet Tannhelsesekretær/assistent?',
    actions: [
      {
        title: 'Tildel ansvarsområde',
        href: '',
        action: {
          type: 'ASSIGN_FUNCTION',
          payload: { employeeId: '[employeeUuid]', templateId: 7079 },
        },
      },
    ],
    check: checkProfessionHasDefaultFunction('Tannhelsesekretær'),
  },
  {
    id: 'missing-function-tannlege',
    message: '[employeeName] er registrert med tannlege som yrke, men har ikke ansvarsområdet Tannlege.',
    actions: [
      {
        title: 'Tildel ansvarsområde',
        href: '',
        action: {
          type: 'ASSIGN_FUNCTION',
          payload: { employeeId: '[employeeUuid]', templateId: 3777 },
        },
      },
    ],
    check: checkProfessionHasDefaultFunction('Tannlege'),
  },
  {
    id: 'missing-function-turnuslege',
    message: '[employeeName] er registrert med turnuslege som yrke, men har ikke ansvarsområdet Turnuslege.',
    actions: [
      {
        title: 'Tildel ansvarsområde',
        href: '',
        action: {
          type: 'ASSIGN_FUNCTION',
          payload: { employeeId: '[employeeUuid]', templateId: 3765 },
        },
      },
    ],
    check: checkProfessionHasDefaultFunction('Turnuslege'),
  },
  {
    id: 'missing-function-lis-1',
    message: '[employeeName] er registrert med LIS 1 som yrke, men har ikke ansvarsområdet Lege.',
    actions: [
      {
        title: 'Tildel ansvarsområde',
        href: '',
        action: {
          type: 'ASSIGN_FUNCTION',
          payload: { employeeId: '[employeeUuid]', templateId: 3765 },
        },
      },
    ],
    check: checkProfessionHasDefaultFunction('LIS 1'),
  },
  {
    id: 'missing-function-manuellterapeut',
    message:
      '[employeeName] er registrert med Manuellterapi som spesialitet, men har ikke ansvarsområdet Manuellterapeut.',
    actions: [
      {
        title: 'Tildel ansvarsområde',
        href: '',
        action: {
          type: 'ASSIGN_FUNCTION',
          payload: { employeeId: '[employeeUuid]', templateId: 7479 },
        },
      },
    ],
    check: checkSpecialityHasFunction('Fysioterapeut', 'Manuellterapi', 7479),
  },
];

export const ignoredRobotAlerts = createSelector([userPropertiesForCurrentOrganization], function (userProperties) {
  return userProperties.ignoredRobotAlerts ?? [];
});

export const functionsByTemplateId = createSelector([functionsNotDeleted], (list) => {
  const r: Map<number, FunctionViewModel> = new Map<number, FunctionViewModel>();
  list.forEach(function (f) {
    if (f.templateId) {
      r.set(f.templateId, f);
    }
  });
  return r;
});

export interface RobotAlertItem {
  id: string;
  message: string;
  actions: RobotAlertRuleAction[];
}

const robotAlerts = createSelector(
  hasOrganizationNotPending,
  getOrganization,
  employeesNotDeleted,
  ignoredRobotAlerts,
  functionsByTemplateId,
  writeAccess,
  function (hasValidOrganization, organization, employees, ignoredRobotAlertItems, functionViewModels, hasWriteAccess) {
    if (!hasValidOrganization) {
      return [];
    }

    if (!hasWriteAccess) {
      return [];
    }

    if (organization === undefined) {
      return [];
    }

    const fixedAlerts: RobotAlertItem[] = [
      {
        id: 'universal-new',
        message: 'Skal du legge til nytt innhold i TrinnVis? Klikk på plusstegnet nede i høyre hjørne på skjermen.',
        actions: [],
      },
    ].filter(function (rule) {
      return ignoredRobotAlertItems.indexOf(rule.id) < 0;
    });

    const r: RobotAlertItem[] = robotAlertRules
      .filter(function (rule) {
        return ignoredRobotAlertItems.indexOf(rule.id) < 0;
      })
      .filter(function (rule) {
        return rule.check(organization, employees);
      })
      .map(function (rule) {
        return {
          id: rule.id,
          message: rule.message,
          actions: rule.actions.map(function (a) {
            return {
              title: a.title,
              href: a.href.replace('[organizationId]', '' + organization.organizationId),
            };
          }),
        };
      });

    const r2: RobotAlertItem[] = flatMap(
      employees.filter(function (e) {
        return e.status === 'ACTIVE';
      }),
      function (e) {
        return robotAlertRulesPerEmployee
          .filter(function (rule) {
            return rule.check(e, functionViewModels);
          })
          .map((rule) => ({
            id: e.uuid + '-' + rule.id,
            message: rule.message.replace('[employeeName]', e.name),
            actions: rule.actions.map(function (a) {
              return {
                title: a.title,
                href: a.href.replace('[organizationId]', '' + organization.organizationId),
                action:
                  'action' in a
                    ? {
                        type: a.action.type,
                        payload: {
                          employeeId: a.action.payload.employeeId.replace('[employeeUuid]', e.uuid),
                          templateId: a.action.payload.templateId,
                        },
                      }
                    : undefined,
              };
            }),
          }));
      },
    ).filter(function (rule) {
      return ignoredRobotAlertItems.indexOf(rule.id) < 0;
    });

    return fixedAlerts.concat(r, r2);
  },
);

export const showRobotAlert = createSelector(
  hasOrganizationNotPending,
  checkIgnoreRobot,
  robotAlerts,
  (hasValidOrganization, ignoreRobot, robotAlertItems) =>
    hasValidOrganization && !ignoreRobot && robotAlertItems.length > 0,
);

export const currentRobotAlert = createSelector(robotAlerts, (robotAlertItems) =>
  robotAlertItems.length > 0 ? robotAlertItems[0] : undefined,
);
