import type { State } from 'src/store/types.js';
import type { IssueView, IssueViewEditItem } from 'src/content/issues/d-issue-view.js';
import {
  currentUserUuid,
  employeeOptions,
  getClassificationText,
  getOrganization,
  isIssueProcessed,
  key,
  maritimeDoctor,
  toIssueViewModel,
} from 'src/store/selectors';
import { BASE_PATH } from 'src/store/api';
import { fetchDraft, pagesAsSelectTagOptions, toAttachmentItems } from 'src/models/factory-utilities.js';
import { assertIsDefined } from 'src/lib';
import { CommonDataEntityView } from 'src/content/entity-content';
import { ApiClient } from 'src/store';

function employeeName(
  availableEmployees: { text: string; value: string }[],
  uuid: string | undefined,
  emptyValue: string = '',
) {
  return availableEmployees.find((e) => e.value === uuid)?.text ?? emptyValue;
}

function getWorkRelatedHealthIssueType(injury: boolean, sickness: boolean) {
  if (injury) {
    if (sickness) {
      return 'Skade og sykdom';
    }
    return 'Skade';
  }
  if (sickness) {
    return 'Sykdom';
  }
  return 'Ikke angitt';
}

export function buildIssueView(
  api: ApiClient,
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): IssueView {
  const organization = getOrganization(state);
  assertIsDefined(organization);
  const item = organization.issuesById[uuid];
  assertIsDefined(item);
  const entity = toIssueViewModel(item);

  return {
    ...commonData,
    /**
     * Report
     */
    reportedDate: entity.reportedDate ?? '',
    reportedBy: entity.reportedBy ?? '',
    reportedByName: employeeName(employeeOptions(state, entity.reportedBy), entity.reportedBy, 'Ukjent'),
    reportedTo: entity.reportedTo ?? '',
    reportedToName: employeeName(employeeOptions(state, entity.reportedTo), entity.reportedTo, 'Ukjent'),
    description: entity.description ?? '',
    eventDate: entity.eventDate ?? '',
    immediateMeasures: entity.immediateMeasures ?? '',
    furtherMeasures: entity.furtherMeasures ?? '',
    /**
     * Processing
     */
    name: entity.name ?? '',
    processedDate: entity.processedDate ?? '',
    processedBy: entity.processedBy ?? '',
    processedByOptions: employeeOptions(state, entity.processedBy),
    processedByName: employeeName(employeeOptions(state, entity.processedBy), entity.processedBy),
    notes: entity.notes ?? '',
    /**
     * Checklist
     */
    personDamagePossibility: entity.personDamagePossibility === true,
    relateToPatient: entity.relateToPatient === true,
    relateToEmployees: entity.relateToEmployees === true,
    workRelatedHealthIssue: entity.workRelatedHealthIssue === true,
    relateToEquipment: entity.relateToEquipment === true,
    relateToRadiation: entity.relateToRadiation === true,
    informationLeakagePossibility: entity.informationLeakagePossibility === true,
    relateToMaritimeHealthCertificate: entity.relateToMaritimeHealthCertificate === true,
    /**
     * Work related health issue
     */
    workRelatedHealthIssueEmployee: entity.workRelatedHealthIssueEmployee ?? '',
    workRelatedHealthIssueEmployeeName: employeeName(
      employeeOptions(state, entity.workRelatedHealthIssueEmployee),
      entity.workRelatedHealthIssueEmployee,
    ),
    workRelatedHealthIssueEmployeeOptions: employeeOptions(state, entity.workRelatedHealthIssueEmployee, true),
    workRelatedHealthIssueDescription: entity.workRelatedHealthIssueDescription ?? '',
    workRelatedInjury: entity.workRelatedInjury === true,
    workRelatedSickness: entity.workRelatedSickness === true,
    workRelatedHealthIssueType: getWorkRelatedHealthIssueType(
      entity.workRelatedInjury === true,
      entity.workRelatedSickness === true,
    ),
    workRelatedInjuryTask: entity.workRelatedInjuryTask ?? '',
    workRelatedInjuryIncidentCause: entity.workRelatedInjuryIncidentCause ?? '',
    workRelatedSicknessCausingTask: entity.workRelatedSicknessCausingTask ?? '',
    workRelatedSicknessCausingTaskLocation: entity.workRelatedSicknessCausingTaskLocation ?? '',
    workRelatedSicknessCausingEnvironment: entity.workRelatedSicknessCausingEnvironment ?? '',
    /**
     * Measures
     */
    requiresMeasures: entity.requiresMeasures,
    identifiedMeasures: entity.identifiedMeasures ?? '',
    implementedMeasure: entity.implementedMeasure,
    measuresSatisfactorily: entity.measuresSatisfactorily,
    /**
     * Evaluation
     */
    evaluated: entity.evaluated, // I.e. 'needs evaluation'
    evaluatedDate: entity.evaluatedDate ?? '',
    evaluatedBy: entity.evaluatedBy ?? '',
    evaluatedByOptions: employeeOptions(state, entity.evaluatedBy),
    evaluatedByName: employeeName(employeeOptions(state, entity.evaluatedBy), entity.evaluatedBy),
    evaluatedOk: entity.evaluatedOk,
    processed: isIssueProcessed(entity),
    /**
     * Meta data and ui resources
     */
    type: 'issues',
    uuid: entity.uuid ?? '',
    pages: (entity.pages ?? []).map((x) => '' + x),
    availablePages: pagesAsSelectTagOptions(state).map((p) => ({ ...p, disabled: p.value === '281' })),
    classification: entity.classification ?? 'NONE',
    accessControl: entity.accessControl ?? [],
    classificationText: getClassificationText(
      entity.classification ?? 'NONE',
      entity.accessControl ?? [],
      commonData.accessControlOptions,
    ),
    maritimeDoctor: maritimeDoctor(state),
    isConfirmedEntity: entity.isConfirmedEntity,
    attachments: toAttachmentItems(api, 'issues', entity.uuid, entity.attachments),
    deleted: entity.deleted === true,
    deletable: true,
    parentHref: currentParent,
    href: currentParent + '/issues/' + uuid,
    pdfHref: BASE_PATH + '/organizations/' + organization.organizationId + '/issues/' + uuid + '.pdf?key=' + key(state),
    helpContent: '',
    currentUserHasAccess:
      entity.classification !== 'CONFIDENTIAL' || (entity.accessControl ?? []).includes(currentUserUuid(state) ?? ''),
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(api, 'issues', uuid, organization.organizationId);
      return command.draft as IssueViewEditItem;
    },
    docsForLinking: [],
  };
}
