/**
 * Koden her kan kanskje flyttes slik at den er en del av d-application.ts eller ligger parallelt med den.
 */
import { ApplicationViewModel, LoadedApplicationViewModel } from 'src/layout/application-view-model.js';
import type { StartTask } from 'src/layout/parts/d-starttask-field.js';
import { currentContentViews } from 'src/models/content/content-view-factory.js';
import { pageView } from 'src/models/pages/page-view.js';
import { sortedByName } from 'src/store/utilities.js';
import { DayOfWeek, LocalDate } from 'src/utilities/local-date.js';
import type { OrganizationState, State } from '../store/types.js';
import { buildCurrentTutorialContent, findAvailableTutorials, isTutorialActive } from 'src/models/tutorials.js';
import type { SettingsItem } from 'src/layout/parts/d-organization-settings.js';
import {
  currentEmployeeAsViewModel,
  currentEmployeeUuid,
  employeesDeleted,
  employeesNotDeleted,
  employeesNotTerminatedCount,
  getEmployeesAsUsers,
  getEmployeesWithAccessForAdministrator,
  isCurrentUserEmployee,
} from 'src/store/selectors/organization-employees.js';
import { getContactsAsUsers, partnersNotDeleted } from 'src/store/selectors/organization-partners.js';
import { getOrganization, getOrganizationId, singleUserVersion } from 'src/store/selectors/organization.js';
import { defaultPageId, getPageGroups, PageGroup } from 'src/store/selectors/pages.js';
import { currentEntityTypeName, getCurrentPath, splitPath } from 'src/store/selectors/path.js';
import { displayedUserName, requiresSecureLogin } from 'src/store/selectors/user.js';
import { functionsNotDeleted, startTasks } from 'src/store/selectors/organization-functions.js';
import { currentRobotAlert, functionsByTemplateId, showRobotAlert } from 'src/store/selectors/robot-alert.js';
import { breadCrumbSelectedName } from 'src/store/selectors/breadcrumbs.js';
import { itemsByTypeAndId, loaded } from 'src/store/selectors/selectors';
import {
  ApiClient,
  createClient,
  currentEmployeeForStaffingCalendar,
  currentUserHasStaffingCalendarAccess,
  currentWeekRemainingTasksAndMeetingsForEmployee,
  getItemDataFromTemplateId,
  getRecipientOptionsForIssues,
} from 'src/store';
import { getDocsForLinking, convertInternalLinks } from 'src/models/internal-linking.js';
import { getFeatureStates, getSpecialTerms } from 'src/store/selectors/features';
import { PageMenuGroup } from 'src/layout/parts/d-page-menu';
import { uiSettings } from 'src/store/selectors/ui';
import { MobileAppViewModel } from 'src/outskirts/account/mobile-app/d-mobile-app';
import { sortBy } from 'lodash';
import { SideContent } from 'src/outskirts/d-side-content';

function getPageMenu(pageGroups: PageGroup[], organizationId: number): PageMenuGroup[] {
  return pageGroups.map((group) => {
    return {
      code: group.code,
      name: group.name,
      pages: group.pages.map((page) => {
        return {
          pageId: page.pageId,
          active: page.active,
          name: page.name,
          href: '/account/' + organizationId + '/' + page.pageId,
          promo: '',
        };
      }),
    };
  });
}

export function getUsersForAccess(state: State) {
  return [
    ...getEmployeesAsUsers(state).map((u) => ({
      name: u.name,
      uuid: u.uuid,
      email: u.email ?? '',
      accessLevel: u.accessLevel?.toString() ?? 'NONE',
      accessExpires: '',
    })),
    ...getContactsAsUsers(state).map((u) => ({
      name: u.name,
      uuid: u.uuid,
      email: u.email ?? '',
      accessLevel: u.accessLevel?.toString() ?? 'NONE',
      partnerName: u.partnerName,
      partnerUuid: u.partnerUuid,
      accessExpires: u.accessExpires ?? '',
    })),
  ];
}

function getSettings(
  state: State,
  currentEmployee,
  currentUserIsOwner: boolean,
  organization: OrganizationState,
): SettingsItem[] {
  const api = createClient();
  const result: SettingsItem[] = [];
  result.push({
    name: 'Brukervalg',
    id: 'profile',
    type: 'profile',
    isEmployee: isCurrentUserEmployee(state),
    user: {
      criiptoAuthDescription: state.user?.criiptoAuthDescription ?? '',
      authDescription: state.user?.authDescription ?? '',
      token: '',
      username: state.user?.username ?? '',
    },
    reminders: currentEmployee?.receiveReminders ?? 'NONE',
    alerts: currentEmployee?.summaries ?? 'NONE',
    features: state.organization?.features ?? [],
    ownerEmail: state.organization?.ownerEmail ?? '',
  });
  if (currentUserIsOwner) {
    result.push({
      name: 'Abonnement',
      id: 'subscription',
      type: 'subscription',
      singleUserVersionAvailable: _singleUserVersionAvailable(state),
      administratorEmployeesList: getEmployeesWithAccessForAdministrator(state).map((a) => ({
        name: a.name,
        email: a.email ?? '',
      })),
      subscription: {
        invoiceAddress: organization?.invoiceAddress ?? '',
        invoiceLocality: organization?.invoiceLocality ?? '',
        invoiceOrganizationNumber: organization?.invoiceOrganizationNumber ?? '',
        invoicePostcode: organization?.invoicePostcode ?? '',
        invoiceReceiver: organization?.invoiceReceiver ?? '',
        invoiceReference: organization?.invoiceReference ?? '',
        invoiceSendMethod: organization?.invoiceSendMethod === 'EHF' ? 'EHF' : 'MANUAL',
        ownerEmail: organization?.ownerEmail ?? '',
        specialTerms: organization?.specialTerms ?? 'NONE',
        singleUser: singleUserVersion(state),
        requiresSecureLogin: requiresSecureLogin(state) ?? false,
      },
      elmaLookup: async (q) => {
        const p = await api.accounts.searchElmaRegistry({ query: q });
        return p.map((x) => ({ value: x.identifier, text: x.identifier + ' ' + x.name }));
      },
    });
  }
  result.push({
    name: 'Brukere',
    id: 'users',
    type: 'users',
    users: getUsersForAccess(state),
    ownerEmail: organization?.ownerEmail ?? '',
    currentUserEmail: state.user?.username ?? '',
  });
  result.push({
    name: 'Organisasjoner',
    id: 'organizations',
    type: 'organizations',
    organizations: sortedByName(state.user?.organizations ?? []),
  });
  return result;
}

function getMobileAppViewModel(state: State): MobileAppViewModel {
  const organization = getOrganization(state);
  if (organization === undefined) {
    throw new Error('Illegal state (E153), organization not found');
  }
  return {
    currentOrganizationId: getOrganizationId(state),
    currentEmployeeUuid: currentEmployeeUuid(state) ?? '',
    currentPath: getCurrentPath(state),
    employeeForStaffing: currentEmployeeForStaffingCalendar(state),
    organizations: sortBy(state.user?.organizations ?? [], [(item) => item.name.toLowerCase()]),
    recipientOptionsForIssues: getRecipientOptionsForIssues(state),
    currentUserHasStaffingAccess: currentUserHasStaffingCalendarAccess(state),
    leavePeriodEditRestriction: organization.leavePeriodEditRestriction,
    eventsCount: currentWeekRemainingTasksAndMeetingsForEmployee(state),
  };
}

function getCurrentSideContent(state: State): SideContent | undefined {
  if (state.currentSideContentTemplateId && state.currentSideContentTemplateId !== 0) {
    const items = itemsByTypeAndId(state);
    if (items) {
      const item = getItemDataFromTemplateId(state.currentSideContentTemplateId + '', items, singleUserVersion(state));
      if (item) {
        let content = item.content;
        const converted = convertInternalLinks(item.content, getDocsForLinking(state));
        if (converted) {
          content = converted;
        }
        return { ...item, content };
      }
    }
  }
  return undefined;
}

export async function loadedApplicationViewModel(api: ApiClient, state: State): Promise<LoadedApplicationViewModel> {
  const pages = getPageGroups(state);
  const organizationId = getOrganizationId(state);
  const singleUser = singleUserVersion(state);
  const pathSegments = splitPath(state).slice(3);
  const currentUserId = currentEmployeeUuid(state);
  const currentEmployee = currentEmployeeAsViewModel(state);
  const organization = getOrganization(state);
  const currentPageId = pathSegments.length > 0 ? Number(pathSegments[0] ?? '' + defaultPageId) : defaultPageId;
  const hrefPrefix = '/account/' + organizationId + '/' + currentPageId + '/';
  const now = LocalDate.fromString(state.today);
  const monday = now.withPreviousOrSame(DayOfWeek.MONDAY);

  function currentStartTask(state: State): StartTask | undefined {
    if (!getFeatureStates(state).core) {
      return undefined;
    }
    const tasks = startTasks(state);
    const predicate =
      state.selectedStartTask !== '' ? (t) => t.uuid === state.selectedStartTask : (t) => t.myTask && !t.executed;

    return tasks
      .filter(predicate)
      .slice(0, 1)
      .map((t) => ({
        uuid: t.uuid,
        weeksFromNow: Math.floor(monday.until(t.time) / 7),
        weekDisplay: 'Uke ' + t.time.toStringForDisplayWeekAndYear(),
        functionName: t.functionName,
        href: t.href,
        name: t.name,
        helpContent: t.helpContent,
        procedures: convertInternalLinks(t.procedures, getDocsForLinking(state)),
        relatedItems: t.relatedItems ?? [],
      }))[0];
  }

  const robotAlert = showRobotAlert(state) ? currentRobotAlert(state) : undefined;

  if (organization === undefined) {
    throw new Error('Illegal state (E121), organization not found');
  }

  const currentUserIsOwner = (currentEmployee?.email?.toUpperCase() ?? '') === organization.ownerEmail.toUpperCase();

  let defaultFunctionUuid = '';
  const defaultFunction = functionsNotDeleted(state).find((f) => {
    return f.templateId === 179; // Daglig leder
  });
  if (defaultFunction) {
    defaultFunctionUuid = defaultFunction.uuid;
  }

  const currentTutorialContent = buildCurrentTutorialContent(state.currentTutorialId, organization, currentEmployee);

  return {
    loaded: true,
    currentContentViewerContent: '',
    availableTutorials: findAvailableTutorials(state, organization, currentEmployee),
    tutorialActive: isTutorialActive(organization, currentEmployee),
    displayedUserName: displayedUserName(state),
    pageMenu: getPageMenu(pages, organizationId),
    currentOrganizationId: organizationId,
    currentEmployeeUuid: currentEmployeeUuid(state),
    currentEmployee: employeesNotDeleted(state).find((e) => {
      return e.uuid === currentEmployeeUuid(state);
    }),
    currentPageId: currentPageId,
    currentPageView: pageView(api, hrefPrefix, currentPageId, pages, state),
    currentPathArray: splitPath(state),
    headerStartTask: {
      startTaskExpanded: state.selectedStartTask !== '',
      startTask: currentStartTask(state),
      relatedEvent: organization.eventOccurrences.find((e) => {
        return e.taskUuid === currentStartTask(state)?.uuid;
      }),
      userTotal: startTasks(state).filter((item) => item.myTask).length,
      userExecuted: startTasks(state).filter((item) => item.myTask && item.executed).length,
    },
    singleUserVersion: singleUser,
    helpViewerOpen: state.helpViewerOpen,
    tutorialViewerOpen: state.tutorialViewerOpen,
    currentTutorialContent: currentTutorialContent,
    currentHelpPage: state.currentHelpPage,
    sideContentOpen: state.sideContentOpen,
    copilotOpen: state.copilotOpen,
    currentSideContent: getCurrentSideContent(state),
    contentViews: await currentContentViews(api, hrefPrefix, pathSegments, state),
    employeesForShare: employeesNotDeleted(state)
      .filter((e) => e.uuid !== currentUserId && e.status === 'ACTIVE' && e.accessLevel !== 'NONE')
      .map((e) => ({ uuid: e.uuid, name: e.name, disabled: false })),
    selectedEntityName: breadCrumbSelectedName(state),
    selectedEntityType: currentEntityTypeName(state),
    settings: getSettings(state, currentEmployee, currentUserIsOwner, organization),
    currentRobotAlert: robotAlert,
    functionsByTemplateId: functionsByTemplateId(state),
    featureStates: getFeatureStates(state),
    userEmails: getUsersForAccess(state).map((e) => e.email ?? ''),
    partners: partnersNotDeleted(state)
      .filter((p) => {
        return p.templateId === null || !p.contentLastModifiedWasCreate;
      })
      .map((p) => {
        return { value: p.uuid, text: p.name };
      }),
    defaultFunctionUuid,
    employeesCount: employeesNotTerminatedCount(state),
    specialTerms: getSpecialTerms(state),
    recipientOptionsForIssues: getRecipientOptionsForIssues(state),
    uiSettings: uiSettings(state),
    mobileAppViewModel: getMobileAppViewModel(state),
  };
}

function _singleUserVersionAvailable(state: State) {
  return employeesNotDeleted(state).length === 1 && employeesDeleted(state).length === 0;
}

export async function applicationViewModel(api: ApiClient, state: State): Promise<ApplicationViewModel> {
  const l = loaded(state);

  if (l) {
    try {
      return await loadedApplicationViewModel(api, state);
    } catch (e) {
      console.error(e);
      return {
        loaded: false,
      };
    }
  } else {
    return {
      loaded: false,
    };
  }
}
