import type { ContentView } from 'src/content/d-content.js';
import { buildAssetView } from 'src/models/content/build-asset-view.js';
import { buildConstitutionalDocumentView } from 'src/models/content/build-constitutional-document-view.js';
import { buildContractView } from 'src/models/content/build-contract-view.js';
import { buildDocumentView } from 'src/models/content/build-document-view.js';
import { buildEmployeeView } from 'src/models/content/build-employee-view.js';
import { buildEventOccurrenceView } from 'src/models/content/build-event-occurrence-view.js';
import { buildFunctionView } from 'src/models/content/build-function-view.js';
import { buildGuidelineView } from 'src/models/content/build-guideline-view.js';
import { buildIssueView } from 'src/models/content/build-issue-view.js';
import { buildMeetingView } from 'src/models/content/build-meeting-view.js';
import { buildContactView, buildPartnerView } from 'src/models/content/build-partner-view.js';
import { buildReportView } from 'src/models/content/build-report-view.js';
import { buildRiskAssessmentView } from 'src/models/content/build-risk-assessment-view.js';
import { buildSubstanceView } from 'src/models/content/build-substance-view.js';
import { buildTaskView } from 'src/models/content/build-task-view.js';
import { buildTutorialView } from 'src/models/content/build-tutorial-view.js';
import type { State } from 'src/store/types.js';
import Bugsnag from '@bugsnag/js';
import { buildMeetingOccurrenceView } from 'src/models/content/build-meeting-occurrence-view';

import { getDocsForLinking, convertInternalLinks } from 'src/models/internal-linking.js';
import { getFeatureStates, getSpecialTerms } from 'src/store/selectors/features';
import {
  ApiClient,
  employeesNotTerminatedCount,
  getEmployeesWithForAccessControl,
  getOrganizationId,
  isCurrentUserEmployee,
  sector,
  writeAccess,
} from 'src/store';
import { CommonDataEntityView } from 'src/content/entity-content';
import { LocalDate } from 'src/utilities/local-date';
import { buildCollectionView } from 'src/models/content/build-collection-view';
import { storedCollections } from 'src/store/selectors/collections';
import { calculatePrompt } from 'src/models/content/prompt-builder';

async function buildContentView(
  api: ApiClient,
  type: string,
  uuid: string,
  state: State,
  currentParent: string,
): Promise<ContentView> {
  const commonData: CommonDataEntityView = {
    today: LocalDate.fromString(state.today),
    currentUserHasWriteAccess: writeAccess(state),
    sector: sector(state),
    featureStates: getFeatureStates(state),
    specialTerms: getSpecialTerms(state),
    employeesCount: employeesNotTerminatedCount(state),
    currentUserIsEmployee: isCurrentUserEmployee(state),
    accessControlOptions: getEmployeesWithForAccessControl(state).map((e) => ({
      value: e.id,
      text: e.value,
      disabled: e.disabled,
    })),
    availableCollections: storedCollections(state),
    organizationId: '' + getOrganizationId(state),
  };
  switch (type) {
    case 'assets':
      return buildAssetView(api, uuid, commonData, state, currentParent);
    case 'constitutionalDocuments':
      return buildConstitutionalDocumentView(api, uuid, commonData, state, currentParent);
    case 'contracts':
      return buildContractView(api, uuid, commonData, state, currentParent);
    case 'documents':
      return buildDocumentView(api, uuid, commonData, state, currentParent);
    case 'employees':
      return buildEmployeeView(api, uuid, commonData, state, currentParent);
    case 'eventOccurrences':
      return buildEventOccurrenceView(api, uuid, commonData, state, currentParent);
    case 'meetingOccurrences':
      return buildMeetingOccurrenceView(api, uuid, commonData, state, currentParent);
    case 'functions':
      return buildFunctionView(api, uuid, commonData, state, currentParent);
    case 'tasks':
      return buildTaskView(api, uuid, commonData, state, currentParent);
    case 'guidelines':
      return buildGuidelineView(api, uuid, commonData, state, currentParent);
    case 'issues':
      return buildIssueView(api, uuid, commonData, state, currentParent);
    case 'meetings':
      return buildMeetingView(api, uuid, commonData, state, currentParent);
    case 'partners':
      return buildPartnerView(api, uuid, commonData, state, currentParent);
    case 'contacts':
      return buildContactView(api, uuid, commonData, state, currentParent);
    case 'reports':
      return buildReportView(api, uuid, commonData, state, currentParent);
    case 'riskAssessments':
      return buildRiskAssessmentView(api, uuid, commonData, state, currentParent);
    case 'substances':
      return buildSubstanceView(api, uuid, commonData, state, currentParent);
    case 'tutorials':
      return buildTutorialView(api, uuid, commonData, state, currentParent);
    case 'collections':
      return buildCollectionView(api, uuid, commonData, state, currentParent);
    default:
      throw new Error('Illegal state (E180), unexpected document type ' + type);
  }
}

export async function currentContentViews(
  api: ApiClient,
  hrefPrefix: string,
  pathSegments: string[],
  state: State,
): Promise<ContentView[]> {
  console.log('VIEWS', hrefPrefix, pathSegments, state);
  let p = pathSegments.slice(1);
  let currentParent = hrefPrefix.substring(0, hrefPrefix.length - 1);
  const result: ContentView[] = [];
  try {
    while (p.length > 0) {
      const type = p[0];
      const uuid = p[1];
      p = p.slice(2);
      try {
        const contentView = await buildContentView(api, type, uuid, state, currentParent);
        if ('procedures' in contentView) {
          contentView.procedures = convertInternalLinks(contentView.procedures, getDocsForLinking(state));
        }
        if ('relatedMeetingProcedures' in contentView) {
          contentView.relatedMeetingProcedures = convertInternalLinks(
            contentView.relatedMeetingProcedures,
            getDocsForLinking(state),
          );
        }
        if ('description' in contentView) {
          contentView.description = convertInternalLinks(contentView.description, getDocsForLinking(state));
        }
        if ('content' in contentView) {
          contentView.content = convertInternalLinks(contentView.content, getDocsForLinking(state));
        }
        if ('meetingAgenda' in contentView) {
          contentView.meetingAgenda = convertInternalLinks(contentView.meetingAgenda, getDocsForLinking(state));
        }
        if (contentView.type !== 'empty') {
          contentView.docsForLinking = getDocsForLinking(state);
        }
        const features = getFeatureStates(state);
        if (features.ask) {
          contentView.prompt = await calculatePrompt('' + getOrganizationId(state), contentView);
        }
        result.push(contentView);
      } catch (e1) {
        console.error(e1);
        Bugsnag.notify(new Error('Building content view model ' + e1));
        result.push({
          type: 'empty',
          parentHref: currentParent,
          href: currentParent + '/' + type + '/' + uuid,
          currentUserHasAccess: true,
        });
      }
      currentParent += '/' + type + '/' + uuid;
    }
  } catch (e) {
    console.error(e);
    Bugsnag.notify(new Error('Building content view model ' + e));
  }

  return result;
}
