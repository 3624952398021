import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../library/elements/d-section';
import '../../library/fields/d-view-html';
import './d-upgrade';
import type { Feature, Promo } from 'src/store/selectors/features';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';

/**
 *
 * STATUS OK
 */
@customElement('d-promo-section')
export class DPromoSection extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
    img,
    video {
      max-width: 100%;
      margin-bottom: 20px;
    }
    :host([dialog]) img,
    :host([dialog]) video {
      max-width: calc(100% + 40px);
      margin: 0 -20px 20px -20px;
    }
    .title {
      margin-bottom: 10px;
      font-size: 30px;
      font-weight: 600;
      line-height: 120%;
    }

    @media only screen and (max-width: 600px) {
      .main-title {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    .module-name {
      font-size: 18px;
      margin-bottom: 4px;
    }
    .price {
      font-size: 30px;
      font-weight: bold;
    }
    .price span {
      font-size: 22px;
      font-weight: normal;
    }
    .button {
      flex: none;
      margin-left: 6px;
      border-radius: 4px;
      background: var(--themeColor);
      padding: 8px 12px;
      font-size: 16px;
      font-weight: 500;
      color: white;
      cursor: pointer;
    }
    @media (hover: hover) {
      .button:hover {
        background: var(--themeColorDarkerTwo);
      }
    }
  `;
  @property({ type: Boolean })
  dialog = false;
  @property({ type: Boolean, attribute: 'theme-page' })
  themePage = false;
  @property({ type: Number })
  employeesCount = 1;
  @property({ type: String })
  specialTerms: string | undefined = undefined;
  @property({ type: Array })
  features: Feature[] = [];
  @property({ type: Object })
  promoData!: Promo;
  @property({ type: Array })
  healthcareSignatoryOptions: SelectDropdownOption[] = [];
  @property({ type: String })
  healthcareSignatory: string | undefined = undefined;
  @property({ type: Boolean })
  canUpgrade = false;

  render() {
    if (this.promoData) {
      return html`
        ${
          this.promoData.imageSource && !this.promoData.videoSource
            ? html` <img id="image" src="${this.promoData.imageSource}" />`
            : nothing
        }
        ${
          this.promoData.videoSource
            ? html` <video
                id="video"
                autoplay
                @mouseenter=${() => this.setVideoControls()}
                @mouseout=${() => this.removeVideoControls()}
              >
                <source
                  src="${this.promoData.videoSource}"
                  poster="${this.promoData.imageSource ?? ''}"
                  type="video/mp4"
                />
              </video>`
            : nothing
        }
        <d-section topless>
          <div>
            <div class="title">${this.promoData.title}</div>
            <d-view-info .content=${this.promoData.text}></d-view-info>
          </div>
        </d-section>
        
        </d-section>
        <d-upgrade
          .dialog=${this.dialog}
          .employeesCount=${this.employeesCount}
          .specialTerms=${this.specialTerms}
          .features=${this.features}
          .bundles=${this.promoData.bundles}
          .modules=${this.promoData.modules}
          .addons=${this.promoData.addons}
          .selectedFeature=${this.promoData.selectedFeature}
          .healthcareSignatoryOptions=${this.healthcareSignatoryOptions}
          .healthcareSignatory=${this.healthcareSignatory ?? ''}
          ?theme-page=${this.themePage}
          .canUpgrade=${this.canUpgrade}
        ></d-upgrade>
      `;
    }
    return nothing;
  }

  private setVideoControls() {
    const video = this.shadowRoot?.getElementById('video');
    if (video) {
      video.setAttribute('controls', '');
    }
  }

  private removeVideoControls() {
    const video = this.shadowRoot?.getElementById('video');
    if (video) {
      video.removeAttribute('controls');
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-promo-section': DPromoSection;
  }
}
