import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { SectionField } from 'src/library/components/d-field-section';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import { IssueUpdateMessage, IssueUpdateMessageClassificationEnum } from 'src/store/api';
import { LocalDate } from 'src/utilities/local-date';
import { createUuid } from 'src/store';
import { abbreviateDescription, isEmptyOrInvalidString } from 'src/utilities/text';

import 'src/library/components/d-field-section';
import 'src/library/elements/d-smooth-resize';

export interface NewIssueEditItem {
  description: string;
  recipient: string;
  classified: boolean;
  anonymous: boolean;
  eventDate: string;
  immediateMeasures: string;
  furtherMeasures: string;
  processing: string;
}

/**
 *
 */
@customElement('d-new-issue')
export class DNewIssue extends LitElement {
  static readonly styles = [];
  @property({ type: String })
  context = '';
  @property({ type: Object })
  editItem!: NewIssueEditItem;
  @property({ type: String })
  currentEmployeeUuid: string = '';
  @property({ type: Number })
  organizationId = 0;
  @property({ type: String })
  issueUuid = '';
  @property({ type: Array })
  recipientOptions: SelectDropdownOption[] = [];
  @property({ type: Boolean })
  issueRegistered = false;
  @property({ type: Boolean })
  registrationComplete = false;

  @property({ type: Boolean })
  canProcessIssue = true;

  private processingOptions = [
    {
      value: 'DEFAULT',
      text: 'Avviket skal behandles senere / av andre',
    },
    {
      value: 'ASAP',
      text: 'Jeg vil behandle avviket selv, med det samme',
    },
    {
      value: 'CLOSE',
      text: 'Avviket trenger ingen videre behandling',
    },
  ];

  private get moreInfoPrompt() {
    if (this.context === 'mobile') {
      return '<p>Hvis du vil, kan du gi utfyllende informasjon her.</p>';
    }
    return '<p>Hvis du vil, kan du gi utfyllende informasjon og velge videre behandling her.</p>';
  }

  private get confirmation() {
    return '<p>Din avviksmelding er registrert.</p>';
  }

  private get fieldsPrimary(): SectionField[] {
    return [
      {
        field: 'description',
        hideLabel: true,
        type: 'edit-textarea',
        placeholder: 'Hva skjedde?',
        autofocus: this.context !== 'mobile',
        class: 'fullWidth',
      },
      {
        field: 'recipient',
        label: 'Til',
        type: 'edit-dropdown',
        inlineLabel: true,
        lightLabel: true,
        options: this.recipientOptions,
        class: 'fullWidth',
        condition: this.recipientOptions.length > 1,
      },
      {
        field: 'recipient',
        label: 'Til',
        inlineLabel: true,
        type: 'view-text',
        class: 'fullWidth',
        options: this.recipientOptions,
        condition: this.recipientOptions.length < 2,
      },
      {
        field: 'classified',
        optionField: 'classified',
        type: 'edit-checkbox',
        class: 'minWidth200',
      },
      {
        field: 'anonymous',
        optionField: 'anonymous',
        type: 'edit-checkbox',
        class: 'minWidth200',
      },
    ];
  }

  private get fieldsSecondary(): SectionField[] {
    return [
      {
        field: '',
        type: 'view-info',
        content: this.moreInfoPrompt,
        class: 'fullWidth',
      },
      {
        field: 'eventDate',
        type: 'edit-date',
        class: 'fullWidth',
      },
      {
        field: 'immediateMeasures',
        type: 'edit-textarea',
        class: 'fullWidth',
      },
      {
        field: 'furtherMeasures',
        type: 'edit-textarea',
        class: 'fullWidth',
      },
      {
        field: 'processing',
        type: 'edit-dropdown',
        options: this.processingOptions,
        class: 'fullWidth',
        condition: this.context !== 'mobile' && this.canProcessIssue,
      },
    ];
  }

  private get fieldsConfirmation(): SectionField[] {
    return [
      {
        field: '',
        type: 'view-info',
        content: this.confirmation,
        class: 'fullWidth',
      },
    ];
  }

  defaultIssue(): IssueUpdateMessage {
    let classification: IssueUpdateMessageClassificationEnum = 'NONE';
    const accessControl: string[] = [];
    if (this.editItem.classified) {
      classification = 'CONFIDENTIAL';
      if (!this.editItem.anonymous) {
        accessControl.push(this.currentEmployeeUuid);
      }
      if (!accessControl.includes(this.editItem.recipient)) {
        accessControl.push(this.editItem.recipient);
      }
    }
    return {
      /**
       * Report
       */
      reportedDate: '',
      reportedBy: this.editItem.anonymous ? '' : this.currentEmployeeUuid,
      reportedTo: this.editItem.recipient,
      description: '',
      eventDate: '',
      immediateMeasures: '',
      furtherMeasures: '',
      /**
       * Processing
       */
      name: '',
      processedDate: '',
      processedBy: '',
      notes: '',
      /**
       * Checklist
       */
      personDamagePossibility: false,
      relateToPatient: false,
      relateToEmployees: false,
      workRelatedHealthIssue: false,
      relateToEquipment: false,
      relateToRadiation: false,
      informationLeakagePossibility: false,
      relateToMaritimeHealthCertificate: false,
      /**
       * Work related health issue
       */
      workRelatedHealthIssueEmployee: '',
      workRelatedHealthIssueDescription: '',
      workRelatedInjury: false,
      workRelatedSickness: false,
      workRelatedInjuryTask: '',
      workRelatedInjuryIncidentCause: '',
      workRelatedSicknessCausingTask: '',
      workRelatedSicknessCausingTaskLocation: '',
      workRelatedSicknessCausingEnvironment: '',
      /**
       * Measures
       */
      identifiedMeasures: '',
      implementedMeasure: false,
      /**
       * Evaluation
       */
      evaluatedDate: '',
      evaluatedBy: '',
      /**
       * Meta data
       */
      pages: [],
      classification,
      accessControl,
      isConfirmedEntity: true,
    };
  }

  name(description: string) {
    if (description.length < 42) {
      return description;
    }
    return description.substring(0, 40) + '…';
  }

  close() {
    this.dispatchEvent(
      new CustomEvent('close', {
        composed: true,
        bubbles: true,
      }),
    );
  }

  newIssue() {
    const issueItem = this.defaultIssue();
    this.issueUuid = createUuid();
    const id = this.issueUuid;
    issueItem.description = this.editItem.description;
    issueItem.name = abbreviateDescription(this.editItem.description);
    issueItem.reportedDate = LocalDate.now().toString();
    this.dispatchEvent(
      new CustomEvent<{ id: string; message: IssueUpdateMessage }>('create-issue', {
        composed: true,
        bubbles: true,
        detail: { id: id, message: issueItem },
      }),
    );
    this.issueRegistered = true;
    this.dispatchEvent(
      new CustomEvent('issue-registered', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  saveAdditionalInformation() {
    this.registrationComplete = true;
    this.dispatchEvent(
      new CustomEvent('registration-complete', {
        bubbles: true,
        composed: true,
      }),
    );
    if (
      this.editItem.eventDate !== '' ||
      !isEmptyOrInvalidString(this.editItem.immediateMeasures) ||
      !isEmptyOrInvalidString(this.editItem.furtherMeasures) ||
      this.editItem.processing !== 'DEFAULT'
    ) {
      const id = this.issueUuid;
      const today = LocalDate.now().toString();
      const issueItem = this.defaultIssue();
      issueItem.description = this.editItem.description;
      issueItem.name = abbreviateDescription(this.editItem.description);
      issueItem.reportedDate = today;
      issueItem.eventDate = this.editItem.eventDate;
      issueItem.immediateMeasures = this.editItem.immediateMeasures;
      issueItem.furtherMeasures = this.editItem.furtherMeasures;
      if (this.editItem.processing === 'CLOSE') {
        issueItem.processedDate = today;
        issueItem.processedBy = this.currentEmployeeUuid;
      }
      this.dispatchEvent(
        new CustomEvent<{ id: string; message: IssueUpdateMessage }>('create-issue', {
          composed: true,
          bubbles: true,
          detail: { id: id, message: issueItem },
        }),
      );
      if (this.editItem.processing === 'ASAP') {
        this.dispatchEvent(
          new CustomEvent('navigate', {
            bubbles: true,
            composed: true,
            detail: { href: '/account/' + this.organizationId + '/882901/issues/' + this.issueUuid },
          }),
        );
        this.close();
      }
    }
  }

  renderPrimary() {
    return html`
      <d-field-section
        .entityType=${'issues'}
        .fields=${this.fieldsPrimary}
        .item=${this.editItem}
        @item-changed=${(e) => this.onFieldSectionChanged(e)}
      ></d-field-section>
    `;
  }

  renderSecondary() {
    return html`
      <d-field-section
        .entityType=${'issues'}
        .fields=${this.fieldsSecondary}
        .item=${this.editItem}
        @item-changed=${(e) => this.onFieldSectionChanged(e)}
      ></d-field-section>
    `;
  }

  renderConfirmation() {
    return html`
      <d-field-section
        .entityType=${'issues'}
        .fields=${this.fieldsConfirmation}
        .item=${this.editItem}
      ></d-field-section>
    `;
  }

  renderContent() {
    if (this.registrationComplete) {
      return this.renderConfirmation();
    }
    if (this.issueRegistered) {
      return this.renderSecondary();
    }
    return this.renderPrimary();
  }

  render() {
    return html` <d-smooth-resize> ${this.renderContent()} </d-smooth-resize> `;
  }

  protected fireEditItemchanged() {
    this.dispatchEvent(
      new CustomEvent('new-issue-edit-item-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.editItem },
      }),
    );
  }

  protected onFieldSectionChanged(e) {
    e.stopPropagation();
    this.editItem = e.detail.value;
    this.fireEditItemchanged();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-new-issue': DNewIssue;
  }
}
