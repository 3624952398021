import { AbstractEntityViewWithRevisions, EntityContent, ListDefinition } from 'src/content/entity-content.js';
import '../../library/fields/index.js';
import '../../library/fields/d-view-html';
import '../../library/editors/index.js';
import '../../library/editors/components/d-edit-classification.js';
import '../../library/editors/components/d-select-add';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/lists/d-list-section.js';
import '../../library/components/d-signatures-section';
import '../../library/components/d-view-attachments';
import { customElement } from 'lit/decorators.js';
import { html, TemplateResult } from 'lit';
import type { Checklist } from 'src/library/fields/d-checklist.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { PartnerForSigning, PersonForSigning } from 'src/layout/parts/request-signatures-dialog';
import { toSelectOptionsEmployees, toSelectOptionsPartners } from 'src/models/content/build-contract-view';
import { isEmptyHtmlContent, isEmptyOrInvalidString } from 'src/utilities/text';
import { SignableEntity, SigningOrderWithAsDocument } from 'src/library/components/d-signatures-section';
import { SectionField } from 'src/library/components/d-field-section';

export interface ContractViewEditItem {
  pages: string[];
  partners: string[];
  employees: string[];
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  content: string;
  validFromDate: string;
  validToDate: string;
}

export interface ContractView
  extends AbstractEntityViewWithRevisions<ContractViewEditItem>,
    UpdateSectionItem,
    SignableEntity {
  partners: string[];
  employees: string[];
  relationsLocked: boolean;
  type: 'contracts';
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  accessControlOptions: SelectTagOption[];
  classificationText: string;
  content: string;
  validFromDate: string;
  validToDate: string;
  contractPartyNames: string;
  checklist?: Checklist;
  availablePages: SelectTagOption[];
  availableEmployees: PersonForSigning[];
  activeEmployeesCount: number;
  currentUserIsOwner: boolean;
  availablePartners: PartnerForSigning[];
  pages: string[];
  signingOrders: SigningOrderWithAsDocument[];
  organizationName: string;
  hasSigningModule: boolean;
}

/**
 *
 * STATUS OK
 */
@customElement('d-contract-view')
export class DContractView extends EntityContent<ContractView, ContractViewEditItem> {
  private showTemplateContentOnEdit = false;

  private get signingDisabled() {
    return (
      (!this.hasValidContent && this.entityView.attachments.length === 0) ||
      (this.entityView.employees.length === 0 && this.entityView.partners.length === 0)
    );
  }

  private get unsavedTemplateContent() {
    return this.entityView.content !== '' && this.entityView.contentLastModifiedBy === 'TrinnVis-teamet';
  }

  private get hasValidContent() {
    return !isEmptyHtmlContent(this.entityView.content) && this.entityView.contentLastModifiedBy !== 'TrinnVis-teamet';
  }

  renderViewMode() {
    return html` ${this.renderFieldSection('contracts', this.viewFields(), this.entityView)} `;
  }

  async initializeEditItem() {
    let content = this.entityView.content;
    if (this.unsavedTemplateContent && !this.showTemplateContentOnEdit) {
      content = '';
    } else if (this.unsavedTemplateContent && this.showTemplateContentOnEdit) {
      content = this.entityView.content + '<p></p>'; // HACK must change the value so the edited by is changed
    }
    this.editItem = {
      accessControl: this.entityView.accessControl,
      classification: this.entityView.classification,
      content: content,
      employees: this.entityView.employees,
      partners: this.entityView.partners,
      validFromDate: this.entityView.validFromDate,
      validToDate: this.entityView.validToDate,
      name: this.entityView.name,
      pages: this.entityView.pages,
    };
    this.showTemplateContentOnEdit = false;
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  fieldActionInsertTemplateContent() {
    if (this.editItem) {
      this.editItem = {
        ...this.editItem,
        content: this.entityView.content,
      };
    }
  }

  renderEditItem(item: ContractViewEditItem): TemplateResult<1> {
    return html`
      ${this.renderEditClassification(item)} ${this.renderFieldSection('contracts', this.editFields(item), item)}
      ${this.renderViewAttachments()}
    `;
  }

  renderViewAttachments() {
    return html`
      <d-view-attachments
        .writeAccess=${this.entityView.currentUserHasWriteAccess}
        field="contracts_documents"
        .items=${this.entityView.attachments}
        @upload-file=${this.onUploadFile}
      ></d-view-attachments>
    `;
  }

  editModeWithTemplateContent() {
    this.showTemplateContentOnEdit = true;
    return this.onEditModeOn();
  }

  protected viewFields(): SectionField[] {
    return [
      {
        field: 'classificationText',
        fieldForLabel: 'generalFields_access',
        type: 'view-text',
        class: 'fullWidth',
        condition: this.entityView.classificationText !== '',
      },
      {
        field: 'validFromDate',
        type: 'view-date',
        format: 'date-short',
        class: 'minWidth300',
      },
      {
        field: 'validToDate',
        type: 'view-date',
        format: 'date-short',
        class: 'minWidth300',
      },
      {
        field: 'contractPartyNames',
        type: 'view-text',
        class: 'fullWidth',
      },
      {
        type: 'custom',
        render: () => this.renderSigningSection(),
        condition: this.entityView.featureStates.signing && !this.entityView.deleted,
      },
      {
        field: '',
        label: 'Avtaletekst',
        type: 'view-text',
        value: () => '',
        action: { name: 'Se forslag', action: 'editModeWithTemplateContent' },
        condition: this.unsavedTemplateContent,
      },
      {
        field: 'content',
        type: 'view-html',
        condition: !this.unsavedTemplateContent,
      },
    ];
  }

  protected lists(): ListDefinition[] {
    return [
      {
        field: 'contracts_documents',
        type: 'custom',
        icon: 'attachments',
        items: 'attachments',
        render: () => this.renderViewAttachments(),
        condition: this.entityView.featureStates.core,
      },
    ];
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }

  private renderSigningSection() {
    return html`<d-signatures-section
      .entityView=${this.entityView}
      .signingDisabled=${this.signingDisabled}
    ></d-signatures-section>`;
  }

  private insertTemplateContentAction(content: string): string {
    if (isEmptyHtmlContent(content) && this.unsavedTemplateContent) {
      return 'Sett inn forslag';
    }
    return '';
  }

  private editFields(item: ContractViewEditItem): SectionField[] {
    return [
      {
        field: 'name',
        type: 'edit-text',
        placeholder: 'Dette feltet må fylles ut',
        autofocus: !this.entityView.isConfirmedEntity,
        markIfEmpty: true,
        class: 'fullWidth',
      },
      {
        field: 'validFromDate',
        type: 'edit-date',
        class: 'minWidth300',
      },
      {
        field: 'validToDate',
        type: 'edit-date',
        class: 'minWidth300',
      },
      {
        field: 'contractPartyNames',
        value: () => {
          return this.entityView.contractPartyNames;
        },
        type: 'view-text',
        class: 'fullWidth',
        condition: this.entityView.relationsLocked,
      },
      {
        field: 'employees',
        type: 'edit-select-add',
        allowRemoveAll: true,
        placeholder: 'Velg person',
        options: toSelectOptionsEmployees(this.entityView.availableEmployees),
        condition: !this.entityView.relationsLocked,
        class: 'fullWidth',
      },
      {
        field: 'partners',
        type: 'edit-select-add',
        allowRemoveAll: true,
        placeholder: 'Velg samarbeidspartner',
        options: toSelectOptionsPartners(this.entityView.availablePartners),
        condition: !this.entityView.relationsLocked,
        class: 'fullWidth',
      },
      {
        field: 'content',
        type: 'edit-html',
        docsForLinking: this.entityView.docsForLinking,
        labelAction: {
          name: this.insertTemplateContentAction(item.content),
          action: 'fieldActionInsertTemplateContent',
        },
      },
    ];
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-contract-view': DContractView;
  }
}
