import { css, html, nothing } from 'lit';
import { PageContent } from 'src/pages/page-content.js';
import '../../library/lists/d-list-section.js';
import '../../library/lists/d-list-section-item.js';
import { customElement } from 'lit/decorators.js';
import type { ReferencesGroup, ReferencesPageViewModel } from './references-page-view-model.js';
import _ from 'lodash';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import { features, promo } from 'src/store/selectors/features';

/**
 *
 * STATUS OK
 */
@customElement('d-references-page-content')
export class DReferencesPageContent extends PageContent<ReferencesPageViewModel> {
  static readonly styles = [
    ...super.styles,
    css`
      d-list-section {
        margin-bottom: 6px;
      }
    `,
  ];

  _groupFilter(value: ReferencesGroup[], group: string) {
    if (value) {
      const v = value.filter(function (e) {
        return e.groupName === group;
      });
      if (v.length === 1) {
        return [...v[0].pages];
      }
    }

    return [];
  }

  _computeItems(references: ReferencesGroup[], group: string): ListSectionItemInput[] {
    return _.sortBy(this._groupFilter(references, group), [(e) => e.linkName]).map((r) => ({
      label: r.linkName,
      href: r.linkHref,
      target: '_blank',
      accessible: true,
    }));
  }

  renderContent() {
    if (!this.pageView.featureStates.core) {
      const promoData = promo.find((p) => {
        return p.id === 'complete';
      });
      if (promoData) {
        return html` <d-promo-section
          theme-page
          .employeesCount=${this.pageView.employeesCount}
          .specialTerms=${this.pageView.specialTerms}
          .promoData=${promoData}
          .features=${features}
          .canUpgrade=${this.writeAccess}
        ></d-promo-section>`;
      }
      return nothing;
    }
    return html`
      <d-list-section
        icon="references"
        label="Lover"
        .items=${this._computeItems(this.pageView.references, 'Lover')}
        theme-page
        .contentStickyTop=${this.contentStickyTop}
      >
      </d-list-section>

      <d-list-section
        icon="references"
        label="Forskrifter"
        .items=${this._computeItems(this.pageView.references, 'Forskrifter')}
        theme-page
        .contentStickyTop=${this.contentStickyTop}
      >
      </d-list-section>

      <d-list-section
        icon="references"
        label="Veiledere og andre ressurser"
        .items=${this._computeItems(this.pageView.references, 'Veiledere og andre ressurser')}
        theme-page
        .contentStickyTop=${this.contentStickyTop}
      >
      </d-list-section>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-references-page-content': DReferencesPageContent;
  }
}
