import { css, html, LitElement, nothing } from 'lit';

import '../../library/components/d-help-section.js';
import '../../library/fields/d-view-html.js';
import '../../library/lists/d-list-section.js';
import '../../library/lists/d-list-section-item.js';
import '../../library/elements/d-smooth-resize.js';
import '../../library/elements/d-section.js';
import '../../library/elements/d-progress-bar.js';
import '../../library/fields/d-expansion.js';
import { customElement, property } from 'lit/decorators.js';
import { startTaskStyles } from 'src/library/start-task-styles.js';
import { ifDefined } from 'lit/directives/if-defined.js';

export interface CopilotFieldPrompt {
  title: string;
  helpContent: string;
  content: string;
}

/**
 *
 */
@customElement('d-copilot-field')
export class DCopilotField extends LitElement {
  static readonly styles = [
    startTaskStyles,
    css`
      :host {
        display: block;
      }

      d-label {
        font-size: 15px;
      }

      .header {
        align-items: baseline;
      }

      .content {
        margin-top: 12px;
        margin-left: var(--listPaddingLeft);
        border-color: var(--startTaskBorderColor);
      }

      .contentScroller {
        padding: 16px 30px 20px 30px;
        max-height: calc(100vh - var(--applicationHeaderHeight) - 40vh);
        max-height: calc((var(--vh, 1vh) * 100) - var(--applicationHeaderHeight) - 40vh);
        overflow: auto;
      }

      .content d-help-section .collapsibleWrapper {
        max-width: none;
        margin-top: 0;
        margin-bottom: 12px;
      }

      .content d-help-section {
        margin: 14px 0;
      }

      .content d-help-section .systemText a.reference {
        color: var(--linkColorGray);
      }

      .content h1 {
        margin-top: 0;
        margin-bottom: 9px;
        font-size: 18px;
        color: var(--pageHeaderColor);
      }

      .content h2 {
        margin-top: 0;
        margin-bottom: 9px;
        font-size: 16px;
        color: var(--pageHeaderColor);
      }

      .content d-section > div:first-child {
        flex-basis: 400px;
        flex-grow: 3;
        padding-right: 20px;
      }

      .content d-section > div:last-child {
        flex-basis: 300px;
        flex-grow: 1;
      }

      .content d-section > div:last-child ul {
        list-style-type: none;
        margin-top: 0;
        padding-left: 20px;
      }

      .content d-section > div:last-child ul a {
        display: block;
        padding: 4px 0;
        color: var(--linkColorGray);
        text-decoration: none;
      }

      .content d-section > div:last-child ul a:hover {
        color: black;
      }

      d-slide-notification .progressText {
        margin-top: 6px;
      }
    `,
  ];
  @property({ type: Boolean, reflect: true })
  open = false;
  @property({ type: Object })
  prompt!: CopilotFieldPrompt;

  private get headerLabel() {
    return 'Assistent';
  }

  private get headerSubLabel() {
    if (this.open) {
      return '';
    }
    return this.prompt.title;
  }

  private get procedure() {
    return this.prompt.content;
  }

  _open() {
    this.open = true;
  }

  _close(e) {
    e.stopPropagation();
    this.open = false;
    this.dispatchEvent(
      new CustomEvent('start-task-closed', {
        bubbles: true,
        composed: true,
        detail: {},
      }),
    );
  }

  openCopilot(e: MouseEvent) {
    e.stopPropagation();
    this.open = false;
    this.dispatchEvent(
      new CustomEvent<{ value: boolean }>('toggle-copilot', {
        bubbles: true,
        composed: true,
        detail: {
          value: true,
        },
      }),
    );
  }

  render() {
    if (this.prompt === undefined) {
      return html`nothing`;
    }
    return html`
      <d-expansion opened @click=${() => this._open()}>
        <div>
          <div class="header">
            <div class="top-right">
              ${this.open
                ? html`
                    <d-action @click=${(e) => this.openCopilot(e)}>Åpne assistent</d-action>
                    <d-closer @click=${(e) => this._close(e)}></d-closer>
                  `
                : nothing}
            </div>
            <div class="label-wrapper">
              <d-label
                allow-wrap
                semibold
                .label=${this.headerLabel}
                sublabel="${ifDefined(this.headerSubLabel)}"
              ></d-label>
            </div>
          </div>
          <d-expansion ?opened=${this.open}>
            <div class="content">
              <d-section>
                <div>
                  <h1>${this.prompt.title}</h1>
                  ${this.prompt.helpContent
                    ? html` <d-help-section content="${this.prompt.helpContent}"></d-help-section> `
                    : nothing}
                  <d-view-info content="${this.procedure}"></d-view-info>
                </div>
              </d-section>
            </div>
          </d-expansion>
        </div>
      </d-expansion>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-copilot-field': DCopilotField;
  }
}
