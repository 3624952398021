import { EmployeeView, EmployeeViewEditItem } from 'src/content/employees/d-employee-view.js';
import { fetchDraft, toAttachmentItems } from 'src/models/factory-utilities.js';
import * as dabihStore from 'src/store';
import {
  BASE_PATH,
  EmployeeViewModelStatusEnum,
  FunctionViewModelTypeEnum,
  TutorialViewModel,
  WorkSchedule,
} from 'src/store/api';
import {
  currentEmployeeUuid,
  employeesNotDeleted,
  functionsNotDeleted,
  getOrganization,
  key,
  toEmployeeViewModel,
} from 'src/store/selectors';
import type { State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { numberWord } from 'src/utilities/text.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import tutorialsList from '../../store/data/tutorials.json';
import { sortBy } from 'lodash';
import {
  ApiClient,
  currentEmployeeForStaffingCalendar,
  employeeContractsAsListItems,
  employeesForStaffingCalendar,
  holidaysForStaffingCalendar,
  timekeepingPeriods,
  writeAccess,
} from 'src/store';
import { getUsersForAccess } from 'src/models/application-view-model.js';
import { assertIsDefined } from 'src/lib';
import { calculateBalance } from 'src/models/pages/staffing-page-view';
import { getFeatureStates } from 'src/store/selectors/features';
import { CommonDataEntityView } from 'src/content/entity-content';

function _workHoursDescription(workSchedules: WorkSchedule[]) {
  let result = '';
  let currentSchedule: WorkSchedule = dabihStore.defaultWorkSchedule;

  const sortedWorkSchedules = sortBy(workSchedules, [(item) => item.start]);
  sortedWorkSchedules.forEach(function (schedule) {
    if (LocalDate.fromString(schedule.start).isSameOrBefore(LocalDate.now())) {
      currentSchedule = schedule;
    }
  });

  if (currentSchedule.workWeeks.length > 1) {
    result = 'Varierende, ' + numberWord(currentSchedule.workWeeks.length) + ' ukers syklus';
  } else {
    const week = currentSchedule.workWeeks[0];
    let defaultWorkHours: string;
    if (!currentSchedule.workHoursDefinedPerDay) {
      defaultWorkHours = currentSchedule.workDayStartTime + '-' + currentSchedule.workDayEndTime;
      if (
        week.monday.workDay &&
        week.tuesday.workDay &&
        week.wednesday.workDay &&
        week.thursday.workDay &&
        week.friday.workDay &&
        !week.saturday.workDay &&
        !week.sunday.workDay
      ) {
        return 'Mandag - fredag ' + defaultWorkHours;
      } else {
        result += week.monday.workDay ? 'man ' : '';
        result += week.tuesday.workDay ? 'tir ' : '';
        result += week.wednesday.workDay ? 'ons ' : '';
        result += week.thursday.workDay ? 'tor ' : '';
        result += week.friday.workDay ? 'fre ' : '';
        result += week.saturday.workDay ? 'lør ' : '';
        result += week.sunday.workDay ? 'søn ' : '';
        result += defaultWorkHours;
      }
    } else {
      result += week.monday.workDay ? 'man ' + week.monday.start + '-' + week.monday.end + ' ' : '';
      result += week.tuesday.workDay ? 'tir ' + week.tuesday.start + '-' + week.tuesday.end + ' ' : '';
      result += week.wednesday.workDay ? 'ons ' + week.wednesday.start + '-' + week.wednesday.end + ' ' : '';
      result += week.thursday.workDay ? 'tor ' + week.thursday.start + '-' + week.thursday.end + ' ' : '';
      result += week.friday.workDay ? 'fre ' + week.friday.start + '-' + week.friday.end + ' ' : '';
      result += week.saturday.workDay ? 'lør ' + week.saturday.start + '-' + week.saturday.end + ' ' : '';
      result += week.sunday.workDay ? 'søn ' + week.sunday.start + '-' + week.sunday.end + ' ' : '';
    }
  }
  return result;
}

export async function buildEmployeeView(
  api: ApiClient,
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): Promise<EmployeeView> {
  let leavePeriodEditRestriction = false;
  const organization = getOrganization(state);
  if (organization) {
    leavePeriodEditRestriction = organization.leavePeriodEditRestriction;
  }
  assertIsDefined(organization);

  const item = organization.employeesById[uuid];
  assertIsDefined(item);

  const entity = toEmployeeViewModel(item, organization);
  const href = currentParent + '/employees/' + uuid;

  const current = currentEmployeeUuid(state);

  const tutorial: TutorialViewModel = organization['tutorial_2'];

  const tutorials: ListSectionItemInput[] = [];

  if (tutorial !== undefined) {
    const p = tutorial.participants.find((e) => e.uuid === uuid);
    if (p?.assigned) {
      const t = tutorialsList.find((x) => x.id === '2');
      if (t) {
        let secondaryLabel = 'påmeldt ' + LocalDate.fromString(p.assigned).toStringForDisplay();
        if (p.completed) {
          secondaryLabel = 'gjennomført ' + LocalDate.fromString(p.completed).toStringForDisplay();
        } else if (p.due) {
          const now = LocalDate.now();
          const dueDate = LocalDate.fromString(p.due);
          if (dueDate.isBefore(now)) {
            const daysOverdue = dueDate.until(now);
            let daysTerm = ' dager';
            if (daysOverdue === 1) {
              daysTerm = ' dag';
            }
            secondaryLabel += ', ' + numberWord(dueDate.until(now)) + daysTerm + ' på overtid';
          } else {
            secondaryLabel += ', frist ' + dueDate.toStringForDisplay();
          }
        }
        tutorials.push({
          label: t.title,
          clickData: t.id,
          secondaryLabel: secondaryLabel,
          accessible: true,
        });
      }
    }
  }

  const forStaffing = employeesForStaffingCalendar(state).find((e) => e.uuid === entity.uuid);
  const currentUserHasStaffingAccess = organization.staffingCalendarAccessList.includes(current ?? '');

  const timekeepingPeriodsForEmployee = timekeepingPeriods(
    employeesForStaffingCalendar(state),
    uuid,
    holidaysForStaffingCalendar(),
  );

  const attachments1 = toAttachmentItems(api, 'employees', entity.uuid, entity.attachments);
  return {
    ...commonData,
    docsForLinking: [],
    currentUserHasAccess: true,
    currentUserHasStaffingAccess: currentUserHasStaffingAccess,
    currentUserHasWriteAccess: writeAccess(state),

    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    helpContent: '',
    href: currentParent + '/employees/' + uuid,
    pdfHref:
      BASE_PATH + '/organizations/' + organization.organizationId + '/employees/' + uuid + '.pdf?key=' + key(state),
    parentHref: currentParent,
    deleted: entity.deleted === true,
    type: 'employees',
    name: entity.name ?? '',
    attachments: attachments1,
    profession: entity.profession ?? '',
    associationType: entity.associationType ?? '',
    accessLevel: entity.accessLevel ?? '',
    personalIdentifier: entity.personalIdentifier,
    hprNumber: entity.hprNumber ?? '',
    herNumber: entity.herNumber ?? '',
    status: entity.status ?? '',
    phone: entity.phone ?? '',
    secondaryPhone: entity.secondaryPhone ?? '',
    email: entity.email ?? '',
    address: entity.address ?? '',
    gender: entity.gender ?? '',
    notes: entity.notes ?? '',
    nextOfKin: entity.nextOfKin ?? '',
    workHoursDescription: _workHoursDescription(entity.workSchedules),
    assignedFunctions: sortBy(
      entity.assignedFunctions.map((f) => ({
        name: f.name ?? '',
        assignedEmployeeNames: f.assignedEmployeeNames,
        type: f.type,
        href: currentParent + '/employees/' + uuid + '/functions/' + f.uuid,
        uuid: f.uuid,
        hasDraft: f.hasDraft,
      })),
      [(f) => f.type !== FunctionViewModelTypeEnum.Common, (f) => f.name],
    ),
    unassignedFunctions: entity.unassignedFunctions.map((f) => ({
      name: f.name ?? '',
      assignedEmployeeNames: f.assignedEmployeeNames,
      type: f.type,
      href: currentParent + '/employees/' + uuid + '/functions/' + f.uuid,
      uuid: f.uuid,
      hasDraft: f.hasDraft,
    })),
    contracts: employeeContractsAsListItems(state, href, uuid),
    isCurrentUser: current === uuid,
    isCurrentOwner: organization.ownerEmail === entity.email,
    firstName: entity.firstName,
    lastName: entity.lastName,
    expertise: entity.expertise ?? '',
    employeesForReassignment: employeesNotDeleted(state)
      .filter((e) => e.status === EmployeeViewModelStatusEnum.Active)
      .filter((e) => e.uuid !== uuid)
      .map((e) => ({ uuid: e.uuid, firstName: e.firstName, lastName: e.lastName })),
    functionsForReassignment: functionsNotDeleted(state)
      .filter((funct) => {
        const foundInEmployees = funct.employees.filter((employee) => employee === uuid);
        return funct.type !== 'COMMON' && foundInEmployees.length > 0;
      })
      .map((f) => ({ uuid: f.uuid, name: f.name ?? '', type: f.type.toString() })),
    inactiveEmployeeIdForReassignment: uuid,
    tutorials: tutorials,
    today: LocalDate.fromString(state.today),
    speciality: entity.speciality ?? '',
    deletable: (current !== uuid && organization.ownerEmail !== entity.email && entity.deletable) ?? false,
    forStaffing: forStaffing,
    otherUserEmails: getUsersForAccess(state)
      .filter((e) => e.uuid !== uuid)
      .map((e) => e.email ?? '')
      .filter((x) => x !== ''),
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(api, 'employees', uuid, organization.organizationId);
      return command.draft as EmployeeViewEditItem;
    },
    timekeepingPeriods: timekeepingPeriodsForEmployee,
    timekeepingBalance: calculateBalance(timekeepingPeriodsForEmployee),
    timekeepingEmployee: currentEmployeeForStaffingCalendar(state),
    leavePeriodEditRestriction,
    featureStates: getFeatureStates(state),
  };
}
