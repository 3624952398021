import { css, html, LitElement, nothing } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import { uuid } from 'src/utilities/text';
import { LocalDate } from 'src/utilities/local-date';
import { DPromoDialog, PromoDialogResult } from 'src/library/promo/d-promo-dialog';
import {
  DNewContactAndPartnerDialog,
  NewContactAndPartnerDialogResult,
} from 'src/layout/parts/d-new-contact-and-partner-dialog';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import { PageMenuGroup } from 'src/layout/parts/d-page-menu';
import type { FeatureStates } from 'src/store/selectors/features';
import { features, promo } from 'src/store/selectors/features';
import { OrganizationViewModelSpecialTermsEnum } from 'src/store/api';
import { pageIds } from 'src/store';

export interface CreateAssetInput {
  entityType: 'assets';
  entityUuid: string;
  targetUrl: string;
}

export interface CreateEventOccurrenceInput {
  entityType: 'eventOccurrences';
  instance?: string;
  entityUuid: string;
  assetUuid?: string;
  taskUuid?: string;
  targetUrl: string;
}

export interface CreateMeetingOccurrenceInput {
  entityType: 'meetingOccurrences';
  instance: string;
  entityUuid: string;
  meetingUuid?: string;
  templateMeetingOccurrenceUuid?: string;
  employeeUuid?: string;
  targetUrl: string;
}

export interface CreateConstitutionalDocumentInput {
  entityType: 'constitutionalDocuments';
  entityUuid: string;
  targetUrl: string;
}

export interface CreateContactInput {
  entityType: 'contacts';
  entityUuid: string;
  partnerId: string;
  targetUrl: string;
}

export interface CreateContractInput {
  entityType: 'contracts';
  entityUuid: string;
  pageId: number;
  employeeId?: string;
  partnerId?: string;
  targetUrl: string;
}

export interface CreateDocumentInput {
  entityType: 'documents';
  entityUuid: string;
  pageId: number;
  targetUrl: string;
}

export interface CreateEmployeeInput {
  entityType: 'employees';
  entityUuid: string;
  targetUrl: string;
}

export interface CreateFunctionInput {
  employeeId?: string;
  entityType: 'functions';
  entityUuid: string;
  pageId: number;
  targetUrl: string;
}

export interface CreateGuidelineInput {
  entityType: 'guidelines';
  entityUuid: string;
  pageId: number;
  targetUrl: string;
}

export interface CreatePartnerInput {
  entityType: 'partners';
  entityUuid: string;
  pageId: number;
  targetUrl: string;
}

export interface CreateReportInput {
  entityType: 'reports';
  entityUuid: string;
  pageId: number;
  targetUrl: string;
}

export interface CreateRiskAssessmentInput {
  entityType: 'riskAssessments';
  entityUuid: string;
  pageId: number;
  targetUrl: string;
  templateId?: number;
  substanceUuid?: string;
  name?: string;
}

export interface CreateTaskInput {
  entityType: 'tasks';
  entityUuid: string;
  pageId: number;
  functionId: string;
  targetUrl: string;
}

export interface CreateCollectionInput {
  entityType: 'collections';
  entityUuid: string;
  pageId: number;
  targetUrl: string;
}

export type CreateEntityInput =
  | CreateAssetInput
  | CreateConstitutionalDocumentInput
  | CreateContactInput
  | CreateContractInput
  | CreateDocumentInput
  | CreateEmployeeInput
  | CreateEventOccurrenceInput
  | CreateMeetingOccurrenceInput
  | CreateFunctionInput
  | CreateGuidelineInput
  | CreatePartnerInput
  | CreateReportInput
  | CreateRiskAssessmentInput
  | CreateTaskInput
  | CreateCollectionInput;

/**
 * Viser en generell + knapp for å legge til nye elementer
 *
 *
 */
@customElement('d-new-documents-list')
export class DNewDocumentsList extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    #cover {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
    }

    a {
      display: block;
      margin-top: 1px;
      padding: 6px 0px 6px 30px;
      background-size: 28px 28px;
      background-position: -2px 2px;
      background-repeat: no-repeat;
      color: var(--linkColorGray);
      text-decoration: none;
      opacity: 0.8;
      cursor: pointer;
    }

    @media (hover: hover) {
      a:hover {
        color: var(--themeColorDarkerOne);
        opacity: 1;
      }
    }

    a.constitutionalDocuments {
      background-image: url(/images/constitutional-documents-gray.svg);
    }

    a.employees,
    a.contacts {
      background-image: url(/images/employees-gray.svg);
    }

    a.partners {
      background-image: url(/images/partners-gray.svg);
    }

    a.assets {
      background-image: url(/images/assets-gray.svg);
    }

    a.substances {
      background-image: url(/images/substances-gray.svg);
    }

    a.functions {
      background-image: url(/images/functions-gray.svg);
    }

    a.tasks {
      background-image: url(/images/tasks-gray.svg);
    }

    a.meetings,
    a.standaloneMeetings {
      background-image: url(/images/meetings-gray.svg);
    }

    .events,
    .taskEvents {
      background-image: url(/images/events-gray.svg);
    }

    a.guidelines {
      background-image: url(/images/guidelines-gray.svg);
    }

    a.documents {
      background-image: url(/images/documents-gray.svg);
    }

    a.contracts {
      background-image: url(/images/contracts-gray.svg);
    }

    a.reports {
      background-image: url(/images/reports-gray.svg);
    }

    a.issues {
      background-image: url(/images/issues-gray.svg);
    }

    a.riskAssessments {
      background-image: url(/images/risk-assessments-gray.svg);
    }

    a.messages {
      background-image: url(/images/messages-gray.svg);
    }

    a.ellipsis {
      background-image: url(/images/ellipsis-gray.svg);
    }

    a.collections {
      background-image: url(/images/collections-gray.svg);
    }

    @media (hover: hover) {
      a:hover {
        opacity: 1;
      }

      a.constitutionalDocuments:hover {
        background-image: url(/images/constitutional-documents-color.svg);
      }

      a.employees:hover,
      a.contacts:hover {
        background-image: url(/images/employees-color.svg);
      }

      a.partners:hover {
        background-image: url(/images/partners-color.svg);
      }

      a.assets:hover {
        background-image: url(/images/assets-color.svg);
      }

      a.substances:hover {
        background-image: url(/images/substances-color.svg);
      }

      a.functions:hover {
        background-image: url(/images/functions-color.svg);
      }

      a.tasks:hover {
        background-image: url(/images/tasks-color.svg);
      }

      a.meetings:hover,
      a.standaloneMeetings:hover {
        background-image: url(/images/meetings-color.svg);
      }

      .events:hover,
      d-new-document .taskEvents:hover {
        background-image: url(/images/events-color.svg);
      }

      a.guidelines:hover {
        background-image: url(/images/guidelines-color.svg);
      }

      a.documents:hover {
        background-image: url(/images/documents-color.svg);
      }

      a.contracts:hover {
        background-image: url(/images/contracts-color.svg);
      }

      a.reports:hover {
        background-image: url(/images/reports-color.svg);
      }

      a.issues:hover {
        background-image: url(/images/issues-color.svg);
      }

      a.riskAssessments:hover {
        background-image: url(/images/risk-assessments-color.svg);
      }

      a.ellipsis:hover {
        background-image: url(/images/ellipsis-color.svg);
      }

      a.collections:hover {
        background-image: url(/images/collections-color.svg);
      }
    }

    .inactive {
      opacity: 0.5;
    }
  `;
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Array })
  currentPathArray: string[] = [];
  @property({ type: Boolean })
  singleUser = false;
  @property({ type: Object })
  featureStates!: FeatureStates;
  @property({ type: Array })
  userEmails: string[] = [];
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];
  @property({ type: Array })
  pageMenu: PageMenuGroup[] = [];
  @property({ type: String })
  defaultFunctionUuid = '';
  @property({ type: Number })
  employeesCount = 0;
  @property({ type: String })
  specialTerms: OrganizationViewModelSpecialTermsEnum | undefined = undefined;
  private readonly docTypeDefinitions: {
    name: string;
    type: string;
    href?: () => string;
    show: () => boolean;
    create?: () => Promise<void>;
  }[] = [
    {
      type: 'events',
      name: 'Oppgave',
      show: () => this.featureStates.core,
      create: async () => {
        const id = uuid();
        let targetUrl = this.contextBasePath + pageIds.events + '/eventOccurrences/' + id + '?edit';
        if (this.collection) {
          targetUrl = this.contextFullPath + 'eventOccurrences/' + id + '?edit';
        }
        this.fireCreateEntity({
          entityType: 'eventOccurrences',
          entityUuid: id,
          targetUrl,
        });
      },
    },
    {
      type: 'tasks',
      name: 'Rutine',
      show: () => this.featureStates.core,
      create: async () => {
        let pageId = this.contextPage;
        if (this.contextDocType !== 'functions' && (!this.singleUser || !this.themePages.includes(pageId))) {
          pageId = pageIds.management;
        }
        let functionId = this.defaultFunctionUuid;
        if (this.contextDocType === 'functions') {
          functionId = this.contextDocUuid;
        }
        const id = uuid();
        let targetUrl = this.contextBasePath + pageId + '/tasks/' + id + '?edit';
        if (this.singleUser) {
          targetUrl = this.contextBasePath + pageId + '/tasks/' + id + '?edit';
        } else if (this.contextDocType === 'functions' || this.collection) {
          targetUrl = this.contextFullPath + 'tasks/' + id + '?edit';
        }
        this.fireCreateEntity({
          entityType: 'tasks',
          entityUuid: id,
          pageId: pageId,
          functionId,
          targetUrl,
        });
      },
    },
    {
      type: 'standaloneMeetings',
      name: 'Møte',
      show: () => !this.singleUser && (this.featureStates.core || this.featureStates.meetings),
      create: async () => {
        const id = uuid();
        const date = LocalDate.now().plusDays(1).toString();
        let targetUrl = this.contextBasePath + pageIds.meetings + '/meetingOccurrences/' + id + '?edit';
        if (this.collection) {
          targetUrl = this.contextFullPath + 'meetingOccurrences/' + id + '?edit';
        }
        this.fireCreateEntity({
          entityType: 'meetingOccurrences',
          entityUuid: id,
          instance: date,
          targetUrl,
        });
      },
    },
    {
      type: 'reports',
      name: 'Referat/rapport',
      show: () => this.featureStates.core,
      create: async () => {
        let pageId = this.contextPage;
        if (!this.themePages.includes(pageId)) {
          pageId = pageIds.management;
        }
        const id = uuid();
        let targetUrl = this.contextBasePath + pageId + '/reports/' + id + '?edit';
        if (this.collection) {
          targetUrl = this.contextFullPath + 'reports/' + id + '?edit';
        }
        this.fireCreateEntity({
          entityType: 'reports',
          entityUuid: id,
          pageId: pageId,
          targetUrl,
        });
      },
    },
    {
      type: 'guidelines',
      name: 'Retningslinje',
      show: () => this.featureStates.core,
      create: async () => {
        let pageId = this.contextPage;
        if (!this.themePages.includes(pageId)) {
          pageId = pageIds.management;
        }
        const id = uuid();
        let targetUrl = this.contextBasePath + pageId + '/guidelines/' + id + '?edit';
        if (this.collection) {
          targetUrl = this.contextFullPath + 'guidelines/' + id + '?edit';
        }
        this.fireCreateEntity({
          entityType: 'guidelines',
          entityUuid: id,
          pageId: pageId,
          targetUrl,
        });
      },
    },
    {
      type: 'contracts',
      name: 'Avtale',
      show: () => this.featureStates.core,
      create: async () => {
        let pageId = this.contextPage;
        if (!this.themePages.includes(pageId)) {
          pageId = pageIds.management;
        }
        const id = uuid();

        let targetUrl = this.contextBasePath + pageId + '/contracts/' + id + '?edit';
        if (this.contextDocType === 'employees' || this.contextDocType === 'partners') {
          targetUrl = this.contextFullPath + 'contracts/' + id + '?edit';
        }
        if (this.collection) {
          targetUrl = this.contextFullPath + 'contracts/' + id + '?edit';
        }
        this.fireCreateEntity({
          entityType: 'contracts',
          entityUuid: id,
          pageId: pageId,
          employeeId: this.contextDocType === 'employees' ? this.contextDocUuid : undefined,
          partnerId: this.contextDocType === 'partners' ? this.contextDocUuid : undefined,
          targetUrl: targetUrl,
        });
      },
    },
    {
      type: 'functions',
      name: 'Ansvarsområde',
      show: () => this.featureStates.core,
      create: async () => {
        let pageId = this.contextPage;
        if (!this.themePages.includes(pageId)) {
          pageId = pageIds.management;
        }
        const id = uuid();
        let targetUrl = this.contextBasePath + pageId + '/functions/' + id + '?edit';
        if (this.collection) {
          targetUrl = this.contextFullPath + 'functions/' + id + '?edit';
        }
        this.fireCreateEntity({
          entityType: 'functions',
          entityUuid: id,
          pageId: pageId,
          targetUrl,
        });
      },
    },
    {
      type: 'documents',
      name: 'Måldokument',
      show: () => this.featureStates.core,
      create: async () => {
        let pageId = this.contextPage;
        if (!this.themePages.includes(pageId)) {
          pageId = pageIds.management;
        }
        const id = uuid();
        let targetUrl = this.contextBasePath + pageId + '/documents/' + id + '?edit';
        if (this.collection) {
          targetUrl = this.contextFullPath + 'documents/' + id + '?edit';
        }
        this.fireCreateEntity({
          entityType: 'documents',
          entityUuid: id,
          pageId: pageId,
          targetUrl,
        });
      },
    },
    {
      type: 'riskAssessments',
      name: 'Risikovurdering',
      show: () => this.featureStates.core || this.featureStates.risk,
      create: async () => {
        let pageId = this.contextPage;
        if (!this.themePages.includes(pageId)) {
          pageId = pageIds.riskAssessments;
        }
        const id = uuid();
        let targetUrl = this.contextBasePath + pageId + '/riskAssessments/' + id + '?edit';
        if (this.collection) {
          targetUrl = this.contextFullPath + 'riskAssessments/' + id + '?edit';
        }
        this.fireCreateEntity({
          entityType: 'riskAssessments',
          entityUuid: id,
          pageId: pageId,
          targetUrl,
        });
      },
    },
    {
      type: 'constitutionalDocuments',
      name: 'Stiftelsesdokument',
      show: () => this.featureStates.core,
      create: async () => {
        const id = uuid();
        let targetUrl = this.contextBasePath + pageIds.company + '/constitutionalDocuments/' + id + '?edit';
        if (this.collection) {
          targetUrl = this.contextFullPath + 'constitutionalDocuments/' + id + '?edit';
        }
        this.fireCreateEntity({
          entityType: 'constitutionalDocuments',
          entityUuid: id,
          targetUrl,
        });
      },
    },
    {
      type: 'employees',
      name: 'Personale',
      show: () => !this.singleUser,
      create: async () => {
        let pageId = pageIds.employees;
        if (!this.featureStates.core && this.featureStates.meetings) {
          pageId = pageIds.people;
        }
        const id = uuid();
        let targetUrl = this.contextBasePath + pageId + '/employees/' + id + '?edit';
        if (this.collection) {
          targetUrl = this.contextFullPath + 'employees/' + id + '?edit';
        }
        this.fireCreateEntity({
          entityType: 'employees',
          entityUuid: id,
          targetUrl,
        });
      },
    },
    {
      type: 'partners',
      name: 'Samarbeidspartner',
      show: () => this.featureStates.core || this.featureStates.meetings,
      create: async () => {
        let pageId = this.contextPage;
        if (!this.themePages.includes(pageId)) {
          pageId = pageIds.partners;
          if (!this.featureStates.core && this.featureStates.meetings) {
            pageId = pageIds.people;
          }
        }
        const id = uuid();
        let targetUrl = this.contextBasePath + pageId + '/partners/' + id + '?edit';
        if (this.collection) {
          targetUrl = this.contextFullPath + 'partners/' + id + '?edit';
        }
        this.fireCreateEntity({
          entityType: 'partners',
          entityUuid: id,
          pageId: pageId,
          targetUrl,
        });
      },
    },
    {
      type: 'contacts',
      name: 'Kontaktperson',
      show: () => (this.featureStates.core || this.featureStates.meetings) && this.contextDocType === 'partners',
      create: async () => {
        const id = uuid();
        this.fireCreateEntity({
          entityType: 'contacts',
          entityUuid: id,
          partnerId: this.contextDocUuid,
          targetUrl: this.contextFullPath + 'contacts/' + id + '?edit',
        });
      },
    },
    {
      type: 'contacts',
      name: 'Kontaktperson',
      show: () => (this.featureStates.core || this.featureStates.meetings) && this.contextDocType !== 'partners',
      create: async () => {
        this.addNewContact();
      },
    },
    {
      type: 'assets',
      name: 'Utstyrsenhet',
      show: () => this.featureStates.core || this.featureStates.assets,
      create: async () => {
        const id = uuid();
        let targetUrl = this.contextBasePath + pageIds.assets + '/assets/' + id + '?edit';
        if (this.collection) {
          targetUrl = this.contextFullPath + 'assets/' + id + '?edit';
        }
        this.fireCreateEntity({
          entityType: 'assets',
          entityUuid: id,
          targetUrl,
        });
      },
    },
    {
      type: 'substances',
      name: 'Sikkerhetsdatablad for stoff',
      show: () => this.featureStates.core || this.featureStates.substances,
      href: () => this.contextBasePath + pageIds.substances + '?showSubstancesUploader=true',
    },
    {
      type: 'collections',
      name: 'Samling',
      show: () => this.featureStates.collections,
      create: async () => {
        let pageId = this.contextPage;
        if (!this.themePages.includes(pageId)) {
          pageId = pageIds.collections;
        }
        const id = uuid();
        this.fireCreateEntity({
          entityType: 'collections',
          entityUuid: id,
          pageId: pageId,
          targetUrl: this.contextBasePath + pageId + '/collections/' + id + '?edit',
        });
      },
    },
  ];

  private get collection(): boolean {
    return this.currentPathArray[this.currentPathArray.length - 2] === 'collections';
  }

  private get themePages(): number[] {
    const group: PageMenuGroup | undefined = this.pageMenu.find((group) => {
      return group.code === 'themePages';
    });
    if (group) {
      return group.pages.map((page) => {
        return page.pageId;
      });
    }
    return [];
  }

  private get contextPage() {
    return Number(this.currentPathArray[3]);
  }

  private get contextDocType() {
    if (this.currentPathArray.length === 4) {
      return 'page';
    } else if (this.currentPathArray.length === 6) {
      return this.currentPathArray[4];
    } else if (this.currentPathArray.length === 8) {
      return this.currentPathArray[6];
    }
    return '';
  }

  private get contextDocUuid() {
    if (this.currentPathArray.length === 6) {
      return this.currentPathArray[5];
    } else if (this.currentPathArray.length === 8) {
      return this.currentPathArray[7];
    } else {
      return '';
    }
  }

  private get contextBasePath() {
    return '/' + this.currentPathArray[1] + '/' + this.currentPathArray[2] + '/';
  }

  private get contextFullPath() {
    return '/' + this.currentPathArray.slice(1).join('/') + '/';
  }

  private get docTypes(): { name: string; type: string; href: string; create?: () => Promise<void> }[] {
    if (this.writeAccess) {
      return this.docTypeDefinitions
        .filter((d) => d.show())
        .map((d) => {
          return {
            name: d.name,
            type: d.type,
            href: d.href !== undefined ? d.href() : '#',
            create: d.create,
          };
        });
    }
    return [];
  }

  render() {
    return html`
      ${this.docTypes.map(
        (item) =>
          html`<a @click=${(e) => this.onClickCreate(e, item)} href="${item.href ?? ''}" class="${item.type}">
            ${item.name}
          </a>`,
      )}
      ${!this.featureStates.core
        ? html`<a @click=${(e) => this.onClickPromo(e, 'doctypes')} class="ellipsis">Andre elementer</a>`
        : nothing}
    `;
  }

  private fireCreateEntity(input: CreateEntityInput) {
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: input,
      }),
    );
  }

  private async onClickCreate(e: MouseEvent, item: { href: string; create?: () => Promise<void> }) {
    this.dispatchEvent(
      new CustomEvent('element-clicked', {
        bubbles: true,
        composed: true,
      }),
    );
    if (item.create !== undefined) {
      e.preventDefault();
      await item.create();
    }
  }

  private async addNewContact() {
    this.dispatchEvent(
      new CustomEvent('element-clicked', {
        bubbles: true,
        composed: true,
      }),
    );
    const result: NewContactAndPartnerDialogResult = await DNewContactAndPartnerDialog.open({
      userEmails: this.userEmails,
      partners: this.partners,
      collectionUuid: this.collection ? this.currentPathArray[this.currentPathArray.length - 1] : undefined,
    });
    return result.action;
  }

  private async onClickPromo(e, type) {
    e.preventDefault();
    this.dispatchEvent(
      new CustomEvent('element-clicked', {
        bubbles: true,
        composed: true,
      }),
    );
    const promoData = promo.find((p) => {
      return p.id === type;
    });
    if (promoData) {
      const result: PromoDialogResult = await DPromoDialog.open({
        promoData,
        features: features,
        employeesCount: this.employeesCount,
        specialTerms: this.specialTerms,
        healthcareSignatoryOptions: [],
        healthcareSignatory: undefined,
        canUpgrade: this.writeAccess,
      });
      if (result.action === 'upgrade') {
        console.log('upgrade');
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-new-documents-list': DNewDocumentsList;
  }
}
