import { css, html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { HideDoneOptionsDialog, HideDoneOptionsResult } from 'src/pages/events-page/hide-done-options-dialog.js';
import { PageContent } from 'src/pages/page-content.js';
import '../../library/lists/d-todo-list-section.js';
import '../../library/lists/d-calendar-list-section.js';
import type { EventsPageViewModel } from './events-page-view-model';
import { features, promo } from 'src/store/selectors/features';

/**
 *
 */
@customElement('d-events-page-content')
export class DEventsPageContent extends PageContent<EventsPageViewModel> {
  static readonly styles = [
    ...super.styles,
    css`
      d-calendar-list-section {
        margin-top: 30px;
      }
    `,
  ];

  renderContent() {
    if (!this.pageView.featureStates.core) {
      const promoData = promo.find((p) => {
        return p.id === 'complete';
      });
      if (promoData) {
        return html` <d-promo-section
          theme-page
          .employeesCount=${this.pageView.employeesCount}
          .specialTerms=${this.pageView.specialTerms}
          .promoData=${promoData}
          .features=${features}
          .canUpgrade=${this.writeAccess}
        ></d-promo-section>`;
      }
      return nothing;
    }
    return html` ${this.renderIfEmployee()} `;
  }

  private async onTodoListAction(e: CustomEvent<string>) {
    if (e.detail === 'showOptions') {
      const result: HideDoneOptionsResult = await HideDoneOptionsDialog.open({
        value: '' + this.pageView.hideAfterDays,
      });
      if (result.action === 'done') {
        this.dispatchEvent(
          new CustomEvent('save-hide-done-option', {
            bubbles: true,
            composed: true,
            detail: {
              value: result.value,
            },
          }),
        );
      }
    }
  }

  private renderIfEmployee() {
    return this.pageView.isEmployee
      ? html`
          <d-todo-list-section
            .organizationId=${this.pageView.organizationId}
            .employees=${this.pageView.employees}
            .items=${this.pageView.todoList}
            .hideAfterDays=${this.pageView.hideAfterDays}
            @action=${this.onTodoListAction}
            .contentStickyTop=${this.contentStickyTop}
          ></d-todo-list-section>
          <d-calendar-list-section
            .organizationId=${this.pageView.organizationId}
            .singleUserVersion=${this.pageView.singleUserVersion}
            .employees=${this.pageView.employees}
            .writeAccess=${this.pageView.writeAccess}
            .weekStart=${this.pageView.weekStart}
            .leavePeriods=${this.pageView.leavePeriodsConfirmed}
            .startTaskInstances=${this.pageView.startTaskInstances}
            .calendarTaskInstances=${this.pageView.calendarTaskInstances}
            .contentStickyTop=${this.contentStickyTop}
          ></d-calendar-list-section>
        `
      : nothing;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-events-page-content': DEventsPageContent;
  }
}
