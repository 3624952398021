import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { ContactView, ContactViewEditItem } from 'src/content/contacts/d-contact-view.js';
import type { TaskView, TaskViewEditItem } from 'src/content/tasks/d-task-view.js';
import type { TutorialView } from 'src/content/tutorials/d-tutorial-view.js';
import { uuid } from 'src/utilities/text.js';
import '../library/components/d-help-section.js';
import './assets/d-asset-view.js';
import type { AssetView, AssetViewEditItem } from './assets/d-asset-view.js';
import './constitutional-documents/d-constitutional-document-view.js';
import type {
  ConstitutionalDocumentView,
  ConstitutionalDocumentViewEditItem,
} from './constitutional-documents/d-constitutional-document-view.js';
import './contacts/d-contact-view.js';
import './contracts/d-contract-view.js';
import type { ContractView, ContractViewEditItem } from './contracts/d-contract-view.js';
import './documents/d-document-view.js';
import type { DocumentView, DocumentViewEditItem } from './documents/d-document-view.js';
import './employees/d-employee-view.js';
import type { EmployeeView, EmployeeViewEditItem } from './employees/d-employee-view.js';
import './event-occurrences/d-event-occurrence-view';
import './meeting-occurrences/d-meeting-occurrence-view';
import {
  EventOccurrenceView,
  EventOccurrenceViewEditItem,
  SaveType,
} from './event-occurrences/d-event-occurrence-view.js';
import './functions/d-function-view.js';
import type { FunctionView, FunctionViewEditItem } from './functions/d-function-view.js';
import './guidelines/d-guideline-view.js';
import type { GuidelineView, GuidelineViewEditItem } from './guidelines/d-guideline-view.js';
import './issues/d-issue-view.js';
import type { IssueView, IssueViewEditItem } from './issues/d-issue-view.js';
import './meetings/d-meeting-view.js';
import type { MeetingView } from './meetings/d-meeting-view.js';
import './partners/d-partner-view.js';
import type { PartnerView, PartnerViewEditItem } from './partners/d-partner-view.js';
import './reports/d-report-view.js';
import type { ReportView, ReportViewEditItem } from './reports/d-report-view.js';
import './risk-assessments/d-risk-assessment-view.js';
import type { RiskAssessmentView, RiskAssessmentViewEditItem } from './risk-assessments/d-risk-assessment-view.js';
import './substances/d-substance-view.js';
import type { SubstanceView, SubstanceViewEditItem } from './substances/d-substance-view.js';
import './tasks/d-task-view.js';
import './tutorials/d-tutorial-view.js';
import {
  MeetingOccurrenceView,
  MeetingOccurrenceViewEditItem,
} from 'src/content/meeting-occurrences/d-meeting-occurrence-view';
import type { CollectionView, CollectionViewEditItem } from 'src/content/collections/d-collection-view';
import 'src/content/collections/d-collection-view';
import { CopilotFieldPrompt } from 'src/layout/parts/d-copilot-field';

export type AfterSaveHandler = () => Promise<void>;

export interface EmptyView {
  type: 'empty';
  href: string;
  parentHref: string;
  currentUserHasAccess: true;
  prompt?: CopilotFieldPrompt | undefined;
}

export type ContentView =
  | EmptyView
  | AssetView
  | ConstitutionalDocumentView
  | ContractView
  | DocumentView
  | EmployeeView
  | EventOccurrenceView
  | MeetingOccurrenceView
  | FunctionView
  | TaskView
  | GuidelineView
  | IssueView
  | MeetingView
  | PartnerView
  | ContactView
  | ReportView
  | RiskAssessmentView
  | SubstanceView
  | TutorialView
  | CollectionView;

export interface GuidelineEditDoneDetail {
  type: 'guidelines';
  uuid: string;
  editItem: GuidelineViewEditItem;
}

export interface AssetEditDoneDetail {
  type: 'assets';
  uuid: string;
  editItem: AssetViewEditItem;
}

export interface ConstitutionalDocumentEditDoneDetail {
  type: 'constitutionalDocuments';
  uuid: string;
  editItem: ConstitutionalDocumentViewEditItem;
}

export interface ContractEditDoneDetail {
  type: 'contracts';
  uuid: string;
  editItem: ContractViewEditItem;
}

export interface DocumentEditDoneDetail {
  type: 'documents';
  uuid: string;
  editItem: DocumentViewEditItem;
}

export interface EmployeeEditDoneDetail {
  type: 'employees';
  uuid: string;
  editItem: EmployeeViewEditItem;
}

export interface EventOccurrenceEditDoneDetail {
  type: 'eventOccurrences';
  uuid: string;
  editItem: EventOccurrenceViewEditItem;
  saveType: SaveType;
  alert: boolean;

  message: string;

  includeDetailsInAlert: boolean;
}

export interface RelateAssetToEventOccurrencesDetail {
  asset: string;
  eventOccurrences: string[];
}

export interface MeetingOccurrenceEditDoneDetail {
  type: 'meetingOccurrences';
  uuid: string;
  editItem: MeetingOccurrenceViewEditItem;
  saveType: SaveType;
}

export interface FunctionEditDoneDetail {
  type: 'functions';
  uuid: string;
  editItem: FunctionViewEditItem;
}

export interface TaskEditDoneDetail {
  type: 'tasks';
  uuid: string;
  editItem: TaskViewEditItem;
}

export interface IssueEditDoneDetail {
  type: 'issues';
  uuid: string;
  editItem: IssueViewEditItem;
}

export interface MeetingEditDoneDetail {
  type: 'meetings';
  uuid: string;
  editItem: Record<string, never>;
}

export interface PartnerEditDoneDetail {
  type: 'partners';
  uuid: string;
  editItem: PartnerViewEditItem;
}

export interface ContactEditDoneDetail {
  type: 'contacts';
  uuid: string;
  editItem: ContactViewEditItem;
}

export interface ReportEditDoneDetail {
  type: 'reports';
  uuid: string;
  editItem: ReportViewEditItem;
}

export interface RiskAssessmentEditDoneDetail {
  type: 'riskAssessments';
  uuid: string;
  editItem: RiskAssessmentViewEditItem;
}

export interface SubstanceEditDoneDetail {
  type: 'substances';
  uuid: string;
  editItem: SubstanceViewEditItem;
}

export interface CollectionEditDoneDetail {
  type: 'collections';
  uuid: string;
  editItem: CollectionViewEditItem;
}

export type ContentEditDoneDetail = EntityEditDoneDetail & { afterSave?: AfterSaveHandler };

export type EntityEditDoneDetail =
  | GuidelineEditDoneDetail
  | AssetEditDoneDetail
  | DocumentEditDoneDetail
  | EmployeeEditDoneDetail
  | EventOccurrenceEditDoneDetail
  | MeetingOccurrenceEditDoneDetail
  | FunctionEditDoneDetail
  | TaskEditDoneDetail
  | IssueEditDoneDetail
  | MeetingEditDoneDetail
  | ConstitutionalDocumentEditDoneDetail
  | ContractEditDoneDetail
  | SubstanceEditDoneDetail
  | RiskAssessmentEditDoneDetail
  | ReportEditDoneDetail
  | ContactEditDoneDetail
  | PartnerEditDoneDetail
  | CollectionEditDoneDetail;

/**
 *
 * Selects the content view to display based on the type of the contentView property.
 *
 *
 *
 */
@customElement('d-content')
export class DContent extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
    }

    :host > * {
      height: 100%;
    }

    section {
      margin-top: -9px;
    }
  `;
  @property({ type: Number })
  pageId = 0;
  @property({ type: String })
  route = '';
  @property({ type: Object })
  levelMetaData: any = {};
  @property({ type: Number })
  count = 0;
  @property({ type: Object })
  user: any = {};
  @property({ type: Object })
  organization: any = {};
  @property({ type: Boolean })
  singleUserVersion = false;
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Object })
  employeeListConfig: any = {};
  @property({ type: Object })
  issueListConfig: any = {};
  @property({ type: Object })
  entityRoute: any = {};
  @property({ type: Object })
  entityItem: any = {};
  @property({ type: String })
  entityId = '';
  @property({ type: String })
  entityType = '';
  @property({ type: String })
  assetUuidForNewEvent = '';
  @property({ type: Boolean })
  uncoverPageMenu = false;
  @property({ type: Object })
  contentView!: ContentView;
  @property({ type: Boolean })
  covered = false;
  @property({ type: String })
  parentHref = '';
  @property({ type: String })
  mode = 'normal';
  @property({ type: Number })
  setScrolltop = 0;
  @property({ type: Boolean, attribute: 'prevent-header-shrink' })
  preventHeaderShrink = false;

  renderContent() {
    const view = this.contentView;
    switch (view.type) {
      case 'issues':
        return html`
          <d-issue-view
            .entityView=${view}
            .singleUserVersion=${this.singleUserVersion}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @entity-delete=${this.onEntityDelete}
            @done=${(e: CustomEvent<{ afterSave?: AfterSaveHandler; editItem: IssueViewEditItem }>) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
          >
          </d-issue-view>
        `;
      case 'assets':
        return html`
          <d-asset-view
            .entityView=${view}
            .singleUserVersion=${this.singleUserVersion}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            duplicatable
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @entity-delete=${this.onEntityDelete}
            @done=${(e: CustomEvent<{ afterSave?: AfterSaveHandler; editItem: AssetViewEditItem }>) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
            @save-as-new=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: AssetViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onSaveAsNew(e, e.detail.editItem, view);
            }}
          >
          </d-asset-view>
        `;
      case 'constitutionalDocuments':
        return html`
          <d-constitutional-document-view
            .entityView=${view}
            .singleUserVersion=${this.singleUserVersion}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            duplicatable
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @entity-delete=${this.onEntityDelete}
            @done=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: ConstitutionalDocumentViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
            @save-as-new=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: ConstitutionalDocumentViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onSaveAsNew(e, e.detail.editItem, view);
            }}
          >
          </d-constitutional-document-view>
        `;
      case 'contracts':
        return html`
          <d-contract-view
            .entityView=${view}
            .singleUserVersion=${this.singleUserVersion}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            duplicatable
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @entity-delete=${this.onEntityDelete}
            @done=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: ContractViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
            @save-as-new=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: ContractViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onSaveAsNew(e, e.detail.editItem, view);
            }}
          >
          </d-contract-view>
        `;
      case 'documents':
        return html`
          <d-document-view
            .entityView=${view}
            .singleUserVersion=${this.singleUserVersion}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            duplicatable
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @entity-delete=${this.onEntityDelete}
            @done=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: DocumentViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
            @save-as-new=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: DocumentViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onSaveAsNew(e, e.detail.editItem, view);
            }}
          >
          </d-document-view>
        `;
      case 'employees':
        return html`
          <d-employee-view
            .entityView=${view}
            .singleUserVersion=${this.singleUserVersion}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @entity-delete=${this.onEntityDelete}
            @done=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: EmployeeViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
          >
          </d-employee-view>
        `;
      case 'eventOccurrences':
        return html`
          <d-event-occurrence-view
            .entityView=${view}
            .singleUserVersion=${this.singleUserVersion}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @checklist-changed=${(e: CustomEvent<{ content: string }>) => {
              e.stopPropagation();
              this.dispatchEvent(
                new CustomEvent<{ uuid: string; content: string }>('checklist-changed', {
                  bubbles: true,
                  composed: true,
                  detail: {
                    uuid: view.uuid,
                    content: e.detail.content,
                  },
                }),
              );
            }}
            @delete-event=${(e: CustomEvent<{ saveType: SaveType; alert: boolean }>) => {
              e.stopPropagation();
              this.dispatchEvent(
                new CustomEvent<{
                  url: string;
                  type: string;
                  uuid: string;
                  saveType: string;
                  alert: boolean;
                }>('content-delete-event', {
                  bubbles: true,
                  composed: true,
                  detail: {
                    type: view.type,
                    uuid: view.uuid,
                    url: view.parentHref,
                    saveType: e.detail.saveType,
                    alert: e.detail.alert,
                  },
                }),
              );
            }}
            @done=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: EventOccurrenceViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.dispatchEvent(
                new CustomEvent<ContentEditDoneDetail>('content-save-event', {
                  bubbles: true,
                  composed: true,
                  detail: {
                    type: view.type,
                    uuid: view.uuid,
                    editItem: e.detail.editItem,
                    afterSave: e.detail.afterSave,
                    saveType: e.detail.editItem.saveType,
                    alert: e.detail.editItem.alert,
                    message: e.detail.editItem.message,
                    includeDetailsInAlert: e.detail.editItem.includeDetailsInAlert,
                  },
                }),
              );
            }}
          >
          </d-event-occurrence-view>
        `;
      case 'meetingOccurrences':
        return html`
          <d-meeting-occurrence-view
            .entityView=${view}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @checklist-changed=${(e: CustomEvent<{ content: string }>) => {
              e.stopPropagation();
              this.dispatchEvent(
                new CustomEvent<{ uuid: string; content: string }>('checklist-changed', {
                  bubbles: true,
                  composed: true,
                  detail: {
                    uuid: view.uuid,
                    content: e.detail.content,
                  },
                }),
              );
            }}
            @delete-meeting-occurrence=${(e: CustomEvent<{ saveType: SaveType; alerts: boolean }>) => {
              e.stopPropagation();
              this.dispatchEvent(
                new CustomEvent<{
                  url: string;
                  type: string;
                  uuid: string;
                  saveType: string;
                  alerts: boolean;
                }>('content-delete-meeting-occurrence', {
                  bubbles: true,
                  composed: true,
                  detail: {
                    type: view.type,
                    uuid: view.uuid,
                    url: view.parentHref,
                    saveType: e.detail.saveType,
                    alerts: e.detail.alerts,
                  },
                }),
              );
            }}
            @done=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: MeetingOccurrenceViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.dispatchEvent(
                new CustomEvent<ContentEditDoneDetail>('content-save-meeting-occurrence', {
                  bubbles: true,
                  composed: true,
                  detail: {
                    type: view.type,
                    uuid: view.uuid,
                    editItem: e.detail.editItem,
                    afterSave: e.detail.afterSave,
                    saveType: e.detail.editItem.saveType,
                  },
                }),
              );
            }}
          >
          </d-meeting-occurrence-view>
        `;
      case 'functions':
        return html`
          <d-function-view
            .entityView=${view}
            .singleUserVersion=${this.singleUserVersion}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @entity-delete=${this.onEntityDelete}
            @done=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: FunctionViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
          >
          </d-function-view>
        `;
      case 'tasks':
        return html`
          <d-task-view
            .entityView=${view}
            .singleUserVersion=${this.singleUserVersion}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @entity-delete=${this.onEntityDelete}
            @done=${(e: CustomEvent<{ afterSave?: AfterSaveHandler; editItem: TaskViewEditItem }>) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
          ></d-task-view>
        `;
      case 'guidelines':
        return html`
          <d-guideline-view
            .entityView=${view}
            .singleUserVersion=${this.singleUserVersion}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            duplicatable
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @entity-delete=${this.onEntityDelete}
            @done=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: GuidelineViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
            @save-as-new=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: GuidelineViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onSaveAsNew(e, e.detail.editItem, view);
            }}
          >
          </d-guideline-view>
        `;
      case 'meetings':
        return html`
          <d-meeting-view
            .entityView=${view}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @entity-delete=${this.onEntityDelete}
            @done=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: Record<string, never>;
              }>,
            ) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
          >
          </d-meeting-view>
        `;
      case 'partners':
        return html`
          <d-partner-view
            .entityView=${view}
            .singleUserVersion=${this.singleUserVersion}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @entity-delete=${this.onEntityDelete}
            @done=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: PartnerViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
          >
          </d-partner-view>
        `;
      case 'contacts':
        return html`
          <d-contact-view
            .entityView=${view}
            .singleUserVersion=${this.singleUserVersion}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @entity-delete=${this.onEntityDelete}
            @done=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: ContactViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
          >
          </d-contact-view>
        `;
      case 'reports':
        return html`
          <d-report-view
            .entityView=${view}
            .singleUserVersion=${this.singleUserVersion}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            ?duplicatable=${!view.hasRelatedMeetingEvent}
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            .isEmployee=${true}
            @entity-delete=${this.onEntityDelete}
            @done=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: ReportViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
            @save-as-new=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: ReportViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onSaveAsNew(e, e.detail.editItem, view);
            }}
          >
          </d-report-view>
        `;
      case 'riskAssessments':
        return html`
          <d-risk-assessment-view
            .entityView=${view}
            .singleUserVersion=${this.singleUserVersion}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @entity-delete=${this.onEntityDelete}
            @done=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: RiskAssessmentViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
            @save-as-new=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: RiskAssessmentViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onSaveAsNew(e, e.detail.editItem, view);
            }}
          >
          </d-risk-assessment-view>
        `;
      case 'substances':
        return html`
          <d-substance-view
            .entityView=${view}
            .singleUserVersion=${this.singleUserVersion}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @entity-delete=${this.onEntityDelete}
            @done=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: SubstanceViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
          >
          </d-substance-view>
        `;
      case 'tutorials':
        return html`
          <d-tutorial-view
            .singleUserVersion=${this.singleUserVersion}
            .entityView=${view}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
          >
          </d-tutorial-view>
        `;
      case 'collections':
        return html`
          <d-collection-view
            .singleUserVersion=${this.singleUserVersion}
            .entityView=${view}
            .pageId=${this.pageId}
            .mode=${this.mode}
            .parentHref=${this.parentHref}
            ?covered=${this.covered}
            .setScrolltop=${this.setScrolltop}
            .preventHeaderShrink=${this.preventHeaderShrink}
            @entity-delete=${this.onEntityDelete}
            @done=${(
              e: CustomEvent<{
                afterSave?: AfterSaveHandler;
                editItem: CollectionViewEditItem;
              }>,
            ) => {
              e.stopPropagation();
              this.onDone(e, e.detail.editItem, view);
            }}
          >
          </d-collection-view>
        `;
    }
  }

  render() {
    return this.renderContent();
  }

  private onDone(e, editItem, view) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent<ContentEditDoneDetail>('content-edit-done', {
        bubbles: true,
        composed: true,
        detail: {
          type: view.type,
          uuid: view.uuid,
          editItem: editItem,
          afterSave: e.detail.afterSave,
        },
      }),
    );
  }

  private onSaveAsNew(e, editItem, view) {
    const newUuid = uuid();
    const destination = view.parentHref + '/' + view.type + '/' + newUuid + '?edit';
    this.dispatchEvent(new CustomEvent('edit', { bubbles: true, composed: true }));
    this.dispatchEvent(
      new CustomEvent('navigate', {
        bubbles: true,
        composed: true,
        detail: { href: destination },
      }),
    );
    this.dispatchEvent(
      new CustomEvent<ContentEditDoneDetail>('content-edit-done', {
        bubbles: true,
        composed: true,
        detail: {
          type: view.type,
          uuid: newUuid,
          editItem: editItem,
          afterSave: e.detail.afterSave,
        },
      }),
    );
  }

  private onEntityDelete(e: Event) {
    e.stopPropagation();
    if (this.contentView.type !== 'empty') {
      this.dispatchEvent(
        new CustomEvent<{ type: string; uuid: string; url: string }>('entity-delete', {
          bubbles: true,
          composed: true,
          detail: {
            type: this.contentView.type,
            uuid: this.contentView.uuid,
            url: this.contentView.parentHref,
          },
        }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-content': DContent;
  }
}
